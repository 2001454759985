import { axios } from 'api'

export const create = async (payload) => axios.post(`createPageSet`, payload)

export const update = async (pageset_id, payload) =>
  axios.patch(`pageSets/${pageset_id}`, payload)

export const archive = async (pageset_id) =>
  axios.patch(`archivePageSet/${pageset_id}`)

export const getByLayoutId = async (layout_id) =>
  axios.get(`pageSets?layout_id=${layout_id}`)

export const getCustomPageSets = async (layout_id) =>
  axios.get(`pageSets?layout_id=${layout_id}&custom_pages=true`)
