import React, { useState, useEffect } from 'react'
import { assetCategories as categories } from 'utils'
import { Flex, LoadingInsertImages, InsertTabs, AssetDrawers } from 'components'
import { assets } from 'api'

const ArtworkDrawer = ({ setShow, id: subcategoryId }) => {
  const [loading, setLoading] = useState(true)
  const [drawers, setDrawers] = useState([])

  const getItems = async () => {
    setLoading(true)
    const { data } = await assets.getAssetsByCategory(subcategoryId)
    setDrawers(data)
    setLoading(false)
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [subcategoryId])

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full>
      <AssetDrawers
        drawers={drawers}
        categoryKey="assets"
        categoryNameKey="name"
        imageKey="image"
        nameKey="name"
        setShow={setShow}
      />
    </Flex>
  )
}

export default ({ setShow }) => (
  <InsertTabs
    tabs={[
      {
        name: 'Clip Art',
        content: () => (
          <ArtworkDrawer setShow={setShow} id={categories['Clip Art']} />
        ),
      },
      {
        name: 'Flourishes',
        content: () => (
          <ArtworkDrawer setShow={setShow} id={categories['Flourishes']} />
        ),
      },
      {
        name: 'Frame Clip Art',
        content: () => (
          <ArtworkDrawer setShow={setShow} id={categories['Frame Clip Art']} />
        ),
      },
      {
        name: 'Military Emblem',
        content: () => (
          <ArtworkDrawer setShow={setShow} id={categories['Military Emblem']} />
        ),
      },
    ]}
  />
)
