import React, { useState, useEffect, useMemo } from 'react'
import { Flex, Dropdown, Table } from 'components'
import { collections, products, layouts, pageSets } from 'api'
import DefaultTemplateTable from './DefaultTemplateTable'
import { useSetToast } from 'hooks'

export default ({ width, margin }) => {
  const [allCollections, setAllCollections] = useState()
  const [selectedCollection, setSelectedCollection] = useState()
  const [allProducts, setAllProducts] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState()
  const [allLayouts, setAllLayouts] = useState(null)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [customTemplates, setCustomTemplates] = useState([])
  const setToast = useSetToast()

  const getCollections = async () => {
    const { data } = await collections.getAllCollections()
    setAllCollections(data)
  }

  const getProducts = async () => {
    if (selectedCollection) {
      const { data } = await products.getProductsByCollection(
        selectedCollection,
      )
      setAllProducts(data)
      setSelectedProduct(null)
      setSelectedLayout(null)
      setCustomTemplates([])
    }
  }

  const getLayouts = async () => {
    if (selectedProduct) {
      const { data } = await layouts.getLayoutsByProduct(selectedProduct)
      setAllLayouts(data)
      setSelectedLayout(null)
      setCustomTemplates([])
    }
  }

  const getCustomTemplates = async () => {
    if (selectedLayout) {
      const { data } = await pageSets.getCustomPageSets(selectedLayout)
      setCustomTemplates(data)
    }
  }

  const deleteTemplate = async (template) => {
    try {
      await pageSets.archive(template.id)
      setToast({
        text: 'Template Deleted!',
        icon: 'Check',
      })
      getCustomTemplates()
    } catch (err) {
      setToast({
        text: 'Something went wrong!',
      })
    }
  }

  const selectDefaultTemplate = async (template) => {
    try {
      const currentDefaults = customTemplates.filter(
        (template) => template.default && template.home_template,
      )
      Promise.all(
        currentDefaults.map(
          async (currentDefault) =>
            await pageSets.update(currentDefault.id, {
              default: false,
            }),
        ),
      )
      if (template.home_template) {
        await pageSets.update(template.id, {
          default: true,
        })
      }
      setToast({
        text: 'Default Set!',
        icon: 'Check',
      })
      getCustomTemplates()
    } catch (err) {
      setToast({
        text: 'Something went wrong!',
      })
    }
  }

  const columns = useMemo(
    () =>
      DefaultTemplateTable(
        customTemplates,
        deleteTemplate,
        selectDefaultTemplate,
      ),
    // eslint-disable-next-line
    [customTemplates],
  )

  useEffect(
    () => {
      getCollections()
    },
    //eslint-disable-next-line
    [],
  )

  useEffect(
    () => {
      getProducts()
    },
    //eslint-disable-next-line
    [selectedCollection],
  )
  useEffect(
    () => {
      getLayouts()
    },
    //eslint-disable-next-line
    [selectedProduct],
  )
  useEffect(
    () => {
      getCustomTemplates()
    },
    //eslint-disable-next-line
    [selectedLayout],
  )

  return (
    <>
      <Flex>
        <Dropdown
          label="Collections"
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedCollection, setSelectedCollection]}
          placeholder="Choose Collections"
          textKey="name"
          valueKey="id"
          items={allCollections}
        />
        <Dropdown
          label="Products"
          disabled={!allProducts}
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedProduct, setSelectedProduct]}
          placeholder="Choose Product"
          textKey="name"
          valueKey="id"
          items={allProducts}
        />
        <Dropdown
          label="Layouts"
          disabled={!allLayouts}
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedLayout, setSelectedLayout]}
          placeholder="Choose Layout"
          textKey="name"
          valueKey="id"
          items={allLayouts}
        />
      </Flex>
      <Flex width="950px" margin="20px 0 0 0">
        <Table
          columns={columns}
          data={customTemplates}
          searchPlaceholder="Search by name or email"
        />
      </Flex>
    </>
  )
}
