import {
  editorHistoryAtom,
  editorHistoryCounterAtom,
  editorPagesAtom,
  itemAtomFamily,
  itemIdAtom,
  selectedPageAtom,
} from 'atoms'
import { useEffect, useState } from 'react'
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
export default () => {
  const editorHistory = useRecoilValue(editorHistoryAtom)
  const [undoCounter, setUndoCounter] = useRecoilState(editorHistoryCounterAtom)
  const selectedPage = useRecoilValue(selectedPageAtom)
  const resetEditorHistory = useResetRecoilState(editorHistoryAtom)
  const itemIds = useRecoilValue(itemIdAtom)
  const historyItem = editorHistory[editorHistory.length - undoCounter]
  const [redoClick, setRedoClick] = useState(false)

  const editorPageItems =
    useRecoilValue(editorPagesAtom)[selectedPage - 1]?.product?.items

  const undo = useRecoilCallback(({ set }) => async () => {
    setRedoClick(false)
    let itemToUndo
    //iterates from 1 index infront of pointer (undoCounter) to end of history to check for the closest match in the history.
    for (let i = editorHistory.length - undoCounter - 1; i >= 0; i--) {
      if (editorHistory[i]?.id === historyItem?.id) {
        itemToUndo = { ...editorHistory[i] }
        break
      }
    }
    if (!itemToUndo) {
      // If the loops finds no matches in history, it will check the editorPageItems for saved items and return it to its saved state, to avoid unwanted deletions
      for (const item of editorPageItems)
        item.id === historyItem?.id && (itemToUndo = { ...item })
    }

    const filteredIds = itemIds.filter((itemId) => itemId !== historyItem.id)
    if (itemToUndo) {
      //replaces item Atoms and itemIds atoms with new item
      const { id } = itemToUndo
      set(itemAtomFamily(id), itemToUndo)
      set(itemIdAtom, [...filteredIds, id])
    } else {
      //if no item is found at this point is was a late addition and can be safely removed
      set(itemAtomFamily(historyItem.id), null)
      set(itemIdAtom, [...filteredIds])
    }

    if (undoCounter < editorHistory.length) setUndoCounter(undoCounter + 1) //moves pointer towards beginning of editorHistoryList
  })

  const redo = () => {
    setRedoClick(true)
    if (undoCounter > 1) setUndoCounter(undoCounter - 1)
  }

  const asyncRedo = useRecoilCallback(({ set }) => () => {
    //redo must be async as it depends on state of undoCounter
    const filteredIds = itemIds.filter((itemId) => itemId !== historyItem.id)
    const { id } = historyItem
    set(itemAtomFamily(id), historyItem)
    set(itemIdAtom, [...filteredIds, id])
  })

  useEffect(() => {
    redoClick && historyItem && asyncRedo()
    //eslint-disable-next-line
  }, [undoCounter])

  useEffect(() => {
    resetEditorHistory()
    //eslint-disable-next-line
  }, [selectedPage])

  return (type) => {
    switch (type) {
      case 'undo':
        editorHistory.length > 0 && undo()
        break
      case 'redo':
        redo()
        break
      default:
      //do nothing
    }
  }
}
