import React, { useState, useRef } from 'react'
import { CustomVerseEditor } from '.'
import { customVerses } from 'api'

export default ({ verse, setEditing, reload }) => {
  const TinyMCERef = useRef(null)
  const [editReference, setEditReference] = useState(verse.reference)

  const patchVerse = async () => {
    const content = TinyMCERef?.current?.getContent?.()
    const updatedVerse = {
      html: content,
      html_for_render: content,
      reference: editReference,
    }
    await customVerses.updateCustomVerse(verse.id, updatedVerse)
    setEditing(false)
    reload()
  }

  return (
    <CustomVerseEditor
      TinyMCERef={TinyMCERef}
      value={verse.html}
      reference={[editReference, setEditReference]}
      cancel={() => setEditing(false)}
      save={() => patchVerse()}
    />
  )
}
