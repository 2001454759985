import { themeAtom, dateFormatAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { toolbarSettings, tinyMCEFonts, setup } from '.'

export default (init = {}, inline = true, caseDetails) => {
  const theme = useRecoilValue(themeAtom)
  const dateFormat = useRecoilValue(dateFormatAtom)

  return {
    height: '100%',
    width: '100%',
    browser_spellcheck: true,
    inline: inline,

    menubar: false,
    statusbar: false,
    toolbar: toolbarSettings(inline),
    setup: (editor) => setup(editor, caseDetails, dateFormat),
    fontsize_formats:
      '14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 48px 72px',

    skin: theme === 'dark' ? 'oxide-dark' : 'oxide',
    content_css: theme === 'dark' ? 'dark' : 'default',

    plugins: [
      'image',
      'template',
      'noneditable',
      'table',
      'paste',
      'hr',
      'casedetails',
    ],
    paste_as_text: true,
    lineheight_formats:
      '0.5 0.75 1 1.25 1.5 1.75 2 2.25 2.5 2.75 3 3.25 3.5 3.75 4',

    font_formats: tinyMCEFonts,

    ...init,
  }
}
