import React from 'react'
import { Flex, Text, DarkImage } from 'components'

export default () => (
  <Flex column center>
    <Flex
      width="100%"
      padding="0 40px"
      margin="0 0 48px 0"
      height="94px"
      background="paleSecondary"
      align="center"
      justify="space-between"
    >
      <Text size="34px" weight="600">
        New User
      </Text>
      <DarkImage width="100px" alt="Directors Print Suite Logo" src="dpsLogo" />
    </Flex>
    <Flex column padding="0 24px">
      <Text align="center" size="24px" margin="0 0 24px 0">
        If you are not a funeral director, please reach out to your funeral home
        to create a new staff member account for you. Your funeral home can do
        this by following{' '}
        <Text
          as="a"
          color="brand"
          target="_blank"
          rel="noreferrer"
          href="https://app.tango.us/app/workflow/c2676a59-d0ba-4c4b-8952-9c0280243b71"
        >
          these steps.
        </Text>
      </Text>
      <Text align="center" size="24px">
        If you are a funeral director, please follow the{' '}
        <Text as="a" color="brand" href="/sign-up">
          signup flow
        </Text>{' '}
        to create your funeral home in the system.
      </Text>
    </Flex>
  </Flex>
)
