import { css } from 'styled-components'
import { colors as c } from 'utils'

export default ({ theme: { os } }) =>
  os.includes('Windows') &&
  css`
    &::-webkit-scrollbar {
      background: ${c('gray5')};
      transition: 0.25s;
    }
    &::-webkit-scrollbar-thumb {
      background: ${c('gray4')};
      &:hover {
        background: ${c('gray4', '90')};
      }
      transition: 0.25s;
    }
  `
