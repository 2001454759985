export default async (id, callback, stateSetter, onSuccess) => {
  stateSetter(true)
  let apiTimeout = setTimeout(() => poll(id, callback), 1000)

  const poll = async (id, callback) => {
    try {
      const res = await callback(id)
      if (![null, 'processing', 'success'].includes(res.data.status)) {
        console.error('ERROR: generating PDF', res.data.status)
        clearTimeout(apiTimeout)
        stateSetter(false)
        return
      }
      if (!res.data.aws_download_url) {
        console.log('polling...')
        apiTimeout = setTimeout(() => poll(id, callback), 1000)
      } else {
        onSuccess(res.data)
        clearTimeout(apiTimeout)
        stateSetter(false)
        return res.data
      }
    } catch (err) {
      console.log('ERROR: polling', err)
    }
  }
}
