import { axios } from 'api'

//TODO: time for refresh token? (can we just do this every time they visit?)

export const checkEmail = async (email) =>
  axios.post('/checkEmail', { email: email })

//TODO: trial days?
export const create = async (user) => axios.post('/signup', user)
// expected payload (ALL REQUIRED):
// {
//   email: <String>,
//   password: <String>,
//   name: <String>,
//   funeral_home_id: <UUID: funeral_home_id>,
// }

export const user = async (token) => axios.get('/user')

export const getStaff = async () => axios.get(`/funeralHomeUsers`)

export const createStaff = async (user) => axios.post(`/createStaff`, user)
// expected payload:
// {
//   email: email,
//   name: staff member name,
//   password: password
// }

export const getInvitedStaff = async (token) => axios.get(`/newStaff/${token}`)

export const archiveUser = async (user_id) =>
  axios.patch(`/archiveUser/${user_id}`)

export const unarchiveUser = async (user_id) =>
  axios.patch(`/unarchiveUser/${user_id}`)

export const resendActivationEmail = async (user_id) =>
  axios.get(`/resendUser/${user_id}`)

//TODO: update backend to send new token back in Headers
export const updateUser = async (user) => axios.patch(`/user`, user)
// expected payload:
// {
//   email: email,
//   password: password,
//   name: name
// }

export const updateManager = async (id, manager) =>
  axios.patch(`/updateUserManager/${id}`, manager)
//expected payload:
// {
//   manager: <boolean>
// }

export const sendShareInvite = async (payload) =>
  axios.post(`/sendInviteToUploadCaseImages`, payload)
// expected payload:
// {
//   email: <String> (REQUIRED),
//   case_id: <String> (REQUIRED),
//   name_of_deceased: <String> (REQUIRED),
//   recipient: <String> (REQUIRED),
//   number_allowed: <Number> (OPTIONAL),
//   expiration_date: <Date> (REQUIRED)
// }
