import styled, { css } from 'styled-components'
import { Flex } from 'components'
import { flex, sizing } from 'styles'
import { colors as c } from 'utils'

const parseBackgroundPosition = (position) => {
  if (position) {
    if (isNaN(position) && position.includes('%')) {
      return position
    } else {
      return `${parseInt(position)}px`
    }
  } else {
    return '0px'
  }
}

export const ProductPrint = styled.div(
  ({
    backgroundOpactiy,
    padding,
    whiteBorder,
    theme,
    pageLayout,
    paperType,
    pageTwo,
    backgroundImageUrl,
    preprinted,
    isPageShuffleItem,
  }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: #ffffff;
    position: relative;
    ${sizing}
    overflow: hidden;
    background-image: ${
      pageLayout?.hide_background || preprinted
        ? ''
        : `url(${backgroundImageUrl})`
    };
    background-size: ${
      pageLayout?.background_fill ? pageLayout.background_fill : 'cover'
    };
    background-position: ${parseBackgroundPosition(pageLayout?.background_left)}
      ${parseBackgroundPosition(pageLayout?.background_top)};
    background-repeat: no-repeat;
    background: ${paperType === 'preprinted' && isPageShuffleItem && 'white'};
    border: ${whiteBorder && `${whiteBorder}px solid white`};P
    padding-top: ${padding?.top && `${padding.top}px`};
    padding-right: ${padding?.right && `${padding.right}px`};
    padding-bottom: ${padding?.bottom && `${padding.bottom}px`};
    padding-left: ${padding?.left && `${padding.left}px`};
    position: relative;
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background: white;
      opacity: ${backgroundOpactiy || (pageTwo ? 0.7 : 0)};
    }
  `,
)

export const PagePrint = styled.div(
  ({ height, width, padding, margin, index }) => css`
    ${flex}
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    height: ${height}px;
    width: ${width}px;
    padding-top: ${padding.top}px;
    padding-bottom: ${padding.bottom ? padding.bottom : padding.top}px;
    padding-left: ${padding.left}px;
    padding-right: ${padding.right ? padding.right : '0'};
    margin: ${margin};
    overflow: hidden;
  `,
)

export const RBDiv = styled.div(
  ({ product, isEven }) => css`
    position: absolute;
    z-index: 0;
    height: ${product?.productSize?.height}px;
    width: ${!isEven
      ? product?.padding.left + 1
      : product?.padding?.right + 1}px;
    background-color: #fff;
    ${isEven && `right: -1px;`}
    ${!isEven && `left: -1px;`}
    top: 0px;
  `,
)

export const RelativeWrapper = styled.div(
  () =>
    css`
      position: relative;
    `,
)

export const PerfGuide = styled.div(
  ({ height, width, padding, whiteBorder, hideOverflow }) => css`
    height: ${whiteBorder
      ? height - whiteBorder * 2
      : height - padding?.top - padding?.bottom}px;
    width: ${whiteBorder
      ? width - whiteBorder * 2
      : width - padding?.left - padding?.right}px;
    overflow: ${hideOverflow ? 'visible' : 'hidden'};
    position: relative;
  `,
)

export const NoPrint = styled(Flex)`
  position: absolute;
  background-color: ${c('gray4')};
`
