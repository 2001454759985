import React from 'react'
import * as S from './WelcomeModal.styled'
import { Flex, Icon, Text } from 'components'

export default (props) => (
  <S.ContentWrapper column {...props}>
    <Text transform="uppercase" weight="500" margin="0 0 8px 0">
      Welcome to
    </Text>
    <Text size="24px" weight="600" margin="0 0 32px 0">
      Bass-Mollett’s Director’s Print Suite Software
    </Text>
    <Text margin="0 0 16px 0">
      Thank you for selecting us for your design needs. Our software is free to
      Bass-Mollett stationery customers, please feel free to familiarize
      yourself with the software and contact us with any questions or concerns.
    </Text>
    <Text margin="0 0 16px 0">
      During your trial period, please call in to register your account with our
      software support team. Accounts must be registered to keep using the
      software.
    </Text>
    <Text size="16px" weight="600" margin="0 0 16px 0">
      Need some help?
    </Text>
    <Text margin="0 0 32px 0">
      Please feel free to give us a call to schedule demonstrations and for
      further information.
    </Text>
    <Flex>
      <Flex margin="0 24px 0 0">
        <Icon icon="welcomeEmail" />
        <Flex column margin="0 0 0 16px">
          <Text margin="0 0 12px 0">Email</Text>
          <Text as="a" href="mailto:support@bass-mollet.com">
            support@bass-mollet.com
          </Text>
        </Flex>
      </Flex>
      <Flex>
        <Icon icon="welcomePhone" />
        <Flex column margin="0 0 0 16px">
          <Text margin="0 0 12px 0">Please call toll free</Text>
          <Text as="a" href="tel:1-855-277-0436">
            1-855-277-0436
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </S.ContentWrapper>
)
