export default (variables) => {
  const { editorHistoryCounterAtom, get, newField, editorHistoryAtom, set } =
    variables
  const undoCounter = get(editorHistoryCounterAtom)
  const includesId = Object.keys(newField).includes('id')
  const addToHistory = includesId && undoCounter === 1 //will only add to history if the pointer (undoCounter) is at default position 1.
  if (addToHistory) {
    const editorHistory = get(editorHistoryAtom)
    const lastEntry = editorHistory[editorHistory.length - 1]
    let isEqual
    if (lastEntry) {
      //necessary guard to avoid setting two equal entries to history
      isEqual = Object.values(lastEntry).every((val, i) => {
        if (val !== Object.values(newField)[i]) return false
        else return true
      })
    }
    if (!isEqual) set(editorHistoryAtom, [...editorHistory, newField])
  } else if (undoCounter !== 1 && includesId) {
    set(editorHistoryCounterAtom, 1) //if user makes changes after undoing it will reset pointer and history
    set(editorHistoryAtom, [])
  }
}
