import React from 'react'
import { Flex, Text, Button, Input, Clipboard } from 'components'
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil'
import {
  selectedItemAtom,
  showPinturaAtom,
  itemSelector,
  itemAtomFamily,
} from 'atoms'
import { useDuplicateItem } from 'hooks'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const duplicateItem = useDuplicateItem()

  const [width, setWidth] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const [height, setHeight] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'height' }),
  )
  const [circle, setCircle] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.circle' }),
  )
  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))
  const setShowPintura = useSetRecoilState(showPinturaAtom)

  const toggleShape = (shape) => setCircle(shape === 'circle')
  const showPinturaModal = () => setShowPintura(true)

  return (
    <Flex column padding="0 12px">
      <Button center margin="0 0 16px" onClick={duplicateItem}>
        Duplicate Image
      </Button>
      <Button center margin="0 0 16px" onClick={deleteItem}>
        Delete Image
      </Button>
      <Button center margin="0 0 16px" onClick={showPinturaModal}>
        Adjust Image
      </Button>
      <Clipboard />
      <Text margin="0 0 16px">Size</Text>
      <Flex margin="0 0 24px">
        <Input
          value={[width, setWidth]}
          label="Width (px)"
          margin="0 16px 0 0"
          type="number"
        />
        <Input value={[height, setHeight]} label="Height (px)" type="number" />
      </Flex>
      <Flex column margin="0 0 16px">
        <Text margin="0 0 16px">Shape</Text>
        <Flex>
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorSquareImage"
            disabled={!circle}
            onClick={() => toggleShape('square')}
          />
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorOvalImage"
            disabled={circle}
            onClick={() => toggleShape('circle')}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
