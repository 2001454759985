import { css } from 'styled-components'

export default css`
  .mce-content-body {
    font-family: 'Times New Roman';
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 100%;
    overflow-x: visible;
    margin: 0px;
    line-height: 1;
    padding: 0 2px;
  }

  //TODO - this helps offset difficulty following cursor and also having the wrong size outline on the text box
  .mce-content-body:focus {
    outline: none;
  }
  .mce-edit-focus {
    outline: none;
  }

  .mce-content-body p {
    margin: 0;
  }

  .tox-tinymce-inline {
    z-index: 10;
  }

  #obituary .mce-content-body {
    padding: 24px;
    line-height: 18px;
  }
`
