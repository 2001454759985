import React, { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { orderProducts, orderLayouts } from 'api'
import {
  selectedProductLineAtom,
  selectedProductsSelector,
  selectedThemeAtom,
  orderAtom,
  selectedProductsAtom,
  productTabAtom,
} from 'atoms'
import { PrintPackageHeader, CustomizeProduct } from './'
import { CustomizeTabs } from 'components'

export default ({ caseInfo }) => {
  const selectedProductLine = useRecoilValue(selectedProductLineAtom)
  const selectedTheme = useRecoilValue(selectedThemeAtom)
  const order = useRecoilValue(orderAtom)
  const [fetchedOrderProducts, setOrderProducts] =
    useRecoilState(selectedProductsAtom)
  const [storedProductTab, setStoredProductTab] = useRecoilState(productTabAtom)
  const [selectedProducts, setSelectedProducts] = useRecoilState(
    selectedProductsSelector,
  )
  const [activeTab, setActiveTab] = useState('')
  const [mappedProductsArr, setMappedProductsArr] = useState([])
  const [productsTabs, setProductTabs] = useState([])

  const getOrderProducts = async () => {
    const { data: orderProductsData } = await orderProducts.getOrderProducts(
      order.collection_id,
      order.id,
    )
    setOrderProducts(orderProductsData)
  }

  const mapLayouts = async (products) => {
    let mappedProducts = []
    for (const product of products) {
      if (product.id) {
        const { data: layouts } = await orderLayouts.getOrderLayouts(product.id)
        const mappedProductObject = {
          ...product,
          layouts: layouts,
        }
        mappedProducts.push(mappedProductObject)
      }
    }
    setMappedProductsArr(mappedProducts)
  }

  useEffect(() => {
    order.id && getOrderProducts()
    //eslint-disable-next-line
  }, [order])

  useEffect(() => {
    fetchedOrderProducts && mapLayouts(fetchedOrderProducts)
    //eslint-disable-next-line
  }, [
    fetchedOrderProducts,
    selectedProductLine,
    selectedTheme,
    selectedProducts,
  ])

  const getTabs = () => {
    const sortedProductsAndLayouts = mappedProductsArr
      .filter((product) => product.selected)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    const storedTabExists = sortedProductsAndLayouts.find(
      (product) => product.name === storedProductTab,
    )

    return sortedProductsAndLayouts.map((product, idx) => {
      if (idx === 0) {
        if (storedTabExists) {
          // There is a stored tab.
          // We set the stored tab as the active tab.
          setActiveTab(storedProductTab)
        } else {
          // There is no stored tab or it doesn't exist:
          // We set the first tab as the active tab and the stored tab.
          setActiveTab(product.name)
          setStoredProductTab(product.name)
        }
      }
      return {
        title: product.name.includes('Register Book:')
          ? product.name.split(': ')[1]
          : product.name,
        content: (
          <CustomizeProduct
            productInfo={product}
            productLineInfo={selectedProductLine}
            themeInfo={selectedTheme}
            productsAndLayouts={sortedProductsAndLayouts}
          />
        ),
      }
    })
  }

  const removeProduct = (tab) => {
    setSelectedProducts(tab.content.props.productInfo)
  }

  const setActiveTabAndPersist = (tab) => {
    setActiveTab(tab)
    setStoredProductTab(tab)
  }

  useEffect(() => {
    const tabs = getTabs()
    setProductTabs(tabs)
    //eslint-disable-next-line
  }, [mappedProductsArr, selectedProducts])

  return (
    <>
      <PrintPackageHeader
        title="Customize and print"
        caseInfo={caseInfo}
        margin="0 0 32px"
      />
      <CustomizeTabs
        tabs={productsTabs}
        width="250px"
        selected={[activeTab, setActiveTabAndPersist]}
        icon="deleteOutline"
        iconClick={removeProduct}
      />
    </>
  )
}
