import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Text, Input, Button } from 'components'
import { validateEmail } from 'utils'
import { users } from 'api'

export default ({
  userPayload,
  handleSetUserPayload,
  nameInputRef,
  setStep,
}) => {
  const { loginWithRedirect } = useAuth0()
  const [nameError, setNameError] = useState(' ')
  const [emailError, setEmailError] = useState(' ')
  const handleEmailBlur = () =>
    userPayload.email.length === 0
      ? setEmailError('Email is required')
      : !validateEmail(userPayload.email)
      ? setEmailError('Please enter a valid email')
      : setEmailError(' ')

  const handleNameBlur = () =>
    setNameError(userPayload.name.length === 0 ? 'Name is required' : ' ')

  const handleContinue = async () => {
    try {
      const res = await users.checkEmail(userPayload.email)
      if (res.data.action === 'unarchiveUser')
        return setEmailError('User has been archived')
      if (res.status === 200) return setStep(2)
    } catch (error) {
      setEmailError(error.response.data.message)
    }
  }
  return (
    <>
      <>
        <Text size="12px" margin="0 0 8px 0">
          Step 1 of 2
        </Text>
        <Text weight="600" size="16px" margin="0 0 32px 0">
          User information
        </Text>
        <Input
          label="Name"
          placeholder="Placeholder"
          value={[
            userPayload.name,
            (value) => handleSetUserPayload('name', value),
          ]}
          margin="0 0 8px 0"
          ref={nameInputRef}
          onBlur={handleNameBlur}
        />
        <Text size="14px" color="error" margin="0 0 10px 0">
          {nameError}
        </Text>
        <Input
          label="Email"
          placeholder="Placeholder"
          value={[
            userPayload.email,
            (value) => handleSetUserPayload('email', value),
          ]}
          margin="0 0 8px 0"
          onBlur={handleEmailBlur}
        />
        <Text size="14px" color="error" margin="0 0 10px 0">
          {emailError}
        </Text>
        <Button
          center
          primary
          margin="0 0 24px 0"
          onClick={handleContinue}
          disabled={
            userPayload.name.length === 0 ||
            userPayload.email.length === 0 ||
            !validateEmail(userPayload.email)
          }
        >
          Continue
        </Button>

        <Text
          clickable
          color="brand"
          decoration="underline"
          align="center"
          onClick={loginWithRedirect}
        >
          Already have an account? Log in
        </Text>
      </>
    </>
  )
}
