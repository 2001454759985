import React, { useState, useEffect } from 'react'
import { assetCategories as categories } from 'utils'
import { Flex, AssetDrawers, LoadingInsertImages } from 'components'
import { assets } from 'api'

export default ({ setShow }) => {
  const [loading, setLoading] = useState(true)
  const [drawers, setDrawers] = useState([])

  const getItems = async () => {
    setLoading(true)
    const { data: thankYouData } = await assets.getAssetsByCategory(
      categories['Thank You Verses'],
    )
    const { data: prayerCardData } = await assets.getAssetsByCategory(
      categories['Prayer Card Verses'],
    )
    const { data: serviceRecordData } = await assets.getAssetsByCategory(
      categories['Service Record Verses'],
    )
    const { data: styleizedData } = await assets.getAssetsByCategory(
      categories['All Stylized Verses'],
    )
    const { data: bookmarkData } = await assets.getAssetsByCategory(
      categories['Bookmark Verses'],
    )
    //Per Bri (https://trello.com/c/tEWc61LQ/1603-bookmark-verse) combine bookmark verse categories
    let bookmarkVerses = [
      {
        name: 'Bookmark Verses ',
        assets: [...bookmarkData[0].assets, ...bookmarkData[1].assets],
      },
    ]
    const { data: jewishHebrewData } = await assets.getAssetsByCategory(
      categories['Jewish/Hebrew Interiors'],
    )
    const { data: spanishData } = await assets.getAssetsByCategory(
      categories['Spanish Verses'],
    )

    setDrawers([
      ...thankYouData,
      ...prayerCardData,
      ...serviceRecordData,
      ...styleizedData,
      ...bookmarkVerses,
      ...jewishHebrewData,
      ...spanishData,
    ])

    setLoading(false)
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full>
      <AssetDrawers
        drawers={drawers}
        categoryKey="assets"
        categoryNameKey="name"
        imageKey="image"
        nameKey="name"
        setShow={setShow}
        isVerse={true}
      />
    </Flex>
  )
}
