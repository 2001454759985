import React, { useEffect, useState } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { userAtom, funeralHomeAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { users, axios, funeralHomes } from 'api'
import {
  Archived,
  Landing,
  PdfRender,
  AlignmentSheet,
  SignUp,
  BetaWelcome,
  FamilyUpload,
  NewUser,
} from 'components'

const AuthProvider = ({ children }) => {
  const setUser = useSetRecoilState(userAtom)
  const setFuneralHome = useSetRecoilState(funeralHomeAtom)
  const [token, setToken] = useState('')
  const [error, setError] = useState('')
  const [headerSet, setHeaderSet] = useState(false)

  const location = useState(window.location.pathname)

  const { user, logout, getAccessTokenSilently, isLoading } = useAuth0()

  // When auth0's user changes, request a new access token
  useEffect(() => {
    user && getAccessTokenSilently().then(setToken)
  }, [user, getAccessTokenSilently])

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      setHeaderSet(true)
    }
  }, [token])

  // When the access token changes, request the user from the API
  useEffect(() => {
    ;(async () => {
      if (token) {
        try {
          const { data: loggedInUser } = await users.user(token)
          if (loggedInUser.archived)
            return logout({ returnTo: `${window.location.origin}/archived` })
          const { data: home } = await funeralHomes
            .getFuneralHome(token)
            .catch(function (error) {
              if (
                error.response &&
                'Missing funeral home identification.' ===
                  error.response.data.message
              ) {
                logout({ returnTo: `${window.location.origin}/new-user` })
              }
            })
          const { data: logos } = await funeralHomes.getFuneralHomeLogos()

          if (loggedInUser.id) {
            setUser(loggedInUser)
            home.logo = logos && (logos.small || logos.medium || logos.large)
          } else if (loggedInUser.statusCode === 401) {
            setError('User error')
          } else {
            setError('Display a Toast?')
            logout({ returnTo: window.location.origin })
          }
          home.id && setFuneralHome(home)
        } catch {
          setError(
            'There is either no funeral home or no user associated with this email. Please return to login and try a different account.',
          )
        }
      }
    })()
    // eslint-disable-next-line
  }, [token, logout])
  return (
    <>
      {location[0].includes('new-user') ? (
        <NewUser />
      ) : location[0].includes('archived') ? (
        <Archived />
      ) : location[0].includes('pdfProduct') ? (
        <PdfRender />
      ) : location[0].includes('pdfAlign') ? (
        <AlignmentSheet />
      ) : location[0].includes('sign-up') ? (
        <SignUp />
      ) : location[0].includes('beta-welcome') ? (
        <BetaWelcome />
      ) : location[0].includes('familyUpload') ? (
        <FamilyUpload />
      ) : (isLoading && !user?.id) || !headerSet ? (
        <Landing />
      ) : (
        children
      )}
      {error && <Landing error={error} />}
    </>
  )
}

export default ({ children }) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
  >
    <AuthProvider>{children}</AuthProvider>
  </Auth0Provider>
)
