import React, { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'hooks'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
  orders,
  themes,
  customThemes,
  products,
  cases,
  orderProducts,
  collections,
} from 'api'
import {
  selectedProductLineAtom,
  selectedProductsAtom,
  selectedThemeAtom,
  customizationAtom,
  orderAtom,
  themesAtom,
  pinnedThemesAtom,
  customThemesAtom,
  productsAtom,
  caseAtom,
  orderSelector,
} from 'atoms'
import { Flex, Button, Link, NavItem, BetaWatermark } from 'components'

export default () => {
  const [selectedProductLine, setSelectedProductLine] = useRecoilState(
    selectedProductLineAtom,
  )
  const [selectedProducts, setSelectedProducts] =
    useRecoilState(selectedProductsAtom)
  const [selectedTheme, setSelectedTheme] = useRecoilState(selectedThemeAtom)
  const customization = useRecoilValue(customizationAtom)
  const [order, setOrder] = useRecoilState(orderAtom)
  const setThemes = useSetRecoilState(themesAtom)
  const setPinnedThemes = useSetRecoilState(pinnedThemesAtom)
  const setCustomThemes = useSetRecoilState(customThemesAtom)
  const setProducts = useSetRecoilState(productsAtom)
  const setCase = useSetRecoilState(caseAtom)
  const setOrderSelector = useSetRecoilState(
    orderSelector({ property: 'collection_id' }),
  )

  const navigate = useNavigate()
  const { pathname: location } = useLocation()

  const getOrder = async () => {
    if (!order.id) {
      const [, , order_id] = location.split('/')
      const { data: fetchedOrder } = await orders.getOrder(order_id)
      setOrder(fetchedOrder)
      setSelectedProductLine(fetchedOrder.collection_id)
    }
  }

  const getThemes = async () => {
    if (order.collection_id) {
      const { data: pinnedThemesData } = await themes.getPinnedThemes(
        order.collection_id,
      )
      setPinnedThemes(pinnedThemesData)
      const { data: customThemesData } = await customThemes.getCustomThemes()
      setCustomThemes(customThemesData)
      const { data: themesData } = await themes.getThemeCategoriesByCollection(
        order.collection_id,
      )
      setThemes(themesData)
      const { data: collectionData } = await collections.getCollection(
        order.collection_id,
      )
      setSelectedProductLine(collectionData)
    }
  }

  const getProducts = async () => {
    if (order.collection_id) {
      const { data: productsData } = await products.getProductsByCollection(
        order.collection_id,
      )
      setProducts(productsData)
    }
  }

  const getOrderProducts = async () => {
    if (order.collection_id) {
      const { data: orderProductsData } = await orderProducts.getOrderProducts(
        order.collection_id,
        order.id,
      )
      setSelectedProducts(orderProductsData)
    }
  }
  const getCase = async () => {
    if (order.case_id) {
      const { data: caseData } = await cases.getCase(order.case_id)
      setCase(caseData)
    }
  }

  const getTheme = async () => {
    if (order.theme_id) {
      const { data: themeData } = await themes.getThemeById(order.theme_id)
      setSelectedTheme(themeData)
    }
  }

  const updateOrderCollection = async () => {
    selectedProductLine?.id && setOrderSelector(selectedProductLine.id)
    getThemesByProductId()
  }
  const getThemesByProductId = async () => {
    if (selectedProductLine?.id) {
      const { data } = await themes.getThemeCategoriesByCollection(
        selectedProductLine.id,
      )
      setThemes(data)
    }
  }

  //TODO: make this work as needed - we filter the selectedProducts against the theme layouts to determine which ones are 'available' right now this overwrites things so needs more work, but the sort logic is correct.
  // const getThemeLayouts = async () => {
  //   const hasAvailability = selectedProducts[0].hasOwnProperty("available");
  //   if (!hasAvailability) {
  //     let orderProducts = await Promise.all(
  //       selectedProducts.map(async (product) => {
  //         const { data: themeLayoutData } =
  //           await themes.getSelectedThemeLayoutByProduct(
  //             product.product_id,
  //             order.theme_id
  //           );
  //         const newSelectedProduct = { ...product, available: true };
  //         const unavailableLayouts = themeLayoutData.some(
  //           (layout) =>
  //             layout.product_id === product.product_id && !layout.available
  //         );
  //         if (unavailableLayouts) newSelectedProduct.available = false;
  //         return newSelectedProduct;
  //       })
  //     );
  //     setSelectedProducts(orderProducts);
  //   }
  // };

  useEffect(() => {
    getOrder()
    //eslint-disable-next-line
  }, [selectedTheme, selectedProductLine])

  useEffect(() => {
    getThemes()
    getProducts()
    getOrderProducts()
    getTheme()
    getCase()
    //eslint-disable-next-line
  }, [order])

  const pages = useMemo(() => {
    const [, , currentPath] = location.split('/')
    const baseUrl = `/package/${currentPath}`
    const pageArr = [
      `${baseUrl}`,
      `${baseUrl}/products`,
      `${baseUrl}/theme`,
      `${baseUrl}/customize`,
    ]
    return pageArr
  }, [location])

  const locationIdx = useMemo(() => {
    return pages.indexOf(location)
  }, [location, pages])

  const next = useMemo(() => {
    return pages[locationIdx + 1]
  }, [locationIdx, pages])

  const back = useMemo(() => {
    return pages[locationIdx - 1]
  }, [locationIdx, pages])

  const currentStep = useMemo(() => {
    switch (locationIdx) {
      case 0:
        if (selectedProductLine) {
          return 1
        } else {
          return 0
        }
      case 1:
        if (selectedProducts.length > 0) {
          return 2
        } else {
          return 1
        }
      case 2:
        if (selectedTheme) {
          return 3
        } else {
          return 2
        }
      case 3:
        if (customization) {
          return 4
        } else {
          return 3
        }
      default:
        return 0
    }
  }, [
    selectedProductLine,
    selectedProducts,
    selectedTheme,
    customization,
    locationIdx,
  ])

  const returnToDashboard = () => {
    setSelectedProductLine(null)
    setSelectedTheme(null)
    setSelectedProducts([])
    setOrder(null)
    setCase(null)
    navigate(`/dashboard`)
  }

  const handleNext = () => {
    if (location === pages[0]) {
      updateOrderCollection()
    } else if (location === pages[1]) {
      getThemesByProductId()
    }
  }

  return (
    <>
      <BetaWatermark />
      <Flex center>
        <Button outline onClick={() => returnToDashboard()}>
          Save and exit
        </Button>
      </Flex>
      <Flex>
        <NavItem
          text="1. Product Line"
          link={pages[0]}
          selected={location === pages[0]}
        />
        <NavItem
          text="2. Products"
          link={pages[1]}
          selected={location === pages[1]}
          disabled={currentStep < 1}
          onClick={() => updateOrderCollection()}
        />
        <NavItem
          text="3. Theme"
          link={pages[2]}
          selected={location === pages[2]}
          disabled={selectedProducts?.length < 1}
          onClick={() => getThemesByProductId()}
        />
        <NavItem
          text="4. Customize"
          link={pages[3]}
          selected={location === pages[3]}
          disabled={!selectedTheme?.id || selectedProducts?.length < 1}
        />
      </Flex>
      <Flex center>
        {location !== pages[0] && (
          <Link href={back}>
            <Button outline iconLeft="chevronLeft">
              Back
            </Button>
          </Link>
        )}
        {location !== pages[3] && (
          <Link href={next} disabled={currentStep < locationIdx + 1}>
            <Button
              primary
              icon="chevronRight"
              margin="0 0 0 16px"
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </Link>
        )}
        {location === pages[3] && (
          <Button
            primary
            icon="check"
            margin="0 0 0 16px"
            onClick={() => returnToDashboard()}
          >
            Finish
          </Button>
        )}
      </Flex>
    </>
  )
}
