import React from 'react'
import { Flex, Text } from 'components'

export default ({ ...props }) => {
  const clickFeedback = () =>
    document.getElementById('bugmuncher_button').click()

  return (
    <Flex
      full
      center
      height="100%"
      width="250px"
      padding="16px"
      background="white"
      flex="0"
      {...props}
    >
      <Text size="12px" color="gray2" onClick={() => clickFeedback()}>
        Give feedback
      </Text>
    </Flex>
  )
}
