import React from 'react'
import * as S from './WelcomeModal.styled'
import { Flex, Icon, Text } from 'components'

export default () => (
  <S.ContentWrapper>
    <Flex>
      <Flex column margin="0 24px 0 0" maxWidth="240px">
        <Text transform="uppercase" weight="500" margin="0 0 8px 0">
          Getting started
        </Text>
        <Text size="24px" weight="600" margin="0 0 32px 0">
          Here are a few items that will help.
        </Text>
      </Flex>
      <Flex column>
        <Text margin="0 0 8px 0" weight="500">
          We recommend using Google Chrome
        </Text>
        <Flex align="center" margin="0 0 24px 0">
          <Text
            color="brand"
            weight="500"
            as="a"
            target="_blank"
            href="https://www.google.com/chrome/"
          >
            Download Google Chrome
          </Text>
          <Icon icon="anchorArrow" />
        </Flex>
        <Text margin="0 0 8px 0" weight="500">
          We recommend using Adobe Acrobat Reader
        </Text>
        <Flex align="center" margin="0 0 24px 0">
          <Text
            color="brand"
            weight="500"
            as="a"
            target="_blank"
            href="https://get.adobe.com/reader/"
          >
            Download Adobe Acrobat
          </Text>
          <Icon icon="anchorArrow" />
        </Flex>
        <Text margin="0 0 8px 0" weight="500">
          Set Google Chrome to open PDFs inside Adobe Acrobat
        </Text>
        <Flex align="center" margin="0 0 24px 0">
          <Text
            color="brand"
            weight="500"
            as="a"
            target="_blank"
            href="https://vimeo.com/248778767"
          >
            Easy Instructional Video
          </Text>
          <Icon icon="anchorArrow" />
        </Flex>
      </Flex>
    </Flex>
  </S.ContentWrapper>
)
