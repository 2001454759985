import { axios } from 'api'

export const create = async (payload) => axios.post(`createTukiosFilm`, payload)
//expected payload
//{
//   order_id: <uuid>,
//   case_id: <uuid>,
//   date_of_birth: <date>,
//   date_of_death: <date>,
//   name_of_deceased: <string>
// }
