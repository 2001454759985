import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0, Recoil, Theme, Initiation, Routes } from 'providers'
import { reportWebVitals } from 'utils'
import 'assets/css/fonts.css'
import * as FullStory from '@fullstory/browser'

FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID })

ReactDOM.render(
  <Recoil>
    <Theme>
      <Initiation>
        <Auth0>
          <Routes />
        </Auth0>
      </Initiation>
    </Theme>
  </Recoil>,
  document.getElementById('root'),
)

reportWebVitals()
