import React, { useEffect, useState } from 'react'
import { cases } from 'api'
import { Expandable, Flex, FileDropzone, Button } from 'components'
import { PersonalImageCard } from './'
import { useRecoilState } from 'recoil'
import { caseValuesAtom } from 'atoms'
import Memorials from './Memorials'
import SendInvite from './SendInvite'
import { useSetToast } from 'hooks'

export default ({
  saveCase,
  case_id,
  name_of_deceased,
  setActiveTab,
  activeTab,
}) => {
  const [personalImages, setPersonalImages] = useRecoilState(
    caseValuesAtom('personal_images'),
  )
  const [expandedPersonalImages, setExpandedPersonalImages] = useState(true)
  const [expandedMemorials, setExpandedMemorials] = useState(false)
  const [expandedSendInvite, setExpandedSendInvite] = useState(false)
  const setToast = useSetToast()

  const removeArrayInput = async (index) => {
    //this endpoint apparently also deletes the image from s3, something we should do more of, lol
    const resp = await cases.deleteCaseImage(case_id, {
      filename: personalImages.value[index],
    })
    setPersonalImages({ ...personalImages, value: resp.data })
  }

  useEffect(() => {
    setExpandedPersonalImages(true)
    setExpandedMemorials(false)
    setExpandedSendInvite(false)
  }, [activeTab])

  const makeDefault = (index) => {
    let newArr = [...personalImages.value]
    const removedElement = newArr.splice(index, 1)
    newArr = [...removedElement, ...newArr]
    setPersonalImages({ ...personalImages, value: newArr })
  }

  const uploadCaseImages = async (filesArray) => {
    Promise.all(filesArray.map((file) => uploadCaseImage(file)))
  }
  const uploadCaseImage = async (fileObject) => {
    let fd = new FormData()
    fd.append('file', fileObject, 'blob')
    const resp = await cases.uploadOptimizedCaseImage(case_id, fd)
    setPersonalImages({ ...personalImages, value: resp.data })
    setToast({ text: resp.data ? 'Image Uploaded!' : 'Image upload failed. ' })
  }

  const saveAndContinue = (expanded) => {
    switch (expanded) {
      case 'personalImages':
        setExpandedPersonalImages(false)
        setExpandedMemorials(true)
        break
      case 'memorials':
        setExpandedMemorials(false)
        setExpandedSendInvite(true)
        break
      case 'sendInvite':
        setExpandedPersonalImages(false)
        setExpandedMemorials(false)
        setExpandedSendInvite(false)
        setActiveTab('Military/Veteran honors')
        break
      default:
        //for now collapse all
        setExpandedPersonalImages(false)
        setExpandedMemorials(false)
        setExpandedSendInvite(false)
    }
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Images of the Deceased"
          show={[expandedPersonalImages, setExpandedPersonalImages]}
          full
        >
          <Flex full column>
            <FileDropzone
              height="200px"
              margin="0 0 24px 0"
              text={`Drag and drop file here to edit`}
              fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
              allowMultiple={true}
              onDrop={(files) => {
                uploadCaseImages(files)
              }}
            />
            {personalImages?.value?.map((image, index) => (
              <PersonalImageCard
                key={image}
                image={image}
                index={index}
                makeDefault={makeDefault}
                deleteImage={removeArrayInput}
              />
            ))}
          </Flex>
          <Flex justify="flex-end">
            <Button onClick={() => saveAndContinue('personalImages')} primary>
              Save and continue
            </Button>
          </Flex>
        </Expandable>

        <Expandable
          margin="0 0 32px 0"
          title="Memorials"
          show={[expandedMemorials, setExpandedMemorials]}
          full
        >
          <Memorials />
          <Flex justify="flex-end">
            <Button onClick={() => saveAndContinue('memorials')} primary>
              Save and continue
            </Button>
          </Flex>
        </Expandable>
        <Expandable
          margin="0 0 32px 0"
          title="Invite Family to Share Images"
          show={[expandedSendInvite, setExpandedSendInvite]}
          full
        >
          <SendInvite case_id={case_id} name_of_deceased={name_of_deceased} />
          <Flex justify="flex-end">
            <Button
              onClick={() => saveAndContinue('sendInvite')}
              primary
              margin="24px 0 0 0"
            >
              Save and continue
            </Button>
          </Flex>
        </Expandable>
      </Flex>
    </Flex>
  )
}
