import { useRecoilValue, useSetRecoilState } from 'recoil'
import { selectedItemAtom, itemAtomFamily, editorClipboardAtom } from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const item = useRecoilValue(itemAtomFamily(selectedItem))
  const setClipboard = useSetRecoilState(editorClipboardAtom)

  const copyItemToClipboard = () => {
    const copiedItem = { ...item }
    setClipboard(copiedItem)
  }
  return () => copyItemToClipboard()
}
