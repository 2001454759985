import React, { useState } from 'react'
import { Button, Flex, MenuDrawer, Text } from 'components'
import { determineItemProperties } from 'utils'
import * as Properties from '../Drawers'
import { useRecoilValue } from 'recoil'
import { selectedItemAtom, itemSelector, editorClipboardAtom } from 'atoms'
import { usePasteItemFromClipboard } from 'hooks'

export default () => {
  const selectedItemId = useRecoilValue(selectedItemAtom)
  let selectedItem = useRecoilValue(
    itemSelector({ id: selectedItemId, path: 'type' }),
  )
  const clipboard = useRecoilValue(editorClipboardAtom)
  const isClipboardEmpty = Object.keys(clipboard).length === 0
  const pasteItemFromClipBoard = usePasteItemFromClipboard()

  selectedItem =
    selectedItem && selectedItem !== 'watermark' ? selectedItem : false

  const [openDrawer, setOpenDrawer] = useState(
    selectedItem ? determineItemProperties?.[selectedItem][0] : false,
  )
  return (
    <Flex column>
      {selectedItem &&
        determineItemProperties?.[selectedItem].map((property, index) => {
          const PropertyDrawer =
            Properties?.[`${property.replace(' ', '')}Drawer`]
          return (
            <MenuDrawer
              show={[openDrawer === property, () => setOpenDrawer(property)]}
              title={property}
              key={property}
              startOpen={index === 0}
            >
              <PropertyDrawer />
            </MenuDrawer>
          )
        })}
      {!selectedItem && (
        <Text margin="32px 40px" color="gray2">
          Select an element to edit
        </Text>
      )}

      {!selectedItem && !isClipboardEmpty && (
        <Flex justify="center" padding="0 24px 24px 24px" margin="0 0 24px">
          <Button full onClick={() => pasteItemFromClipBoard()}>
            Paste Item
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
