import { axios } from 'api'

export const getThemeCategoriesByCollection = async (collection_id) =>
  axios.get(`themeCategories?collection_id=${collection_id}`)

export const getThemesByCategory = async (theme_category_id) =>
  axios.get(`themes?theme_category_id=${theme_category_id}`)

export const getPinnedThemes = async (collection_id) =>
  axios.get(`pinnedThemes?collection_id=${collection_id}`)

export const createPinnedThemes = async (payload) =>
  axios.post(`createPinnedTheme`, payload)
// expected payload
// {
//   collection_id: uuid,
//   theme_id: uuid
// }

export const deletePinnedThemes = async (pinned_theme_id) =>
  axios.delete(`pinnedThemes/${pinned_theme_id}`)

export const getThemeLayout = async (theme_id, layout_id) =>
  axios.get(`themeLayouts?layout_id=${layout_id}&theme_id=${theme_id}`)

export const getThemeLayoutByProduct = async (product_id) =>
  axios.get(`themeLayouts?product_id=${product_id}`)

export const getSelectedThemeLayoutByProduct = async (product_id, theme_id) =>
  axios.get(`themeLayouts?product_id=${product_id}&theme_id=${theme_id}`)

export const getThemeLayoutByLayout = async (layout_id) =>
  axios.get(`themeLayouts?layout_id=${layout_id}`)

export const getThemeById = async (theme_id) => axios.get(`themes/${theme_id}`)

export const getWatermark = async (watermark_id) =>
  axios.get(`watermarks/${watermark_id}`)
