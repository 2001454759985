import React, { useRef, useState, useEffect } from 'react'
import { Flex, Divider } from 'components'
import { useActions, useFillCaseImages } from 'hooks'
import { ToolbarIcon } from './'
import { cases } from 'api'
import {
  caseAtom,
  editorHistoryAtom,
  editorHistoryCounterAtom,
  objectSelector,
  pageLoadingAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as S from './EditorToolbar.styled'

export default ({ setShowSlideout }) => {
  const inputFile = useRef(null)
  const action = useActions()
  const [_case, setPersonalImages] = useRecoilState(
    objectSelector({ atom: caseAtom, property: 'personal_images' }),
  )
  const pageLoading = useRecoilValue(pageLoadingAtom)
  const fillCaseImages = useFillCaseImages()
  const [newImage, setNewImage] = useState(null)
  const editorHistory = useRecoilValue(editorHistoryAtom)
  const undoCounter = useRecoilValue(editorHistoryCounterAtom)
  const editorHistoryIsEmpty = editorHistory.length <= 1

  const handleFileChange = async (e) => {
    const { files } = e.target
    if (files && files.length) {
      let fd = new FormData()
      fd.append('file', files[0], 'blob')
      const resp = await cases.uploadCaseImage(_case.id, fd)
      setPersonalImages(resp.data)

      setNewImage({
        caseImage: resp.data.length - 1,
        url: `${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${
          resp.data[resp.data.length - 1]
        }`,
      })
    }
  }

  useEffect(() => {
    newImage && action.insertImage(newImage)
    setNewImage(null)
    //eslint-disable-next-line
  }, [_case.filledImages])

  useEffect(() => {
    newImage && fillCaseImages()
    //eslint-disable-next-line
  }, [_case.personal_images])

  const addImage = () => {
    inputFile.current.click()
  }

  return (
    <Flex
      center
      padding="0 12px"
      position="absolute"
      bottom="24px"
      height="48px"
      radius="4px"
      background="lockedAlmostBlack"
    >
      <S.HiddenInput ref={inputFile} onChange={handleFileChange} type="file" />
      <ToolbarIcon
        icon="editorImage"
        tooltip="Add image"
        onClick={() => addImage()}
      />
      <ToolbarIcon
        icon="editorText"
        tooltip="Add text"
        onClick={() => action.insertText()}
      />
      <ToolbarIcon
        icon="editorShape"
        tooltip="Add opacity box"
        onClick={() => action.insertShape()}
      />
      <ToolbarIcon
        icon="editorLine"
        tooltip="Add line"
        onClick={() => action.insertLine()}
      />
      <Divider vertical fill="lockedGray2" margin="0 6px" />
      <ToolbarIcon
        icon="editorUndo"
        tooltip="Undo"
        onClick={() => action.undo()}
        disabled={editorHistoryIsEmpty}
      />
      <ToolbarIcon
        icon="editorRedo"
        tooltip="Redo"
        onClick={() => action.redo()}
        disabled={undoCounter === 1}
      />
      <Divider vertical fill="lockedGray2" margin="0 6px" />
      <ToolbarIcon
        icon="editorZoomOut"
        tooltip="Zoom out"
        onClick={() => action.zoomOut()}
      />
      <ToolbarIcon
        icon="editorZoomIn"
        tooltip="Zoom in"
        onClick={() => action.zoomIn()}
      />
      <Divider vertical fill="lockedGray2" margin="0 6px" />
      <ToolbarIcon
        icon="editorPreviousPage"
        tooltip="Previous page"
        onClick={() => action.previousPage()}
        disabled={pageLoading}
      />
      <ToolbarIcon
        icon="editorShuffler"
        tooltip="Shuffle pages"
        onClick={() => setShowSlideout(true)}
        disabled={pageLoading}
      />
      <ToolbarIcon
        icon="editorNextPage"
        tooltip="Next page"
        onClick={() => action.nextPage()}
        disabled={pageLoading}
      />
    </Flex>
  )
}
