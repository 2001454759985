import React, { useState, useEffect } from 'react'
import { Flex, Text, Button, CaseDetails, FullscreenModal } from 'components'
import { caseAtom, hasNewCaseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ title, caseInfo, ...props }) => {
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState(false)
  const _case = useRecoilValue(caseAtom)
  const hasNewCase = useRecoilValue(hasNewCaseAtom)

  useEffect(() => {
    hasNewCase && setShowCaseDetailsModal(true)
  }, [hasNewCase])

  const openCaseDetailsModal = async () => {
    _case?.id && setShowCaseDetailsModal(true)
  }

  return (
    <Flex
      width="100%"
      padding="24px"
      justify="space-between"
      align="center"
      background="paleSecondary"
      {...props}
    >
      <Flex align="center">
        <Text size="32px" weight="bold">
          {title}
        </Text>
      </Flex>
      <Flex align="center">
        <Text size="14px" margin="0 16px 0 0">
          {caseInfo}
        </Text>
        <Button default disabled={!_case?.id} onClick={openCaseDetailsModal}>
          Edit
        </Button>
      </Flex>

      <FullscreenModal show={[showCaseDetailsModal, setShowCaseDetailsModal]}>
        <CaseDetails selected_case={{ ..._case }} />
      </FullscreenModal>
    </Flex>
  )
}
