export default (
  item,
  dragElement,
  alignmentPoints,
  padding,
  clientX,
  pageRef,
  setControlledX,
) => {
  const { width, height } = item
  const { vertical, horizontal } = alignmentPoints
  const itemVerticalMiddle = dragElement.x + width / 2
  const itemHorizontalMiddle = dragElement.y + height / 2
  const paddingTop = padding?.top || 0
  const paddingLeft = padding?.left || 0
  let snapVertical, snapHorizontal
  const snapInfo = {
    verticalArea: 0,
    verticalSnap: 0,
    horizontalSnap: 0,
    horizontalArea: 0,
  }
  const verticalSnapArea = width / 4 > 25 ? width / 4 : 25
  const cursorSnapArea = width / 3 > 25 ? width / 3 : 25
  const horizontalSnapArea = 10
  const page = pageRef.getBoundingClientRect()
  const unscaledPage = pageRef
  const zoomRatioWidth = unscaledPage.clientWidth / page.width
  const baseX = clientX - page.left
  const x = Math.ceil(baseX * zoomRatioWidth)

  for (const align of vertical) {
    //Absolute value will give us the distance between the vertical middle of the dragged item and the grid vertical alignment point(align) of alignment points [](vertical).
    //Then we check if that distance is less than or equal to arbitrary snap area (verticalSnapArea), evaluates to boolean (isElementInSnapArea)
    const isElementInSnapArea =
      Math.abs(itemVerticalMiddle - align) <= verticalSnapArea
    //If isElementInSnapArea===true snapInfo.verticalArea will be assigned value of vertical alignment point (align) rendering its active state, then it will preform another check with similar logic as above (isCursorInSnapArea).
    //If the distance between the cursor's position (x) and the grid's vertical alignment point(align) is less than an arbitrary snap area (cursorSnapArea)
    if (isElementInSnapArea) {
      snapInfo.verticalArea = align
      const isCursorInSnapArea = Math.abs(x - align) <= cursorSnapArea
      //If isCursorInSnapArea===true snapVertical will be set to the alignment point (align) causing the dragged element to snap to the alignment point at its vertical middle.
      isCursorInSnapArea && (snapVertical = align - paddingLeft - width / 2)
    } else {
      //continue dragging as usual
      setControlledX(x)
    }
    //verticalSnapArea & cursorSnapArea can be adjusted to manipulate snap window.
  }

  horizontal.map((align) => {
    //get the horizontal middle of the item (height divided by 2) and determine if that position is within a set distance from the horizontal alignment point, if it is we snap to center and set the alignment line to be bolder (snapInfo tells the lines how to behave)
    if (
      itemHorizontalMiddle > align - horizontalSnapArea &&
      itemHorizontalMiddle < align + horizontalSnapArea
    ) {
      snapHorizontal = align - paddingTop - height / 2
      snapInfo.horizontalSnap = align
    }
    //for horizontal alignments, we currently anticipate that the 'center' of the section IS included in the align array (we do not have horizontal dividers). In this case each 'section' is HALF the first align value +/- the current align point.
    //that is, the first align point is 150, the align area is 75-225 or 150 -/+ 75. the same will be true for each section, the second align point is 300, the align area is 225-375px or 300 -/+ 75
    //this calculation is designed to allow the areas to split the shared internal space between align points, is does not account for edges, but for now this is an acceptable loss.

    if (
      itemHorizontalMiddle > align - horizontal[0] / 2 &&
      itemHorizontalMiddle < align + horizontal[0] / 2
    ) {
      snapInfo.horizontalArea = align
    }

    return align
  })

  return {
    snapVertical: snapVertical,
    snapHorizontal: snapHorizontal,
    snapInfo: snapInfo,
  }
}
