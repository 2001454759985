// this utility determines which drawers will be displayed
// with its corresponding item type

export default {
  image: [
    'Image',
    'Feather',
    'Opacity',
    'Rotate',
    'Positioning',
    'Border',
    'Drop Shadow',
  ],
  shape: [
    'Shape',
    'Feather',
    'Opacity',
    'Rotate',
    'Positioning',
    'Border',
    'Drop Shadow',
  ],
  text: [
    'Text Box',
    'Opacity',
    'Rotate',
    'Positioning',
    'Border',
    'Text Shadow',
    'Drop Shadow',
  ],
  line: ['Line', 'Opacity', 'Rotate', 'Positioning', 'Border', 'Drop Shadow'],
}
