import { useState } from 'react'
import { caseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { caseMemorials } from 'api'

export default () => {
  const _case = useRecoilValue(caseAtom)
  const [memorials, setMemorials] = useState([])
  const [memorial, setMemorial] = useState('')
  const [memorialError, setMemorialError] = useState('')
  const [submitter, setSubmitter] = useState('')
  const [submitterError, setSubmitterError] = useState('')

  const getMemorials = async () => {
    const { data: memorials } = await caseMemorials.getCaseMemorials(_case.id)
    if (memorials) setMemorials(memorials)
  }

  const createCaseMemorial = async () => {
    setMemorialError(memorial ? '' : 'Memorial is required')
    setSubmitterError(submitter ? '' : 'Shared by is required')
    if (!memorial || !submitter) return
    setMemorialError('')
    setSubmitterError('')

    const payload = {
      case_id: _case.id,
      memorial,
      submitter_name: submitter,
    }

    const { data } = await caseMemorials.createCaseMemorial(payload)

    if (data) {
      setMemorial('')
      setSubmitter('')
      getMemorials()
    }

    return data
  }

  const archiveMemorial = async (id) => {
    const { data } = await caseMemorials.archiveMemorial(id)
    if (data) getMemorials()
  }

  return {
    _case,
    getMemorials,
    createCaseMemorial,
    archiveMemorial,
    memorials,
    memorial,
    setMemorial,
    memorialError,
    submitter,
    setSubmitter,
    submitterError,
  }
}
