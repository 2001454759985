import {
  useRecoilValue,
  useRecoilCallback,
  useRecoilState,
  useSetRecoilState,
} from 'recoil'
import {
  editorClipboardAtom,
  itemAtomFamily,
  itemIdAtom,
  selectedItemAtom,
} from 'atoms'
import { v4 as uuidv4 } from 'uuid'

export default () => {
  const itemIds = useRecoilValue(itemIdAtom)
  const [clipboard, setClipboard] = useRecoilState(editorClipboardAtom)
  const setSelectedItem = useSetRecoilState(selectedItemAtom)

  const pasteItemFromClipBoard = useRecoilCallback(({ set }) => () => {
    const uuid = uuidv4()
    const copiedItem = { ...clipboard }

    copiedItem.id = uuid
    copiedItem.x = parseInt(copiedItem.x) + 15
    copiedItem.y = parseInt(copiedItem.y) + 15

    set(itemAtomFamily(copiedItem.id), copiedItem)
    set(itemIdAtom, [...itemIds, copiedItem.id])
    setClipboard(copiedItem)
    setSelectedItem(copiedItem.id)
  })

  return () => pasteItemFromClipBoard()
}
