import { useState } from 'react'
import { users } from 'api'
import { useSetToast } from 'hooks'

export default () => {
  const setToast = useSetToast()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [expDate, setExpDate] = useState('')
  const [expDateError, setExpDateError] = useState('')
  const [numOfImages, setNumOfImages] = useState(25)

  const submit = async (id, deceased, twoWeekLimit) => {
    setNameError(name ? '' : 'Name is required')
    setEmailError(email ? '' : 'Email is required')
    setExpDateError(expDate ? '' : 'Expiration date is required')

    if (!name || !email || !expDate) return

    const expDateLessThanTwoWeeks = expDate
      ? expDate.getTime() < twoWeekLimit.getTime()
      : null

    setExpDateError(expDateLessThanTwoWeeks ? '' : 'Expiration date past limit')

    if (!expDateLessThanTwoWeeks) return

    setNameError('')
    setEmailError('')
    setExpDateError('')

    const payload = {
      email,
      case_id: id,
      name_of_deceased: deceased,
      recipient: name,
      number_allowed: numOfImages,
      expiration_date: expDate,
    }

    const { data } = await users.sendShareInvite(payload)

    if (data.emailSent) {
      setName('')
      setEmail('')
      setExpDate('')
      setToast({ text: 'Invite Sent!' })
      return
    }
  }

  return {
    name,
    setName,
    nameError,
    setNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    expDate,
    setExpDate,
    expDateError,
    setExpDateError,
    numOfImages,
    setNumOfImages,
    submit,
  }
}
