import React from 'react'
import * as S from './LoadingPlaceholder.styled'

export default ({ ...props }) => (
  <S.LoadingPlaceholder
    full
    background="gray5"
    height="100%"
    radius="2px"
    {...props}
  />
)
