import { axios } from 'api'

export const getOrderProducts = async (collection_id, order_id) =>
  axios.get(
    `orderProductDetails?collection_id=${collection_id}&order_id=${order_id}`,
  )

export const createOrderProduct = async (payload) =>
  axios.post(`createOrderProductDetails`, payload)
// expected payload (ALL REQUIRED):
// {
//   name: <String>,
//   collection_id: <UUID>,
//   product_id: <UUID>,
//   order_id: <UUID>,
//   thumbnail:<String (image-url)>,
//   selected: <Boolean>,
// }

export const updateOrderProduct = async (order_product_id, payload) =>
  axios.patch(`orderProductDetails/${order_product_id}`, payload)
// expected payload (ALL OPTIONAL):
// {
//   name: <String>,
//   collection_id: <UUID>,
//   order_id: <UUID>,
//   thumbnail:<String (image-url)>,
//   selected: <Boolean>,
// }
