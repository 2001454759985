import React, { useState, useMemo } from 'react'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import {
  selectedProductsSelector,
  orderAtom,
  selectedProductsAtom,
} from 'atoms'
import { orderProducts } from 'api'
import { PrintPackageHeader } from './'
import { CardSelector, Text, Flex, SearchBar } from 'components'

export default ({ caseInfo, categories, products }) => {
  const [search, setSearch] = useState('')
  const [selectedProducts, setSelectedProducts] = useRecoilState(
    selectedProductsSelector,
  )
  const order = useRecoilValue(orderAtom)
  const setOrderProducts = useSetRecoilState(selectedProductsAtom)

  const filteredCategories = useMemo(() => {
    if (search.trim() === '') {
      const filteredCategories = {}
      Object.keys(categories).forEach((category) => {
        const filtered = products.filter((product) => {
          const exists = categories[category].findIndex(
            (obj) => obj.title === product.name,
          )
          return exists > -1
        })
        filteredCategories[category] = filtered
      })
      return filteredCategories || categories
    } else {
      let filteredCategories = {}
      const searchRegex = new RegExp(`${search}`, 'i')
      Object.keys(categories).forEach((category) => {
        const filtered = products.filter((product) => {
          const exists = categories[category].findIndex(
            (obj) => obj.title === product.name,
          )
          return exists > -1
        })

        filteredCategories[category] = filtered.filter((product) =>
          searchRegex.test(product.name),
        )
      })
      return filteredCategories
    }
  }, [categories, products, search])

  const categoryAmounts = useMemo(() => {
    let categoryObject = {}
    Object.keys(categories).forEach((category) => {
      categoryObject[category] = selectedProducts.filter((selectedProduct) =>
        filteredCategories[category].some(
          (product) =>
            product.id === selectedProduct.product_id &&
            selectedProduct.selected,
        ),
      ).length
    })
    return categoryObject
  }, [selectedProducts, filteredCategories, categories])

  const getOrderProducts = async () => {
    const { data: orderProductsData } = await orderProducts.getOrderProducts(
      order.collection_id,
      order.id,
    )
    setOrderProducts(orderProductsData)
  }

  return (
    <>
      <PrintPackageHeader title="Choose products" caseInfo={caseInfo || ''} />
      <SearchBar
        placeholder="Search"
        value={[search, setSearch]}
        margin="32px 40px"
        width="600px"
      />
      {Object.keys(filteredCategories).map((category, idx) => (
        <Flex column width="100%" margin="0 40px" key={`category-${idx}`}>
          {filteredCategories[category].length > 0 && (
            <>
              <Flex align="center">
                <Text size="16px" weight="bold">
                  {category} •{' '}
                </Text>
                <Text size="14px">{categoryAmounts[category]} Selected</Text>
              </Flex>
              <CardSelector
                padding="16px 0 40px"
                cardAlign="flex-start"
                cardJustify="flex-start"
                cards={filteredCategories[category]}
                cardWidth="212px"
                cardHeight="180px"
                cardPadding="24px 56px"
                selectedState={[selectedProducts, setSelectedProducts]}
                allowMultiple
                matchProperty="product_id"
                selectedStateUpdate={getOrderProducts}
              />
            </>
          )}
        </Flex>
      ))}
    </>
  )
}
