import React from 'react'
import { Grid } from 'components'
import { ImageItem } from '../Items'

export default ({
  setShow,
  items,
  imageKey,
  nameKey,
  srcPrefix,
  type,
  canDelete,
  isVerse,
  ...props
}) => (
  <Grid columns="2" {...props}>
    {items?.map((item, index) => (
      <ImageItem
        name={item[nameKey]}
        src={item[imageKey] || item}
        srcPrefix={srcPrefix || process.env.REACT_APP_S3BUCKET_UPLOADS}
        index={index}
        type={type}
        key={index}
        setShow={setShow}
        canDelete={canDelete}
        isVerse={isVerse}
      />
    ))}
  </Grid>
)
