import { axios } from 'api'

export const getFuneralHome = async (funeralHome_id) =>
  axios.get(`funeralHomes`)

//TODO: handle trial days
export const createFuneralHome = async (payload) =>
  axios.post(`createFuneralHome`, payload)
// expected payload:
// {
//   name: <String> (REQUIRED)
//   phone: <Number>,
//   address: <String>,
//   city: <String>,
//   state: <String>,
//   zip_code: <String>,
//   ip_city: <String> (allow: null),
//   ip_country_code: <String> (allow: null),
//   ip_country_name: <String> (allow: null),
//   ip_address: <String> (allow: null),
//   ip_latitude: <Number> (allow: null),
//   ip_longitude: <Number> (allow: null),
//   ip_region_code: <String> (allow: null),
//   ip_region_name: <String> (allow: null),
//   ip_zip_code: <String> (allow: null),
// }

// Serves as both an Admin and user endpoint. 😐
// As an Admin endpoint it requires the id parameter to find the home as a user endpoint it will take that from the user's details.
export const updateFuneralHome = async (payload) =>
  axios.patch(`funeralHomes`, payload)
// expected payload:
// {
//   id: <String>, admin
//   name: <String>, user/admin
//   enrolled: <Boolean> (OPTIONAL), admin
//   active: <Boolean> (OPTIONAL), admin
//   service_url_slug: <String> (OPTIONAL), user
// }

export const getFuneralHomeLogos = async () => axios.get(`funeralHomeLogos`)

export const createFuneralHomeLogo = async (size, file) =>
  axios.post(`createFuneralHomeLogo?size=${size}`, file, {
    headers: { 'content-type': 'form-data' },
  })

export const updateFuneralHomeLogo = async (logo_id, size, file) =>
  axios.patch(`funeralHomeLogos/${logo_id}?size=${size}`, file, {
    headers: { 'content-type': 'form-data' },
  })

export const getIpLocation = async () => axios.get('iplocation')
