import React from 'react'
import { Flex, DashboardNav, AdminNav, PrintPackageNav } from 'components'
import * as S from './Navigation.styled'

export default ({ type, ...props }) => (
  <>
    <Flex
      blur
      full
      background="lockedAlmostBlack"
      bgOpacity="80"
      height="70px"
      position="fixed"
      zindex="2"
    />
    <S.Navigation
      full
      justify="space-between"
      align="stretch"
      height="70px"
      padding="0 36px"
      zindex="2"
      position="fixed"
    >
      {{
        dashboard: <DashboardNav />,
        admin: <AdminNav />,
        package: <PrintPackageNav />,
      }[type] || <DashboardNav />}
    </S.Navigation>
    <Flex full height="70px" background="lockedAlmostBlack" {...props} />
  </>
)
