// // this utility will replace the text between elements that contain the "case-detail" attribute
// // with its relative case detail value

import cheerio from 'cheerio'
import get from 'lodash/get'
import { isDate, formatDate } from 'utils'

export default (value, cases, dateFormat) => {
  const $ = cheerio.load(value, null, false)

  $('[data-case-detail]').each(function () {
    const caseDetail = $(this).attr('data-case-detail')
    const sanitizedCaseDetail = caseDetail?.split(' ')?.[0]
    const caseDetailValue = get(cases, sanitizedCaseDetail)

    const initialText = $(this).text()
    const isPlaceholder = initialText.includes('{$')
    const existingText = isPlaceholder ? '' : initialText

    if (isDate(caseDetailValue)) {
      $(this).html(formatDate(caseDetailValue, dateFormat) || existingText)
    } else {
      $(this).html(caseDetailValue || existingText)
    }
  })

  return $.html()
}
