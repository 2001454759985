import React from 'react'
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector, itemAtomFamily } from 'atoms'
import { Flex, Text, Button, ColorPicker, Input, Clipboard } from 'components'
import { useDuplicateItem } from 'hooks'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const duplicateItem = useDuplicateItem()

  const [width, setWidth] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const [height, setHeight] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'height' }),
  )
  const [color, setColor] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.backgroundColor' }),
  )
  const [circle, setCircle] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.circle' }),
  )

  const toggleShape = (shape) => setCircle(shape === 'circle')

  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))

  return (
    <Flex column padding="0 12px">
      <Button center margin="0 0 16px" onClick={duplicateItem}>
        Duplicate Shape
      </Button>
      <Button center margin="0 0 16px" onClick={deleteItem}>
        Delete Shape
      </Button>
      <Clipboard />
      <Text margin="0 0 16px">Size</Text>
      <Flex margin="0 0 24px">
        <Input
          value={[width, setWidth]}
          label="Width (px)"
          margin="0 16px 0 0"
          placeholder="100"
          type="number"
        />
        <Input
          value={[height, setHeight]}
          label="Height (px)"
          placeholder="100"
          type="number"
        />
      </Flex>
      <Text margin="0 0 16px">Shape fill color</Text>
      <ColorPicker
        label="Color"
        color={[color, setColor]}
        resetButton
        margin="0 0 16px 0"
      />
      <Flex column margin="0 0 16px">
        <Text margin="0 0 16px">Shape</Text>
        <Flex>
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorSquareImage"
            disabled={!circle}
            onClick={() => toggleShape('square')}
          />
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorOvalImage"
            disabled={circle}
            onClick={() => toggleShape('circle')}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
