import React, { useState } from 'react'
import { ModalButton, FullscreenModal, CaseDetails } from 'components'
import { NewCaseModal } from '../CaseDetails'
import { caseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ buttonText, icon, type, onSave, getItems }) => {
  const [showButtonModal, setShowButtonModal] = useState(false)
  const [showFullModal, setShowFullModal] = useState(false)
  const _case = useRecoilValue(caseAtom)

  return (
    <>
      <ModalButton
        left
        primary
        text="New Case"
        icon="plus"
        show={[showButtonModal, setShowButtonModal]}
      >
        <NewCaseModal
          setShowCaseModal={setShowFullModal}
          setShowButtonModal={() => setShowButtonModal(false)}
          type={type}
          onSave={onSave}
          getItems={getItems}
        />
      </ModalButton>
      <FullscreenModal show={[showFullModal, setShowFullModal]}>
        {showFullModal && <CaseDetails selected_case={{ ..._case }} />}
      </FullscreenModal>
    </>
  )
}
