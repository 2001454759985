import axios from 'axios'
import { api } from 'utils'

const instance = axios.create({ baseURL: api })

// instance.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// instance.interceptors.request.use(
//   (config, data, token) => {
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

instance.defaults.withCredentials = false

export default instance
