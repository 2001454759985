export default (editor) => {
  // make a shortcut for tinyMCE's built in dom query
  const $ = editor.editorManager.dom.DomQuery

  // find which elements have noneditable attributes
  const nonEditableClass = editor.getParam(
    'noneditable_noneditable_class',
    'mceNonEditable',
  )

  // execute the following before any tinyMCE command
  editor.on('BeforeExecCommand', ({ command }) => {
    const permittedCommands = [
      'mceToggleFormat',
      'mceApplyTextcolor',
      'mceRemoveTextcolor',
      'FontSize',
      'FontName',
      'LineHeight',
    ]

    // determine if the command is permitted for the formatting of noneditable items
    const commandIsPermitted = permittedCommands.indexOf(command) !== -1

    // remove the noneditable class from the elements and turn contenteditable off
    commandIsPermitted &&
      $(editor.getBody())
        .find(`.${nonEditableClass}`)
        .attr('contenteditable', null)
  })

  // restore the noneditable class and turn back on contentediable after command is finished
  editor.on('ExecCommand', () => {
    $(editor.getBody())
      .find(`.${nonEditableClass}`)
      .attr('contenteditable', false)
  })
}
