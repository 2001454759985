import { v4 as uuidv4 } from 'uuid'
import { toastsAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import isArray from 'lodash/isArray'

export default () => {
  const [toasts, setToasts] = useRecoilState(toastsAtom)

  return (addToasts) => {
    if (isArray(addToasts)) {
      const newToasts = addToasts.map((toast) => ({
        ...toast,
        uuid: uuidv4(),
      }))
      setToasts([...toasts, ...newToasts])
    } else {
      const newToast = { ...addToasts, uuid: uuidv4() }
      setToasts([...toasts, { ...newToast }])
    }
  }
}
