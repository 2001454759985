import { caseDetailSpan, columnTemplates } from '.'
import startCase from 'lodash/startCase'
import { replaceInitialValues } from 'utils'

export default (editor, caseDetails, key, templates, dateFormat) => {
  // Pushes content of array as a list

  let content = ''

  caseDetails[key].forEach(
    (_, i) =>
      (content = content.concat(
        '',
        `<p>${caseDetailSpan(`${key}[${i}]`)}</p>`,
      )),
  )

  templates.push({
    type: 'menuitem',
    text: `${startCase(key)}`,
    onAction: () =>
      editor.execCommand(
        'mceInsertTemplate',
        false,
        replaceInitialValues(content, caseDetails),
      ),
  })
  // Pushes content of array as a table
  const tableContent = columnTemplates(caseDetails[key], key)

  templates.push({
    type: 'menuitem',
    text: `${startCase(key)} Columns`,
    onAction: () =>
      editor.execCommand(
        'mceInsertTemplate',
        false,
        replaceInitialValues(tableContent, caseDetails),
      ),
  })
}
