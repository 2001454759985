import React, { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Button,
  ToggleSwitch,
  Dropdown,
  Input,
  Tooltip,
  Icon,
} from 'components'
import { pageSets, pages, orderPages, orderPageSets } from 'api'
import {
  editorLayoutAtom,
  editorPagesAtom,
  editorOrderPageSetAtom,
  triggerUseEffect,
} from 'atoms'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useSetToast } from 'hooks'
import { default as _omit } from 'lodash/omit'

export default ({ setShow, basePages, baseLayout, setIsLoading }) => {
  const layoutId = useRecoilValue(editorLayoutAtom)
  const setEditorPages = useSetRecoilState(editorPagesAtom)
  const [orderPageSet, setOrderPageSet] = useRecoilState(editorOrderPageSetAtom)
  const [layouts, setLayouts] = useState([])
  const [selectedLayoutId, setSelectedLayoutId] = useState(null)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [layoutIsDefault, setLayoutIsDefault] = useState(null)
  const [layoutName, setLayoutName] = useState(null)
  const [currentLayout, setCurrentLayout] = useState(null)
  const setToast = useSetToast()
  const setTrigger = useSetRecoilState(triggerUseEffect)

  const getSets = async () => {
    const { data } = await pageSets.getCustomPageSets(layoutId)

    let homeLayouts = data
      .filter((item) => item.home_template && !item.default)
      .sort((a, b) => (a.name < b.name ? -1 : 0))

    let adminLayouts = data
      .filter((item) => !item.home_template && !item.default)
      .sort((a, b) => (a.name < b.name ? -1 : 0))

    const adminLayoutDefault = data.filter(
      (item) => item.default && !item.home_template,
    )
    const homeLayoutDefault = data.filter(
      (item) => item.default && item.home_template,
    )

    const finalLayouts = adminLayoutDefault
      .concat(adminLayouts)
      .concat(homeLayoutDefault)
      .concat(homeLayouts)

    setLayouts(finalLayouts)
  }

  const archiveLayout = async () => {
    await pageSets.archive(selectedLayout.id)

    setToast({
      text: 'Layout deleted!',
    })
    getSets()
  }

  const updateLayout = async () => {
    if (layoutIsDefault) {
      const currentDefault = layouts.find(
        (layout) => layout.home_template && layout.default,
      )
      const defaultPayload = {
        default: false,
      }
      if (currentDefault)
        await pageSets.update(currentDefault.id, defaultPayload)
    }
    const payload = {
      default: layoutIsDefault,
      name: layoutName,
    }
    await pageSets.update(selectedLayout.id, payload)

    setToast({ text: 'Layout updated!' })
    getSets()
  }

  const archiveCurrentPages = async () => {
    for (const page of basePages) {
      await orderPages.archiveOrderPage(page.id)
    }
  }

  const getNewPages = async () => {
    const { data } = await pages.getPages(selectedLayout.id)
    const newPages = []
    for (const page of data) {
      const json = page.json_template
      const newPage = _omit(page, ['archived', 'id', 'json_template'])
      newPage.order_page_set_id = basePages?.[0].order_page_set_id
      newPage.json_template = JSON.stringify(json)
      const { data: newPageData } = await orderPages.createOrderPage(newPage)
      newPageData.json_template.product.orderPageId = newPageData.id
      newPages.push(newPageData)
    }
    return newPages
  }

  const applyLayout = async () => {
    setIsLoading(true)
    await archiveCurrentPages()
    //now we have the base page sets, we need to create versions to attach to the order

    const payload = _omit(selectedLayout, ['archived', 'id', 'order_layout_id'])
    payload.page_set_id = selectedLayout.id
    // payload.order_layout_id = baseLayout.id
    //this will also create the 'page' objects and return both data: {page_set: {}, pages: []}
    if (!payload.funeral_home_id) delete payload.funeral_home_id
    const { data: newPageSetData } = await orderPageSets.update(
      orderPageSet.id,
      payload,
    )
    // const newPages = []
    const newPages = await getNewPages()
    const finalPages = newPages.map((page) => {
      return page.json_template
    })
    setEditorPages(
      finalPages.sort((a, b) =>
        a.pageProperties.pageNumber < b.pageProperties.pageNumber ? -1 : 0,
      ),
    )
    setCurrentLayout(newPageSetData)
    setOrderPageSet(newPageSetData)
    setSelectedLayoutId(newPageSetData.id)
    setTrigger(Date.now())
    setIsLoading(false)
  }

  useEffect(() => {
    getSets()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let current = layouts.find((item) => item.id === orderPageSet.page_set_id)
    setCurrentLayout(current)
    current && setSelectedLayoutId(current.paeg_set_id)
    //eslint-disable-next-line
  }, [layouts, orderPageSet])

  useEffect(() => {
    let layout = layouts.find((item) => item.id === selectedLayoutId)
    // layout = !layout
    //   ? adminLayouts.find((item) => item.id === selectedLayoutId)
    //   : layout
    layout && setLayoutIsDefault(layout?.default || false)
    layout?.name && setLayoutName(layout.name)
    layout && setSelectedLayout(layout)
    //eslint-disable-next-line
  }, [selectedLayoutId])

  return (
    <Flex margin="16px 24px" column>
      <Flex margin="16px 0px">
        <Text size="16px">Current Layout: </Text>
        <Text size="16px" weight="600">
          {currentLayout?.name}
        </Text>
      </Flex>
      <Flex column>
        {/* <Text>Default Layouts</Text>
        <Dropdown
          selected={[selectedLayoutId, setSelectedLayoutId]}
          placeholder="Default layouts"
          textKey="name"
          valueKey="id"
          customLayout
          items={adminLayouts}
          margin="16px"
        /> */}

        <Text>Your Saved Layouts</Text>
        <Dropdown
          selected={[selectedLayoutId, setSelectedLayoutId]}
          placeholder="Saved layouts"
          textKey="name"
          customLayout
          valueKey="id"
          items={layouts}
          margin="16px"
        />
      </Flex>

      {selectedLayout && (
        <Flex column background="gray5" padding="16px">
          <Flex justify="space-between" align="center" margin="16px 0">
            <Text>
              Selected:
              <Text size="18" weight="600">
                {selectedLayout.name}
              </Text>
            </Text>
            <Button primary onClick={applyLayout}>
              Apply to Product
            </Button>
          </Flex>

          {selectedLayout?.home_template && (
            <>
              <Flex
                justify="space-between"
                align="center"
                padding="16px 0 8px"
                borderTop="1px solid #B0B6BC"
              >
                <Flex jusitfy="space-between">
                  <Tooltip
                    text="Change name of this saved layout"
                    margin="0 12px 0 0"
                  >
                    <Input
                      label="Layout Name"
                      placeholder="Layout name"
                      value={[layoutName, setLayoutName]}
                      width="200px"
                      disabled={!selectedLayout?.home_template}
                    />
                  </Tooltip>
                  <Tooltip text="Make this layout default" margin="0 12px 0 0">
                    <ToggleSwitch
                      label="Default"
                      value={[layoutIsDefault, setLayoutIsDefault]}
                      margin="0 16px"
                      disabled={!selectedLayout?.home_template}
                    />
                  </Tooltip>
                </Flex>
                <Flex justify="flex-end">
                  <Tooltip text="Delete Layout" margin="0 12px 0 0">
                    <Icon
                      height="16px"
                      width="16px"
                      margin="8px"
                      icon="archive"
                      fill="brand"
                      onClick={() => {
                        archiveLayout()
                      }}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              <Flex justify="right" align="center" margin="4px 8px">
                <Button
                  disabled={!selectedLayout?.home_template}
                  onClick={updateLayout}
                >
                  Save
                </Button>
              </Flex>{' '}
            </>
          )}
        </Flex>
      )}
    </Flex>
  )
}
