import { filterUnwantedKeys, handleKeys } from '.'

export default (editor, caseDetails, dateFormat) => {
  if (!caseDetails) return

  let templates = []

  Object.keys(caseDetails)
    .filter(filterUnwantedKeys)
    .filter(
      (key) =>
        (caseDetails[key] !== null && caseDetails[key]?.length > 0) ||
        key.includes('date'),
    )
    .forEach((key) =>
      handleKeys(editor, caseDetails, key, templates, dateFormat),
    )

  return templates
}
