import React, { useRef } from 'react'
import { Text, Icon } from 'components'
import * as S from './InsertItems.styled'
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil'
import { itemAtomFamily, itemIdAtom, caseAtom, objectSelector } from 'atoms'
import { v4 as uuidv4 } from 'uuid'
import { getImageDimensions } from 'utils'
import { cases } from 'api'

export default ({
  setShow,
  name,
  src,
  index,
  srcPrefix,
  type,
  canDelete,
  isVerse,
  ...props
}) => {
  const itemIds = useRecoilValue(itemIdAtom)
  const _case = useRecoilValue(caseAtom)
  const { id: caseId } = _case || {}
  const setPersonalImages = useSetRecoilState(
    objectSelector({ atom: caseAtom, property: 'personal_images' }),
  )

  const addToItems = useRecoilCallback(({ set }) => (item) => {
    set(itemAtomFamily(item.id), item)
    set(itemIdAtom, [...itemIds, item.id])
  })

  const deleteRef = useRef(null)

  const addImageObj = async (e) => {
    if (deleteRef?.current?.contains(e.target)) {
      deleteCaseImage()
      return
    } else {
      const uuid = uuidv4()
      const d = await getImageDimensions(`${srcPrefix || ''}${src}`)
      const height = 150
      const width = height * (d.width / d.height)
      const imageObj = {
        id: uuid,
        type: 'image',
        x: 0,
        y: 0,
        z: 1,
        height: height,
        width: width,
        properties: {
          subtype: type || 'assetImage',
          caseImage: type === 'caseImage' ? index : '',
        },
        image: {
          url: `${srcPrefix || ''}${src}`,
          edits: {},
        },
      }
      addToItems(imageObj)
      setShow(false)
    }
  }

  const deleteCaseImage = async () => {
    const { data } = await cases.deleteCaseImage(caseId, {
      filename: src,
    })

    if (data) {
      setPersonalImages(data)
    }
  }

  return (
    <S.ImageItem
      align="center"
      justify="space-between"
      column
      clickable
      width="194px"
      height="216px"
      margin="8px"
      radius="4px"
      padding="16px"
      border="1px solid"
      borderColor="gray4"
      background={isVerse ? 'lockedWhite' : 'white'}
      transiton=".25s"
      position="relative"
      onClick={addImageObj}
      {...props}
    >
      {canDelete && (
        <S.IconWrapper ref={deleteRef} onClick={deleteCaseImage}>
          <Icon clickable icon="exitModal" fill="gray1" hoverfill="gray2" />
        </S.IconWrapper>
      )}
      <S.Image full flex="1" src={src} srcPrefix={srcPrefix} />
      <Text weight="500" margin="12px 0 8px 0">
        {name}
      </Text>
    </S.ImageItem>
  )
}
