import { caseDetailSpan } from '.'
import { replaceInitialValuesAndDates } from 'utils'
import startCase from 'lodash/startCase'

export default (editor, caseDetails, key, templates, dateFormat) => {
  const content = replaceInitialValuesAndDates(
    caseDetailSpan(key),
    caseDetails,
    dateFormat,
  )
  templates.push({
    type: 'menuitem',
    text: `${startCase(key)}`,
    onAction: () => editor.execCommand('mceInsertTemplate', false, content),
  })
}
