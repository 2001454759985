import React, { useState, useEffect } from 'react'
import { Navigation, CustomThemeEditor } from 'components'
import { customThemes as themeAPI } from 'api'
import { useLocation } from 'react-router-dom'

export default () => {
  const { pathname: location } = useLocation()
  const [theme, setTheme] = useState('')

  const getTheme = async () => {
    const [, , themeId] = location.split('/')
    const { data } = await themeAPI.getCustomThemesById(themeId)
    setTheme(data)
  }

  useEffect(() => {
    getTheme()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Navigation />
      <CustomThemeEditor theme={theme} />
    </>
  )
}
