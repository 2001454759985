import React, { useState } from 'react'
import { Flex, Tabs, Text } from 'components'
import { WelcomeContent, GettingStartedContent, DoNoShowAgain } from '.'

export default ({ show: [show, setShow] }) => {
  const [activeTab, setActiveTab] = useState('Welcome')
  return (
    <Flex padding="32px 40px 32px 0" column>
      <Text size="32px" weight="600" margin="0 0 40px 40px">
        {activeTab}
      </Text>
      <Tabs
        tabs={[
          { title: 'Welcome', content: <WelcomeContent /> },
          { title: 'Getting started', content: <GettingStartedContent /> },
        ]}
        selected={[activeTab, setActiveTab]}
        width="278px"
      />
      <Flex justify="flex-end" margin="32px 0 0 0">
        <DoNoShowAgain {...{ show, setShow }} />
      </Flex>
    </Flex>
  )
}
