import React from 'react'
import { Flex, Text } from 'components'
import * as S from './ToggleSwitch.styled'

export default ({
  label,
  labelRight,
  labelLeft,
  value: [value, setValue] = [false, null],
  color,
  spaceBetween,
  ...props
}) => (
  <S.ToggleSwitch
    clickable
    {...props}
    column={!labelRight && !labelLeft}
    justify={spaceBetween ? 'space-between' : undefined}
  >
    {label && (
      <Text
        align={!spaceBetween ? 'center' : undefined}
        color={color}
        margin={
          labelRight ? '0 0 0 8px' : labelLeft ? '0 8px 0 0' : '0 0 8px 0'
        }
      >
        {label}
      </Text>
    )}

    <Flex
      width="44px"
      minWidth="44px"
      height="28px"
      radius="44px"
      align="center"
      justify="flex-start"
      position="relative"
      background={value ? 'secondary' : 'gray3'}
      transition="0.25s ease"
      switchOn={value}
      labelRight={labelRight}
      labelLeft={labelLeft}
      onClick={() => setValue(!value)}
    >
      <Flex
        switchOn={value}
        position="absolute"
        left={value ? '18px' : '2px'}
        background="white"
        width="24px"
        height="24px"
        radius="50%"
        transition="0.25s ease"
      />
    </Flex>
  </S.ToggleSwitch>
)
