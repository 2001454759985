import React, { useState } from 'react'
import { defaultFHLogo } from 'assets'
import { useLocation } from 'react-router-dom'
import {
  Flex,
  HiddenMenu,
  Link,
  Image,
  Menu,
  NavItem,
  UserDropDown,
  BetaWatermark,
} from 'components'
import { funeralHomeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default () => {
  const [supportOpen, setSupportOpen] = useState(false)
  const home = useRecoilValue(funeralHomeAtom)
  const { pathname: location } = useLocation()

  return (
    <>
      <BetaWatermark />
      <Flex center>
        <Link href="/dashboard">
          <Image
            clickable
            height="39px"
            radius="2px"
            src={
              home.logo
                ? `${process.env.REACT_APP_S3BUCKET_FH_LOGOS}${home.logo}`
                : defaultFHLogo
            }
          />
        </Link>
      </Flex>
      <Flex>
        <NavItem
          text="Print Packages"
          link="/dashboard"
          selected={location === '/dashboard'}
        />
        <NavItem text="Cases" link="/cases" selected={location === '/cases'} />
        <NavItem
          text="Custom Themes"
          link="/customThemes"
          selected={location.includes('/customThemes')}
        />

        <HiddenMenu
          show={[supportOpen, setSupportOpen]}
          parentElement={() => (
            <NavItem
              text="Support"
              selected={supportOpen}
              onClick={() => setSupportOpen(!supportOpen)}
            />
          )}
          hiddenElement={() => (
            <Menu
              show={[supportOpen, setSupportOpen]}
              margin="0 0 0 24px"
              items={[
                {
                  text: 'Contact',
                  link: '/support/contact',
                },
                {
                  text: 'Videos',
                  link: '/support/videos',
                },
                {
                  text: 'Instructions',
                  link: '/support/instructions',
                },
                {
                  text: 'Webinars',
                  link: '/support/webinars',
                },
                {
                  text: 'Welcome',
                  link: '/support/welcome',
                  onClick: () => localStorage.removeItem('showWelcomeModal'),
                },
              ]}
            />
          )}
        />
      </Flex>
      <UserDropDown />
    </>
  )
}
