import { atom, selectorFamily } from 'recoil'
import { default as _set } from 'lodash/set'
import { default as _get } from 'lodash/get'
import { produce } from 'immer'

export const userAtom = atom({
  key: 'user',
  default: {},
})

export const funeralHomeAtom = atom({
  key: 'funeralHome',
  default: {},
})

export const themeAtom = atom({
  key: 'theme',
  default: 'automatic',
})

export const hasNewCaseAtom = atom({
  key: 'hasNewCase',
  default: false,
})

export const objectSelector = selectorFamily({
  key: 'objectSelector',
  get:
    ({ property, atom }) =>
    ({ get }) => {
      const obj = get(atom)
      if (!obj.id) return null
      // const value = _get(obj, property);
      //if we request the 'theme_id' the value is all that will be returned, in this case, a uuid
      return obj
    },
  set:
    ({ property, atom }) =>
    ({ set, get }, newValue) => {
      const obj = get(atom)
      if (!obj) return null
      //here we can target an update only the property being edited
      const newField = produce(obj, (draft) => {
        _set(draft, property, newValue)
      })
      //then we set the atom with the new value
      set(atom, newField)
    },
})

export const objectKeySelector = selectorFamily({
  key: 'objectKeySelector',
  get:
    ({ property, atom }) =>
    ({ get }) => {
      const obj = get(atom)
      if (!obj) return null
      const value = _get(obj, property)
      //if we request the 'theme_id' the value is all that will be returned, in this case, a uuid
      return value
    },
  set:
    ({ property, atom }) =>
    ({ set, get }, newValue) => {
      const obj = get(atom)
      if (!obj) return null
      //here we can target an update only the property being edited
      const newField = produce(obj, (draft) => {
        _set(draft, property, newValue)
      })
      //then we set the atom with the new value
      set(atom, newField)
    },
})
