import styled, { css } from 'styled-components'
import { flex, sizing, spacing, positioning } from 'styles'

const parseBackgroundPosition = (position) => {
  if (position) {
    if (isNaN(position) && position.includes('%')) {
      return position
    } else {
      return `${parseInt(position)}px`
    }
  } else {
    return '0px'
  }
}

export const Page = styled.div(
  ({
    zoom = 100,
    pageExceedsCanvas,
    padding,
    whiteBorder,
    theme,
    pageLayout,
    themeLayout,
    preprinted,
    backgroundTabActive,
    isPMRegisterBook,
    isEven,
    pageTwo,
    backgroundImageUrl,
  }) => css`
    ${flex}
    ${sizing}
    ${spacing}
    ${positioning}
    left: 0%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    transform: scale(${zoom / 100});
    transform-origin: ${pageExceedsCanvas ? 'left top' : 'top center'};
    transition: 0.15s;
    background-image: ${pageLayout?.hide_background ||
    backgroundTabActive ||
    preprinted
      ? ''
      : `url(${backgroundImageUrl})`};
    background-size: ${pageLayout?.background_fill
      ? pageLayout.background_fill
      : 'cover'};
    background-position: ${parseBackgroundPosition(pageLayout?.background_left)}
      ${parseBackgroundPosition(pageLayout?.background_top)};
    background-repeat: no-repeat;
    border: ${whiteBorder && `${whiteBorder}px solid white`};
    padding-top: ${padding?.top && `${padding.top}px`};
    padding-right: ${padding?.right && `${padding.right}px`};
    padding-bottom: ${padding?.bottom && `${padding.bottom}px`};
    padding-left: ${padding?.left && `${padding.left}px`};
    position: relative;
    background-color: white;
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: -1;
      background: white;
      opacity: ${pageLayout?.background_opacity || (pageTwo ? 0.7 : 0)};
    }
  `,
)

export const PerforationGuide = styled.div(
  ({ height, width, padding, whiteBorder, hideOverflow }) => css`
    height: ${whiteBorder
      ? height - whiteBorder * 2
      : height - padding?.top - padding?.bottom}px;
    width: ${whiteBorder
      ? width - whiteBorder * 2
      : width - padding?.left - padding?.right}px;
    outline: 1px dashed red;
    overflow: ${hideOverflow ? 'visible' : 'hidden'};
    position: relative;
  `,
)

export const RBDiv = styled.div(
  ({ product, isEven }) => css`
    position: absolute;
    z-index: -1;
    height: ${product?.productSize?.height}px;
    width: ${!isEven ? product?.padding.left : product?.padding?.right}px;
    background-color: #fff;
    ${isEven && `right: 0px;`}
    ${!isEven && `left: 0px;`}
    top: 0px;
  `,
)
