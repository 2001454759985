import React, { useState } from 'react'
import { Flex, Text, AnimatedHeight } from 'components'
import UploadContent from './UploadContent'
import MemorialContent from './MemorialContent'

export default () => {
  const defaultTabs = [
    {
      label: 'Upload images',
      active: true,
      content: <UploadContent />,
    },
    {
      label: 'Add memorials',
      active: false,
      content: <MemorialContent />,
    },
  ]

  const [tabs, setTabs] = useState(defaultTabs)

  const activeTab = tabs.findIndex((tab) => tab.active)

  const handleTab = (active, i) => {
    let tabsCopy = [...tabs]
    setTabs(
      tabsCopy.map((tab, index) => ({
        ...tab,
        active: i === index ? !active : active,
      })),
    )
  }

  return (
    <>
      <Flex width="100%" center borderBottom="1px solid" borderColor="gray4">
        <Flex width="fit-content" height="44px" position="relative">
          {tabs.map(({ label, active }, i) => (
            <Text
              key={label}
              padding="0 8px"
              size="16px"
              weight="600"
              color={!active ? 'gray2' : undefined}
              transition="color 0.3s"
              onClick={() => handleTab(active, i)}
            >
              {label}
            </Text>
          ))}
          <Flex
            width="131.5px"
            height="3px"
            background="brand"
            position="absolute"
            bottom="-2px"
            left={activeTab === 0 ? '0' : '50%'}
            transition="left 0.3s"
          />
        </Flex>
      </Flex>
      {tabs.map(({ active, content }, i) => (
        <AnimatedHeight
          key={i}
          height={active ? 'auto' : 0}
          duration={250}
          aria-hidden={true}
        >
          {active && content}
        </AnimatedHeight>
      ))}
    </>
  )
}
