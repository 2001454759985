import React from 'react'
import { Flex, Icon } from 'components'
import { useDropzone } from 'react-dropzone'
import * as S from './FileDropzone.styled'

export default ({
  onDrop = () => {},
  text = 'Drag and drop files here',
  allowMultiple = false,
  fileTypes = [],
  disabled = false,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <S.FileDropzone
      padding="80px"
      radius="2px"
      center
      {...getRootProps({ refKey: 'innerRef' })}
      disabled={disabled}
      isDragActive={isDragActive}
      {...props}
    >
      <S.HiddenInput
        {...getInputProps()}
        multiple={allowMultiple}
        accept={fileTypes?.join(', ')}
      />
      <Flex column center>
        <Flex
          center
          margin="0 0 20px"
          background="gray5"
          height="100px"
          width="100px"
          radius="100px"
        >
          <Icon width="72px" height="72px" icon="upload" color="secondary" />
        </Flex>
        <S.UploadText weight="500">Drop images here</S.UploadText>
        <S.UploadText weight="500">
          or <S.UploadSpan>choose files</S.UploadSpan>
        </S.UploadText>
      </Flex>
    </S.FileDropzone>
  )
}
