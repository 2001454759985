import { editorPagesAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { default as isEmpty } from 'lodash/isEmpty'

export default () => {
  const [editorPages, setEditorPages] = useRecoilState(editorPagesAtom)

  return (selectedPages, layoutUpdates) => {
    const updatedPages = editorPages.map((originalPage, i) => {
      // determine if the page is even or odd
      const currentPage = i + 1
      const pagePosition = currentPage % 2 === 0 ? 'even' : 'odd'

      // determine if the page will be affected by the changes or not
      const allPagesSelected = selectedPages === 'all'
      const isSelectedPage = allPagesSelected || selectedPages === pagePosition

      // only apply changes to selected pages
      if (isSelectedPage) {
        // make a deep copy of the page
        const page = JSON.parse(JSON.stringify(originalPage))
        let themeLayout = page?.product?.themeLayout

        // create the object for the updated pages
        const updatedPage = {
          ...themeLayout,
          ...layoutUpdates,
        }

        isEmpty(updatedPage?.edited_background || {}) &&
          (updatedPage.edited_background = {})

        // update the replacement_image
        page.product.themeLayout = updatedPage

        // return the modified page
        return page
      } else {
        // return the original page
        return originalPage
      }
    })

    // update the pages atom
    setEditorPages(updatedPages)
  }
}
