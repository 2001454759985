import React from 'react'
import { Flex, Text, Button, Slider } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'
import { useSetToast } from 'hooks'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const setToast = useSetToast()

  const [opacity, setOpacity] = useRecoilState(
    itemSelector({
      atomFamily: 'watermark',
      id: selectedItem,
      path: 'opacity',
    }),
  )

  const setComingSoon = () => setToast({ text: 'Coming Soon!' })

  return (
    <Flex column padding="0 12px" margin="24px 0">
      <Button margin="0 0 16px" onClick={() => setComingSoon()}>
        Replace
      </Button>
      <Button margin="0 0 16px" onClick={() => setComingSoon()}>
        Delete
      </Button>
      {selectedItem && (
        <Flex full column align="center" margin="0 0 20px">
          <Text margin="0 24px 0 0">Opacity</Text>

          <Slider
            sliderState={[opacity, setOpacity]}
            min="0"
            max="100"
            initialValue={100}
          />
        </Flex>
      )}
    </Flex>
  )
}
