import { api } from 'utils'
import { axios } from 'api'

const request = (method) => async (endpoint, body) => {
  // Send the request
  const headers = { 'Content-Type': 'application/json' }
  // if (token)
  headers['Authorization'] = axios.defaults.headers.common['Authorization']
  if (body && typeof body === 'object') body = JSON.stringify(body)
  const res = await fetch(`${api}` + endpoint, { method, body, headers })

  // Parse the response
  try {
    if (res.headers.get('content-type').includes('pdf')) return res.blob()
    if (!res.headers.get('content-type').includes('json')) return res.text()
    const data = await res.json()
    if (String(res.status)[0] !== '2') {
      throw data.message || 'Error'
    }
    return data
  } catch (e) {
    throw e
  }
}

export default {
  del: request('DELETE'),
  get: request('GET'),
  post: request('POST'),
  patch: request('PATCH'),
}
