import React, { useEffect, useState } from 'react'
import { FullscreenModal, WelcomeModal } from 'components'
import { useLocalStorage } from 'hooks'
import { users } from 'api'
import { useAuth0 } from '@auth0/auth0-react'

export default () => {
  const { logout } = useAuth0()
  const [showWelcomeModal, setShowWelcomeModal] = useState(true)
  const [localStorageShow, setLocalStorageShow] = useLocalStorage(
    'showWelcomeModal',
    localStorage.getItem('showWelcomeModal') ?? true,
  )
  const [, setTrialExpired] = useLocalStorage(
    'trialExpired',
    localStorage.getItem('trialExpired') ?? false,
  )

  useEffect(() => {
    setShowWelcomeModal(localStorageShow)
  }, [localStorageShow, logout])

  useEffect(() => {
    const checkTrial = async () => {
      try {
        const { data } = await users.user()
        // Check if trial days is the string error message
        if (typeof data?.trialDays === 'string') {
          setTrialExpired(data.trialDays)
          return logout({ returnTo: window.location.origin })
        }

        if (typeof data?.trialDays === 'number' || !data?.trialDays)
          return setTrialExpired(false)
      } catch (e) {
        console.log({ e })
      }
    }

    checkTrial()
  }, [logout, setTrialExpired])

  return (
    <>
      <FullscreenModal show={[showWelcomeModal, setShowWelcomeModal]}>
        <WelcomeModal
          show={[JSON.parse(localStorageShow), setLocalStorageShow]}
        />
      </FullscreenModal>
    </>
  )
}
