import { axios } from 'api'

/// SETTINGS ///

export const createFuneralHomeSettings = async (payload) =>
  axios.post(`createFuneralHomeSettings`, payload)
// expected payload:
//  {
//   print_parameter_top: <Number> (nullable, OPTIONAL),
//   print_parameter_left: <Number> (nullable, OPTIONAL),
//   print_parameter_right: <Number> (nullable, OPTIONAL),
//   print_parameter_bottom: <Number> (nullable, OPTIONAL),
//   default_font: <String> (nullable, OPTIONAL),
//   case_title_name_of_deceased: <String> (nullable, OPTIONAL),
//   case_title_date_of_birth: <String> (nullable, OPTIONAL),
//   case_title_date_of_death: <String> (nullable, OPTIONAL),
//   case_title_clergy_name: <String> (nullable, OPTIONAL),
//   case_title_service_date: <String> (nullable, OPTIONAL),
//   case_title_interment_place: <String> (nullable, OPTIONAL),
//   date_format: <String> (nullable, OPTIONAL),
//  }

export const getFuneralHomeSettings = async () =>
  axios.get(`funeralHomeSettings`)

export const updateFuneralHomeSettings = async (funeral_home_id, payload) =>
  axios.patch(`funeralHomeSettings/${funeral_home_id}`, payload)
// expected payload:
//  {
//   print_parameter_top: <Number> (nullable, OPTIONAL),
//   print_parameter_left: <Number> (nullable, OPTIONAL),
//   print_parameter_right: <Number> (nullable, OPTIONAL),
//   print_parameter_bottom: <Number> (nullable, OPTIONAL),
//   default_font: <String> (nullable, OPTIONAL),
//   case_title_name_of_deceased: <String> (nullable, OPTIONAL),
//   case_title_date_of_birth: <String> (nullable, OPTIONAL),
//   case_title_date_of_death: <String> (nullable, OPTIONAL),
//   case_title_clergy_name: <String> (nullable, OPTIONAL),
//   case_title_service_date: <String> (nullable, OPTIONAL),
//   case_title_interment_place: <String> (nullable, OPTIONAL),
//   date_format: <String> (nullable, OPTIONAL),
//  }

/// LOGOS ///

// TODO: front end stores this in the service, a - why, b - do we still need to
// let logos = null;

export const createFuneralHomeLogo = async (size, file) =>
  axios.post(`createFuneralHomeLogo?size={size}`, file, {
    headers: { 'Content-Type': undefined },
  })

export const updateFuneralHomeLogo = async (id, size, file) =>
  axios.patch(`updateLogos/${id}?size=${size}`, file, {
    headers: { 'Content-Type': undefined },
  })

export const getFuneralHomeLogo = async () =>
  axios.get(`funeralHomeLogos`, {
    headers: { 'Content-Type': undefined },
  })

/// DEFAULT LAYOUTS ///
// This is where the user can set the white border or full bleed etc to always be the selected default

export const getFuneralHomeDefaultLayouts = async () =>
  axios.get(`funeralHomeDefaultLayouts`)

export const createFuneralHomeDefaultLayout = async (payload) =>
  axios.post(`createFuneralHomeDefaultLayout`, payload)
// expected payload:
// {
//   layout_id: <UUID> (REQUIRED),
// }

export const deleteFuneralHomeDefaultLayout = async (default_layout_id) =>
  axios.delete(`funeralHomeDefaultLayouts/${default_layout_id}`)
