import { useState } from 'react'
import { cases, caseMemorials } from 'api'
import { defaultPlaceholder, avatarPlaceholder } from 'assets'
import { useSetToast } from 'hooks'

export default (location = '') => {
  const [, , token, caseId] = location?.split('/')
  const [_case, setCase] = useState({})
  const [memorials, setMemorials] = useState([])
  const [memorial, setMemorial] = useState('')
  const [memorialError, setMemorialError] = useState('')
  const [submitter, setSubmitter] = useState('')
  const [submitterError, setSubmitterError] = useState('')
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const getCase = async () => {
    const { data: caseData } = await cases.getCaseAsGuest(token)
    if (caseData) setCase(caseData)
  }

  const getMemorials = async () => {
    const { data: memorials } = await caseMemorials.getCaseMemorialsAsGuest(
      token,
    )
    if (memorials) setMemorials(memorials)
  }

  const createCaseMemorialAsGuest = async () => {
    setMemorialError(memorial ? '' : 'Memorial is required')
    setSubmitterError(submitter ? '' : 'Shared by is required')
    if (!memorial || !submitter) return
    setMemorialError('')
    setSubmitterError('')

    const payload = {
      case_id: caseId,
      memorial,
      submitter_name: submitter,
    }

    const { data } = await caseMemorials.createCaseMemorialAsGuest(
      token,
      payload,
    )

    data
      ? setToast({ text: 'Memorial shared!' })
      : setToast({ text: 'Memorial upload failed.' })

    return data
  }

  const uploadCaseImagesAsGuest = async (filesArray) => {
    Promise.all(filesArray.map((file) => uploadCaseImageAsGuest(file)))
  }

  const uploadCaseImageAsGuest = async (file) => {
    setLoading(true)
    let fd = new FormData()
    fd.append('file', file, 'blob')

    const { data } = await cases.uploadCaseImageAsGuest(token, fd)

    if (data) {
      getCase()
      setLoading(false)
      setToast({ text: 'Image Uploaded!' })
    } else {
      setToast({ text: 'Image upload failed.' })
    }
  }

  return {
    token,
    _case,
    getCase,
    memorials,
    memorial,
    setMemorial,
    memorialError,
    getMemorials,
    submitter,
    setSubmitter,
    submitterError,
    createCaseMemorialAsGuest,
    uploadCaseImagesAsGuest,
    loading,
    defaultPlaceholder,
    avatarPlaceholder,
  }
}
