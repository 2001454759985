import React from 'react'
import { useTinyMCESettings } from '.'
import { Editor } from '@tinymce/tinymce-react'

export default ({
  value = '',
  init: additionalInit,
  inline,
  setRef,
  caseDetails,
  ...props
}) => {
  const init = useTinyMCESettings(additionalInit, inline, caseDetails)
  return (
    <Editor
      onInit={(evt, editor) => setRef?.(editor)}
      apiKey={process.env.REACT_APP_TINYMCE}
      initialValue={value}
      init={init}
      {...props}
    />
  )
}
