import React, { useState } from 'react'
import { Flex, InsertCategories, SlideoutMenu } from 'components'
import { useRecoilState } from 'recoil'
import { insertMenuOpenAtom } from 'atoms'

export default ({ basePages, baseLayout, setIsLoading }) => {
  const [showSlideout, setShowSlideout] = useRecoilState(insertMenuOpenAtom)
  const [selectedMenu, setSelectedMenu] = useState('')

  const setMenu = (menu) => {
    setShowSlideout(selectedMenu === menu ? !showSlideout : true)
    setSelectedMenu(menu)
  }

  return (
    <Flex
      height="100%"
      width="250px"
      background="white"
      position="relative"
      zindex="1"
      borderRight="1px solid"
      borderColor="gray4"
      column
    >
      <InsertCategories
        setMenu={setMenu}
        showSlideout={showSlideout}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        setShowSlideout={setShowSlideout}
      />
      <SlideoutMenu
        isSearchMenu={selectedMenu === 'SearchBar'}
        setIsLoading={setIsLoading}
        title={selectedMenu}
        show={[showSlideout, setShowSlideout]}
        menu={selectedMenu}
        basePages={basePages}
        baseLayout={baseLayout}
      />
    </Flex>
  )
}
