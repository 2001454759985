import { axios } from 'api'
import { sanitizeDashboardDates, sanitizeSearchFHOrders } from 'utils'
import { default as fetch } from './fetch.config.js'

export const getFuneralHomeOrders = async (archived) => {
  const response = await axios.get(`/funeralHomeOrders?archived=${archived}`)
  response.data.orders = sanitizeDashboardDates(response.data.orders)
  return response
}

export const searchFuneralHomeOrders = async (payload) => {
  const response = await axios.post(`searchFuneralHomeOrders`, payload)
  response.data.orders = sanitizeSearchFHOrders(response.data.orders)
  return response
  // expected payload (ALL OPTIONAL):
  // {
  //   name_of_deceased: <String>,
  //   case_id: <String>,
  //   editable_case_id: <String>,
  //   date_of_death_start: <Date>,
  //   date_of_death_end: <Date>,
  //   archived: <Boolean>,
  // }
}

export const createOrder = async (payload) => axios.post(`createOrder`, payload)
// expected payload:
// {
//   case_id: <String> (REQUIRED),
//   collection_id: <String>,
//   theme_id: <String>,
// }

export const updateOrder = async (order_id, payload) =>
  axios.patch(`orders/${order_id}`, payload)
// expected payload:
// {
//   case_id: <String>,
//   collection_id: <String> (allow null),
//   theme_id: <String> (allow null),
//   reset_edited_layouts: <Boolean>,
//   selected_for_service: <Boolean> (OPTIONAL),
// }

export const archiveOrder = async (order_id) =>
  axios.patch(`archiveOrder/${order_id}`)

export const unarchiveOrder = async (order_id) =>
  axios.patch(`unarchiveOrder/${order_id}`)

export const getOrder = async (order_id) => axios.get(`orders/${order_id}`)

//TODO: Expect this endpoint to change with rebuild
export const generatePDF = async (payload) => fetch.post(`generatePDF`, payload)
// expected payload (ALL REQUIRED):
// {
//   url: <String>,
//   html: <String>,
//   page_height: <Number>,
//   page_width: <Number>,
// }

//TODO: Expect this endpoint to change with rebuild
export const generateJPEG = async (payload, _) =>
  axios.post(`generateJPEG`, payload)
// expected payload:
// {
//   html: <String> (REQUIRED),
// }

//TODO: Expect this endpoint to change with rebuild
export const duplicateOrder = async (payload) =>
  axios.post(`duplicateOrder`, payload)
// expected payload (ALL REQUIRED):
// {
//   case_id: <UUID>,
//   order_id: <UUID>,
// }
