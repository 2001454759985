import { axios } from 'api'

export const getOrderLayouts = async (order_product_details_id) =>
  axios.get(
    `orderLayoutDetails?order_product_details_id=${order_product_details_id}`,
  )

export const createOrderLayout = async (payload) =>
  axios.post(`createOrderLayoutDetails`, payload)
// expected payload:
// {
//   name: <String> (REQUIRED),
//   product_id: <UUID> (REQUIRED),
//   layout_id: <UUID> (REQUIRED),
//   order_product_details_id: <UUID>,
//   order_layout_id: <UUID>,
//   default: <Boolean> (REQUIRED),
//   paper_width: <Number> (REQUIRED),
//   paper_height: <Number> (REQUIRED),
//   theme_width: <Number> (REQUIRED),
//   theme_height: <Number> (REQUIRED),
// }

export const updateOrderLayout = async (order_layout_id, payload) =>
  axios.patch(`updateOrderLayoutDetails/${order_layout_id}`, payload)
// expected payload (ALL OPTIONAL):
// {
//   name: <String>,
//   saved: <Boolean>,
//   background_watermark_edited: <Boolean>,
//   default: <Boolean>,
//   paper_width: <Number>,
//   paper_height: <Number>,
//   theme_width: <Number>,
//   theme_height: <Number>,
// }
