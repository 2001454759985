import React, { useState, useRef, useEffect } from 'react'
import {
  itemSelector,
  caseSelector,
  dateFormatAtom,
  rightMenuAtom,
} from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { TinyMCE } from 'components'
import { replaceCaseDetails } from 'utils'
import * as S from './PageText.styled'

export default ({
  text: rawText,
  selected,
  dragDisabled: [dragDisabled, setDragDisabled] = [null, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  setItemHeight,
  itemRef,
  id,
  ...props
}) => {
  const [text, setText] = useState('')
  const [editing, setEditing] = useState(false)
  const TinyMCERef = useRef(null)
  const _case = useRecoilValue(caseSelector)
  const dateFormat = useRecoilValue(dateFormatAtom)
  const setThisItemTextValue = useSetRecoilState(
    itemSelector({ id: id, path: 'text.value' }),
  )
  const activeMenu = useRecoilValue(rightMenuAtom)

  useEffect(() => {
    setText(replaceCaseDetails(rawText.value, _case, dateFormat))
    //eslint-disable-next-line
  }, [_case])

  useEffect(() => {
    setDragDisabled(editing)
    //eslint-disable-next-line
  }, [editing])

  const updateHeight = () => {
    const updatedHeight = TinyMCERef?.current?.bodyElement?.offsetHeight
    setItemHeight?.(updatedHeight)
    setInternalHeight?.(updatedHeight)
  }

  useEffect(() => {
    TinyMCERef?.current && updateHeight()
    // eslint-disable-next-line
  }, [TinyMCERef?.current])

  useEffect(() => {
    !selected && setEditing(false)
  }, [selected])

  const handleBlur = () => {
    if (TinyMCERef.current) {
      const content = TinyMCERef.current.getContent()
      setThisItemTextValue(content)
      updateHeight()
    }
  }

  const handleDoubleClick = () => {
    !editing && TinyMCERef?.current?.editorCommands?.commands?.exec?.selectall()
    setEditing(true)
  }

  return (
    <S.Text
      ref={itemRef}
      selected={selected}
      editing={editing}
      onDoubleClick={() => handleDoubleClick()}
      onBlur={handleBlur}
      {...props}
    >
      <S.TextContainer
        editing={editing}
        cantHighlight={activeMenu !== 'Elements'}
      >
        {text && (
          <TinyMCE
            setRef={(ref) => (TinyMCERef.current = ref)}
            value={text}
            // TODO: the case doesn't render the details drop down when I pass the converted case?
            caseDetails={_case}
            onKeyUp={updateHeight}
            onExecCommand={updateHeight}
          />
        )}
      </S.TextContainer>
    </S.Text>
  )
}
