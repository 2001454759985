import React, { useState, useMemo, useEffect } from 'react'
import { users } from 'api'
import { staffMockTable } from 'mocks'
import { Button, Flex, Input, ModalButton, Text, Table } from 'components'
import { StaffTable } from './'
import { useSetToast } from 'hooks'

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [staffName, setStaffName] = useState('')
  const [email, setEmail] = useState('')
  const setToast = useSetToast()

  const [tableContent, setTableContent] = useState(staffMockTable)
  const [itemsLoaded, setItemsLoaded] = useState(false)

  const getItems = async () => {
    // retreives the items to populate the table
    const { data } = await users.getStaff()
    tableContent && setTableContent(data)
    tableContent && setItemsLoaded(true)
  }

  const archiveUser = async (user_id) => {
    let resp
    try {
      resp = await users.archiveUser(user_id)
      if (resp.status === 200) {
        setToast({
          text: `User successfully deactivated!`,
          icon: 'check',
        })
      }
    } catch (err) {
      setToast({
        text: err?.response?.data?.message || err.message,
        icon: 'deleteOutline',
      })
    }
    getItems(false)
  }

  const resendEmail = async (user_id) => {
    let resp
    try {
      resp = await users.resendActivationEmail(user_id)
      if (resp.status === 200) {
        setToast({
          text: `Invitation email resent!`,
          icon: 'check',
        })
      }
    } catch (err) {
      setToast({
        text: err?.response?.data?.message || err.message,
        icon: 'deleteOutline',
      })
    }
    getItems(false)
  }

  const columns = useMemo(
    () => StaffTable(itemsLoaded, archiveUser, resendEmail),
    // eslint-disable-next-line
    [itemsLoaded],
  )

  useEffect(() => {
    getItems(false)
    // eslint-disable-next-line
  }, [])

  const closeModal = async () => {
    setStaffName('')
    setEmail('')
    setShowModal(false)
  }

  const createStaff = async () => {
    // I have no idea why the front end does this, also the email just sends them a reset password link so WTF 🤔 but we can handle for this later
    //TODO: update backend to manage this password garbage
    //TODO: shit this isn't the way to create a user anymore 🤔 talk this through w/Robert
    const password = Math.random().toString(36).substring(7)
    let resp
    try {
      resp = await users.createStaff({
        email: email,
        name: staffName,
        password: password,
      })
      if (resp?.status === 201) {
        setToast({
          text: `New staff member created! An invitation email has been sent.`,
          icon: 'check',
        })
      }
    } catch (err) {
      setToast({
        text: err?.response?.data?.message || err.message,
        icon: 'deleteOutline',
      })
    }
    getItems(false)
    setStaffName('')
    setEmail('')
    setShowModal(false)
  }

  return (
    <Flex column full padding="0 42px 0 0">
      <Flex justify="space-between" align="flex-start" margin="0 0 16px 0">
        <Text size="24px" weight="600">
          Staff members
        </Text>
        <ModalButton
          left
          primary
          icon="plus"
          text="New staff member"
          show={[showModal, setShowModal]}
        >
          <Flex column full padding="32px">
            <Text size="24px" weight="600" margin="0 0 24px 0">
              New staff member
            </Text>
            <Input
              full
              margin="0 0 16px 0"
              label="Staff member name"
              placeholder="Full name"
              value={[staffName, setStaffName]}
            />
            <Input
              full
              margin="0 0 24px 0"
              label="Email"
              value={[email, setEmail]}
            />
            <Flex justify="flex-end">
              <Button margin="0 16px 0 0" onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button primary onClick={() => createStaff()}>
                Send invitation
              </Button>
            </Flex>
          </Flex>
        </ModalButton>
      </Flex>

      <Table
        columns={columns}
        data={tableContent}
        searchPlaceholder="Search by name or email"
      />
    </Flex>
  )
}
