import React, { useState, useEffect } from 'react'
import { Flex, Text } from 'components'
import { useSetToast } from 'hooks'
import { funeralHomes } from 'api'
import Compressor from 'compressorjs'
import { default as LogoCard } from './LogoCard'
import { funeralHomeAtom, objectSelector } from 'atoms'
import { useSetRecoilState } from 'recoil'

export default () => {
  const setHomeLogo = useSetRecoilState(
    objectSelector({
      property: 'logo',
      atom: funeralHomeAtom,
    }),
  )
  const [logo, setLogo] = useState({})
  const setToast = useSetToast()
  const dimensions = {
    small: 250,
    medium: 500,
    large: 1000,
  }

  const getLogos = async () => {
    const { data } = await funeralHomes.getFuneralHomeLogos()
    data && setLogo(data)
  }

  useEffect(() => {
    getLogos()
  }, [])

  const uploadLogo = async (file, sizeName) => {
    new Compressor(file[0], {
      maxWidth: dimensions[sizeName],
      maxHeight: dimensions[sizeName],
      async success(result) {
        const formData = new FormData()
        // The third parameter is required for server
        formData.append('file', result, 'blob')
        const { data } = await funeralHomes.createFuneralHomeLogo(
          sizeName,
          formData,
        )
        setLogo(data)
        setHomeLogo(data.small || data.medium || data.large)
        setToast({
          text: 'Logo added!',
          icon: 'check',
        })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  const replaceLogo = async (file, sizeName) => {
    new Compressor(file[0], {
      maxWidth: dimensions[sizeName],
      maxHeight: dimensions[sizeName],
      async success(result) {
        const formData = new FormData()
        // The third parameter is required for server
        formData.append('file', result, 'blob')
        const { data } = await funeralHomes.updateFuneralHomeLogo(
          logo.funeral_home_id,
          sizeName,
          formData,
        )
        setLogo(data)
        setHomeLogo(data.small || data.medium || data.large)
        setToast({
          text: 'Logo updated!',
          icon: 'check',
        })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  return (
    <Flex column full padding="0 40px 0 0">
      <Flex column align="flex-start">
        <Text weight="600" margin="0 0 12px 0">
          Funeral home logos
        </Text>

        <Text size="14px" color="gray2" margin="0 0 32px 0">
          Logos will be used on product stationery
        </Text>
        <Text size="14px" margin="0 0 24px 0">
          Small logo
        </Text>
        <LogoCard
          logo={logo}
          sizeName="small"
          replaceLogo={replaceLogo}
          uploadLogo={uploadLogo}
        />

        <Text size="14px" margin="0 0 24px 0">
          Medium logo
        </Text>
        <LogoCard
          logo={logo}
          sizeName="medium"
          replaceLogo={replaceLogo}
          uploadLogo={uploadLogo}
        />

        <Text size="14px" margin="0 0 24px 0">
          Large logo
        </Text>
        <LogoCard
          logo={logo}
          sizeName="large"
          replaceLogo={replaceLogo}
          uploadLogo={uploadLogo}
        />
      </Flex>
    </Flex>
  )
}
