import chunk from 'lodash/chunk'
import { caseDetailSpan } from '.'

const tableRow = (chunk) => `
  <tr>
    ${chunk.map((value) => tableData(value)).join('')}
  </tr>
`

const tableData = (value) => `
  <td 
    style="width: 50%; 
    text-align: center" 
    data-mce-style="width: 50%; text-align: center;"
  >
    ${caseDetailSpan(value)}
  </td>
`

export default (arr, key) => {
  const newArr = arr.map((_, i) => `${key}[${i}]`)
  const chunked = chunk(newArr, 2)
  return `
    <table
      style="border-collapse: collapse; width: 100%"
      border="0"
      data-mce-style="border-collapse: collapse; width: 100%;"
    >
      <tbody>
        ${chunked.map((chunk) => tableRow(chunk)).join('')}
      </tbody>
    </table>
`
}
