import React, { useState, useEffect } from 'react'
import { Flex, Button, Text, FullscreenModal, NewThemeModal } from 'components'
import { products as productsAPI, customThemes as themesAPI } from 'api'
import { ThemeEditor } from './ThemeEditorContent'
import { default as Tabs } from './CustomizeTabs/CustomizeTabs'
import { useNavigate } from 'hooks'

export default ({ theme, closeModal }) => {
  const [products, setProducts] = useState([])
  const [productTabs, setProductTabs] = useState([])
  const [allLayouts, setAllLayouts] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const [showNewThemeModal, setShowNewThemeModal] = useState(false)
  const navigate = useNavigate()

  const getProducts = async () => {
    const { data } = await productsAPI.getProductsByCollection(
      '00000000-57c7-4c01-caa5-ce2103a01c53',
    )
    data.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    setProducts(data)
  }

  const getProductTabs = () => {
    const tabsArray = []
    const mainTab = {
      title: 'Main Theme Image',
      content: (
        <ThemeEditor
          product={{ name: 'Main Theme Image', layouts: allLayouts }}
          theme={theme}
          allLayouts={allLayouts}
          getCustomThemeLayouts={getCustomThemeLayouts}
          main={true}
        />
      ),
    }
    tabsArray.push(mainTab)
    products.map((product) => {
      const tab = {
        title: product.name,
        thumbnail: product.thumbnail,
        content: <ThemeEditor product={product} theme={theme} />,
      }
      !product.name.includes('Front Insert') &&
        !product.name.includes('Extra') &&
        tabsArray.push(tab)
      return product
    })
    setActiveTab('Main Theme Image')
    setProductTabs(tabsArray)
  }

  const getCustomThemeLayouts = async () => {
    let retrievedLayouts = []
    const productsWithLayouts = await Promise.all(
      products.map(async (product) => {
        const { data } = await themesAPI.layoutForCustomThemes(
          product.id,
          theme.id,
        )
        retrievedLayouts = retrievedLayouts.concat(data)
        product.layouts = data
        return product
      }),
    )
    setProducts(productsWithLayouts)
    setAllLayouts(retrievedLayouts)
  }

  const saveAndExit = () => {
    closeModal && closeModal()
    !closeModal && navigate(`/customThemes`)
  }

  useEffect(() => {
    getProducts()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getProductTabs()
    //eslint-disable-next-line
  }, [allLayouts, theme])

  useEffect(() => {
    theme?.id &&
      products.length > 0 &&
      !products[0]?.layouts &&
      getCustomThemeLayouts()
    //eslint-disable-next-line
  }, [products, theme])

  return (
    <Flex full column>
      <Flex
        padding="24px 40px"
        margin="0 0 48px 0"
        height="94px"
        background="paleSecondary"
        align="center"
        justify="space-between"
        full
      >
        <Text size="34px" weight="600">
          {theme?.name}
        </Text>
        <Flex>
          <Button
            margin="0 16px 0 0"
            onClick={() => setShowNewThemeModal(true)}
          >
            Edit theme details
          </Button>
          <Button
            margin={closeModal ? '0 16px 0 0' : undefined}
            primary
            onClick={() => saveAndExit()}
          >
            Save & Exit
          </Button>
        </Flex>
      </Flex>
      <Tabs tabs={productTabs} selected={[activeTab, setActiveTab]} />
      <FullscreenModal show={[showNewThemeModal, setShowNewThemeModal]}>
        <NewThemeModal
          theme={theme}
          setShowNewThemeModal={setShowNewThemeModal}
        />
      </FullscreenModal>
    </Flex>
  )
}
