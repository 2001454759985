export default [
  {
    name: 'AG University',
    file: 'ag-university',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Amazone',
    file: 'amazone',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Arima Madurai',
    file: 'arima-madurai',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Bitter',
    file: 'bitter',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold'],
  },
  {
    name: 'Book Antiqua',
    file: 'book-antiqua',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Bookman Old Style',
    file: 'bookman-old-style',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Century Gothic',
    file: 'century-gothic',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Charm',
    file: 'charm',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Cormorant',
    file: 'cormorant',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Dancing Script',
    file: 'dancing-script',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'English',
    file: 'english-157',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'FG Jayne Print',
    file: 'fg-jayne-print',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Gaeilge',
    file: 'gaeilge',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Goudy Catalog',
    file: 'goudy-catalog',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Goudy Old Style',
    file: 'goudy-old-style',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold'],
  },
  {
    name: 'Kalam',
    file: 'kalam',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Lato',
    file: 'lato',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Liberty',
    file: 'liberty',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Lobster Two',
    file: 'lobster-two',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Lora',
    file: 'lora',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Merienda',
    file: 'merienda',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Mistral',
    file: 'mistral',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Monotype Corsiva',
    file: 'monotype-corsiva',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Monterey',
    file: 'monterey',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Murray Hill',
    file: 'murray-hill',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Nuptial',
    file: 'nuptial',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Oleo Script',
    file: 'oleo-script',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Papyrus',
    file: 'papyrus',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Park Avenue',
    file: 'park-avenue',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Piranesi It',
    file: 'piranesi-it',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Pristina',
    file: 'pristina',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Ribbon',
    file: 'ribbon-131',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Ruthie',
    file: 'ruthie',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Scriptina',
    file: 'scriptina',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Shelley Allegro',
    file: 'shelley-allegro',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Shelley Andante',
    file: 'shelley-andante',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Tangerine',
    file: 'tangerine',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Times New Roman',
    file: 'times-new-roman',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Vivaldi',
    file: 'vivaldi',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Wedding Text',
    file: 'wedding-text',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Abyssinica SIL',
    file: 'abyssinica-sil',
    format: 'ttf',
    language: 'Amharic - አማርኛ',
    weights: ['regular'],
  },
  {
    name: 'David Libre',
    file: 'david-libre',
    format: 'ttf',
    language: 'Hebrew עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Frank Ruhl Libre',
    file: 'frank-ruhl-libre',
    format: 'ttf',
    language: 'Hebrew - עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Heebo',
    file: 'heebo',
    format: 'ttf',
    language: 'Hebrew - עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Mirza',
    file: 'mirza',
    format: 'ttf',
    language: 'Arabic - عربى',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Sans KR',
    file: 'noto-sans-kr',
    format: 'otf',
    language: 'Korean - 한국어',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Serif SC',
    file: 'noto-serif-sc',
    format: 'otf',
    language: 'Simplified Chinese - 中文',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Serif TC',
    file: 'noto-serif-tc',
    format: 'otf',
    language: 'Traditional Chinese - 中文',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Open Sans',
    file: 'open-sans',
    format: 'ttf',
    language: 'Vietnamese - Tiếng Việt',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
]
