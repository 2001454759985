import React from 'react'
import { Flex, Text, AnimatedHeight, Icon } from 'components'
import * as S from './CustomizeTabs.styled'
import findIndex from 'lodash/findIndex'

export default ({
  tabs,
  width,
  noOverflow,
  icon,
  iconClick,
  selected: [selected, setSelected] = ['', () => {}],
}) => {
  const selectedIndex = findIndex(tabs, ({ title }) => title === selected)

  const iconClickUnpropagated = (e, i) => {
    e.stopPropagation()
    iconClick(tabs[i])
  }

  return (
    <Flex full overflow="hidden">
      <Flex column margin="0 42px" width={width}>
        {tabs?.map(({ title, key }, i) => (
          <S.Tab
            clickable
            key={`${title}${i}}`}
            padding="16px"
            radius="2px"
            justify={icon ? 'space-between' : undefined}
            margin={
              title.includes('Front Insert') || title.includes('Extra')
                ? '0 0 0 25px'
                : undefined
            }
            selected={selectedIndex === i}
            onClick={() => setSelected(title)}
          >
            <Text>{title}</Text>
            {icon && (
              <Icon
                icon={icon}
                fill="gray2"
                onClick={(e) => iconClickUnpropagated(e, i)}
              />
            )}
          </S.Tab>
        ))}
      </Flex>
      <Flex
        align="flex-start"
        column
        flex="1"
        overflow={!noOverflow ? 'auto' : 'inherit'}
      >
        {tabs?.map(({ title, content }, i) => (
          <AnimatedHeight
            key={`${title}${i}`}
            height={selectedIndex === i ? 'auto' : 0}
            duration={250}
            aria-hidden={true}
          >
            {selectedIndex === i && content}
          </AnimatedHeight>
        ))}
      </Flex>
    </Flex>
  )
}
