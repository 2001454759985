import React, { useMemo, useState, useEffect, useRef } from 'react'
import {
  useSetToast,
  useNavigate,
  resetOrderAndCaseAtoms,
  importCaseCSV,
} from 'hooks'
import { cases, tukios, orders, orderProducts } from 'api'
import { orderAtom, caseAtom } from 'atoms'
import { useSetRecoilState, useRecoilState } from 'recoil'
import {
  ArchiveFooter,
  Flex,
  FullscreenModal,
  MoreActionsButton,
  Table,
  Text,
  CaseDetails,
  CasesTableLite,
  ImportFailureModal,
} from 'components'
import * as S from './DashboardTable.styled'

export default ({
  type,
  title,
  api,
  mockTable,
  TableComponent,
  CreateComponent,
}) => {
  const setToast = useSetToast()
  const navigate = useNavigate()
  const [tableContent, setTableContent] = useState(mockTable)
  const [itemsLoaded, setItemsLoaded] = useState(false)
  const [showingArchived, setShowingArchived] = useState(false)
  const [showCheckboxes, setShowCheckboxes] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [updateTable, setUpdateTable] = useState(Date.now())
  const [openCaseModal, setOpenCaseModal] = useState(false)
  const [duplicateOrderId, setDuplicateOrderId] = useState(null)
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState(false)
  const [_case, setCase] = useRecoilState(caseAtom)
  const [csvFailed, setCsvFailed] = useState(false)
  const [refreshCases, setRefresh] = useState(false)
  const setOrder = useSetRecoilState(orderAtom)
  const resetAtoms = resetOrderAndCaseAtoms()
  const inputFile = useRef(null)

  const getItems = async (archived, skipCaseReset) => {
    // retreives the items to populate the table
    const { data } = await api?.[`getFuneralHome${type}s`](archived)
    tableContent && setTableContent(data[`${type.toLowerCase()}s`])
    tableContent && setItemsLoaded(true)
    resetAtoms(skipCaseReset)
  }

  const archive = async (multiple, item) => {
    // determine which items will be mapped through, and what will be archived
    const items = multiple ? selectedRows : [item]
    const archiveFunction = showingArchived
      ? `unarchive${type}`
      : `archive${type}`

    try {
      // archives or unarchives the selected items
      await Promise.all(
        items.map(async (item) => {
          await api?.[archiveFunction](item?.original?.id)
        }),
      )

      // retrieves the new items and hides the checkboxes
      setUpdateTable(Date.now())

      // displays success toast
      setToast({
        text: showingArchived ? `${type}s Restored` : `${type}s Archived`,
        icon: 'check',
      })

      setShowCheckboxes(false)
    } catch {
      // displays failure toast
      setToast({ text: 'There was an internal server error' })
    }
  }

  const toggleArchived = async (archived) => {
    // toggles between unarchived and archived
    setItemsLoaded(false)
    setShowingArchived(archived)
    await getItems(archived)
  }

  const clearSelectedRows = async () => {
    // unselects all checked rows
    setSelectedRows([])
    setShowCheckboxes(false)
  }

  const createTukiosFilm = async (payload) => {
    const response = await tukios.create(payload)
    if (response.data.url) {
      window.open(response.data.url, '_blank')
    }
  }

  const goToOrder = async (id) => {
    const { data: orderData } = await orders.getOrder(id)
    let orderProductsData
    if (orderData.collection_id) {
      orderProductsData = await orderProducts.getOrderProducts(
        orderData.collection_id,
        orderData.id,
      )
    }
    setOrder(orderData)
    if (orderData.theme_id) navigate(`/package/${id}/customize`)
    else if (orderProductsData?.data?.length > 0)
      navigate(`/package/${id}/theme`)
    else if (orderData.collection_id) navigate(`/package/${id}/products`)
    else navigate(`/package/${id}`)
  }

  const createOrder = async (id) => {
    const { data } = await orders.createOrder({ case_id: id })
    setOrder(data)

    navigate(`/package/${data.id}`)
  }

  const openDuplicateOrder = (orderId) => {
    setOpenCaseModal(true)
    setDuplicateOrderId(orderId)
  }

  const duplicateOrder = async (caseId) => {
    const payload = {
      order_id: duplicateOrderId,
      case_id: caseId,
    }
    try {
      const { data } = await orders.duplicateOrder(payload)
      data?.id &&
        setToast({
          text: 'Order Duplicated',
          icon: 'check',
        })
    } catch (err) {
      setToast({
        text: 'Something went wrong!',
      })
    }
    setDuplicateOrderId(null)
    setOpenCaseModal(false)
    await getItems(false)
  }

  const openCaseDetailsModal = async (caseId) => {
    const { data: _case } = await cases.getCase(caseId)
    setCase(_case)
    setShowCaseDetailsModal(true)
  }

  const handleFileChange = async (e) => {
    let { files } = e.target
    if (files && files.length) {
      const result = await importCaseCSV(files[0])
      try {
        await cases.createCase(result)
        setToast({
          text: 'Case imported',
          icon: 'check',
        })
      } catch (err) {
        setCsvFailed(true)
      }
      inputFile.current.value = ''
      getItems(false)
      setRefresh(true)
    }
  }

  const openFile = () => {
    inputFile.current.click()
  }

  useEffect(() => {
    getItems(showingArchived)
    // eslint-disable-next-line
  }, [updateTable])

  const goToTableRow = (id) => {
    type === 'Order' && goToOrder(id)
    type === 'Case' && openCaseDetailsModal(id)
  }

  const columns = useMemo(
    () =>
      TableComponent({
        itemsLoaded: itemsLoaded,
        showingArchived: showingArchived,
        archive: archive,
        createTukiosFilm: createTukiosFilm,
        openCaseDetailsModal: openCaseDetailsModal,
        editPackage: goToOrder,
        openDuplicateOrder: openDuplicateOrder,
      }),
    // eslint-disable-next-line
    [itemsLoaded, showingArchived, showCheckboxes],
  )

  return (
    <Flex column align="center">
      <ImportFailureModal
        show={[csvFailed, setCsvFailed]}
        openFile={openFile}
      />

      <Flex
        align="center"
        background="paleSecondary"
        full
        height="94px"
        justify="space-between"
        padding="0 42px"
      >
        <Text size="32px" weight="600">
          {title}
        </Text>

        <Flex>
          <MoreActionsButton
            showingArchived={showingArchived}
            toggleCheckboxes={() => setShowCheckboxes(!showCheckboxes)}
            openFile={openFile}
          />
          <CreateComponent
            buttonText={'New Print Package'}
            rowClick={createOrder}
            type={type}
            importCase={openFile}
            refresh={[refreshCases, setRefresh]}
            getItems={getItems}
          />
        </Flex>
      </Flex>
      <S.HiddenInput ref={inputFile} onChange={handleFileChange} type="file" />
      <Flex padding="32px 42px 0 42px" full>
        <Table
          columns={columns}
          data={tableContent}
          setItemsLoaded={setItemsLoaded}
          setSelectedRows={setSelectedRows}
          searchPlaceholder="Search by any field"
          showingArchived={showingArchived}
          showCheckboxes={showCheckboxes}
          toggleArchived={toggleArchived}
          defaultClick={({ original: { id } }) => goToTableRow(id)}
        />
      </Flex>

      <FullscreenModal show={[openCaseModal, setOpenCaseModal]}>
        <CasesTableLite
          rowClick={duplicateOrder}
          setShowModal={setOpenCaseModal}
          height="100vh"
          type="duplicate"
        />
      </FullscreenModal>

      <FullscreenModal show={[showCaseDetailsModal, setShowCaseDetailsModal]}>
        {showCaseDetailsModal && (
          <CaseDetails selected_case={{ ..._case }} updateTable={getItems} />
        )}
      </FullscreenModal>

      <ArchiveFooter
        archive={archive}
        clearSelectedRows={clearSelectedRows}
        selectedRows={selectedRows}
        show={showCheckboxes}
        showingArchived={showingArchived}
      />
    </Flex>
  )
}
