import React from 'react'
import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import { InsertCategoryItem } from 'components'

export default ({
  setMenu,
  showSlideout,
  selectedMenu,
  setSelectedMenu,
  setShowSlideout,
}) => {
  const menus = [
    'SearchBar',
    'SavedLayouts',
    'PhotosOfDeceased',
    'Artwork',
    'Verses',
    'CustomVersesAndPhrases',
    'Memorials',
    'FuneralHomeLogos',
    'FloralArrangements',
  ]

  return menus?.map((menu) => (
    <InsertCategoryItem
      selected={showSlideout && selectedMenu === menu}
      onClick={() => setMenu(menu)}
      key={menu}
      menu={menu}
      setSelectedMenu={setSelectedMenu}
      setShowSlideout={setShowSlideout}
    >
      {capitalize(startCase(menu))}
    </InsertCategoryItem>
  ))
}
