import React, { useState, useEffect } from 'react'
import {
  Flex,
  EditorHeader,
  InsertMenu,
  Canvas,
  PropertiesMenu,
  FullscreenModal,
  Pintura,
  SlideoutMenu,
  Loading,
} from 'components'
import {
  updatePageItems,
  useSetToast,
  useNavigate,
  useResetEditorAtoms,
} from 'hooks'
import { funeralHomeSettings, orderPages, themes, orderPageSets } from 'api'
import {
  caseSelector,
  editorPagesAtom,
  itemIdAtom,
  showPinturaAtom,
  unsavedChangesAtom,
  dateFormatAtom,
  editorLayoutAtom,
  editorOrderPageSetAtom,
  triggerUseEffect,
  editorClipboardAtom,
  showUnsavedModalAtom,
  backgroundImageAtom,
  themeLayoutAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { funeralHomeDateFormat } from 'utils'
import { UnsavedModal } from 'components'

export default () => {
  const [editorData, setEditorData] = useState({})
  const [pages, setPages] = useRecoilState(editorPagesAtom)
  const [header, setHeader] = useState('')
  const { pathname: location } = useLocation()
  const setCase = useSetRecoilState(caseSelector)
  const setEditorLayout = useSetRecoilState(editorLayoutAtom)
  const setEditorOrderPageSet = useSetRecoilState(editorOrderPageSetAtom)
  const itemIds = useRecoilValue(itemIdAtom)
  const setDateFormat = useSetRecoilState(dateFormatAtom)
  const [initialLoad, setInitialLoad] = useState(true)
  const updateItems = updatePageItems()
  const setToast = useSetToast()
  const [showPintura, setShowPintura] = useRecoilState(showPinturaAtom)
  const [showUnsavedModal, setShowUnsavedModal] =
    useRecoilState(showUnsavedModalAtom)
  const navigate = useNavigate()
  const [goBack, setGoBack] = useState(false)
  const resetEditorValues = useResetEditorAtoms()
  const [showSlideout, setShowSlideout] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesAtom)
  const [saveClick, setSaveClick] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const trigger = useRecoilValue(triggerUseEffect)
  const setClipboard = useSetRecoilState(editorClipboardAtom)
  const setBackgroundImage = useSetRecoilState(backgroundImageAtom)
  const setThemeLayout = useSetRecoilState(themeLayoutAtom)

  const getData = async () => {
    const [, , orderPageSetId] = location.split('/')
    try {
      const { data } = await orderPages.getEditorOrderPageDetails(
        orderPageSetId,
      )
      setEditorLayout(data?.layout?.layout_id)
      const { data: settings } =
        await funeralHomeSettings.getFuneralHomeSettings()
      const fetchedWatermarks = []

      //fetch the watermarks if they exist
      if (data.theme_layout[0].first_watermark) {
        const { data: firstWatermark } = await themes.getWatermark(
          data.theme_layout[0].first_watermark,
        )
        //set the required data fields and do the math to translate them to 96dpi
        //TODO: once the old site is decommissioned we can run a script to do this globally
        firstWatermark.type = 'watermark'
        firstWatermark.y = (firstWatermark.top / 72) * 96
        firstWatermark.x = (firstWatermark.left / 72) * 96
        firstWatermark.height = (firstWatermark.height / 72) * 96
        firstWatermark.width = (firstWatermark.width / 72) * 96
        firstWatermark.z = 1
        //add the watermark to the array of watermarks
        fetchedWatermarks.push(firstWatermark)
      }
      if (data.theme_layout[0].second_watermark) {
        const { data: secondWatermark } = await themes.getWatermark(
          data.theme_layout[0].second_watermark,
        )
        secondWatermark.type = 'watermark'
        secondWatermark.y = (secondWatermark.top / 72) * 96
        secondWatermark.x = (secondWatermark.left / 72) * 96
        secondWatermark.z = 1
        secondWatermark.height = (secondWatermark.height / 72) * 96
        secondWatermark.width = (secondWatermark.width / 72) * 96

        fetchedWatermarks.push(secondWatermark)
      }

      const format = funeralHomeDateFormat(settings.date_format)
      setDateFormat(format)

      const pageData = []

      data.pages.map((page, index) => {
        const { json_template } = page
        const items = json_template?.product?.items?.map((item) => {
          //the backend is parsing this in so lets
          //TODO remove this uuid addition
          const uuid = uuidv4()
          item.id = uuid
          return item
        })
        json_template.product.items = items
        //set a variable to check for watermark matches
        let matchedMarks
        //if the json template has watermarks and is page 1 (I think interior pages do not have watermarks 🤔)
        if (
          json_template?.product?.watermarks?.length > 0 &&
          json_template.pageProperties.pageNumber === 1
        ) {
          //check to see if the currently set watermarks match the incoming watermarks
          //we want to see if these are the same watermarks as on the currently selected theme
          //we'll want to set some values in the future to track if they replaced the watermarks (then the ids won't match but we won't want to update them)
          //TODO - create logic for handling replaced watermarks and replaced 1 watermark
          matchedMarks = fetchedWatermarks.find((mark) => {
            return json_template.product.watermarks.find(
              (storedMark) => storedMark.id === mark.id,
            )
          })
        }
        //if there are no matches (the watermarks are new to this package) and it's page 1, set the watermarks to be the ones fetched from the db
        if (!matchedMarks && json_template.pageProperties.pageNumber === 1) {
          json_template.product.watermarks = fetchedWatermarks
        }
        //we need the order page id more easily accessible for save, maybe backend can do this but no time for that now in budget
        json_template.product.orderPageId = page.id

        pageData.push(page.json_template)
        return page
      })

      setCase(data.case)
      setHeader(
        `${data?.case?.name_of_deceased} | ${data?.product?.name}: ${data?.layout?.name} | ${data?.theme?.name}`,
      )

      const sortedPages = pageData.sort((a, b) =>
        a.pageProperties.pageNumber < b.pageProperties.pageNumber ? -1 : 0,
      )

      const themeLayout = await themes.getThemeLayout(
        data?.theme.id,
        data?.layout?.layout_id,
      )

      setEditorData(data)
      setBackgroundImage(data.theme?.image)
      setPages(sortedPages)
      setInitialLoad(false)
      setEditorOrderPageSet(data.page_set)
      setThemeLayout(themeLayout?.data?.[0])
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error(err.response.data)
    }
  }

  useEffect(() => {
    resetEditorValues()
    getData()
    //eslint-disable-next-line
  }, [trigger])

  //save will run everytime the page json changes. right now this is only on click save button
  useEffect(() => {
    !initialLoad && saveClick && save()
    goBack && navigate(`/package/${editorData.order.id}/customize`)
    goBack && setClipboard({})
    //do we need to reset this since we navigate away?
    goBack && setGoBack(false)
    //eslint-disable-next-line
  }, [pages, goBack])

  const save = async () => {
    const saved = []
    const error = []
    await Promise.all(
      pages.map(async (page, index) => {
        const payload = { json_template: JSON.stringify(page) }
        try {
          const { status } = await orderPages.updateOrderPage(
            page.product.orderPageId,
            payload,
          )
          status === 200 && saved.push(index + 1)
        } catch (err) {
          console.error(err)
          error.push(index + 1)
        }
        return page
      }),
    )
    setUnsavedChanges(false)
    saveClick &&
      saved.length === pages.length &&
      setToast({ text: 'Product saved!' })
    error.length > 0 &&
      setToast({
        text: `Something went wrong saving pages: ${error.join(', ')}`,
      })
    setSaveClick(false)
  }

  const update = async () => {
    const [, , orderPageSetId] = location.split('/')
    await orderPageSets.wipePageSetPdfDownloadUrl(orderPageSetId)
    updateItems()
    setSaveClick(true)
  }

  const back = () => {
    !unsavedChanges && setGoBack(true)
    unsavedChanges && setShowUnsavedModal(true)
  }
  return (
    <Flex column height="100vh">
      <FullscreenModal hideClose show={[showPintura, setShowPintura]}>
        <Pintura update={update} />
      </FullscreenModal>
      <FullscreenModal show={[showUnsavedModal, setShowUnsavedModal]}>
        <UnsavedModal
          setShow={() => setShowUnsavedModal(false)}
          orderId={editorData?.order?.id}
        />
      </FullscreenModal>
      <EditorHeader assetDetails={header} update={update} back={back} />
      <Flex
        justify="space-between"
        align={isLoading ? 'center' : 'flex-start'}
        position="relative"
        background="paleSecondary"
        height="calc(100vh - 70px)"
        borderTop="1px solid"
        borderColor="gray4"
      >
        <InsertMenu
          basePages={editorData?.pages}
          baseLayout={editorData?.layout}
          setIsLoading={setIsLoading}
        />

        <SlideoutMenu
          className="slide-out"
          title="Pages"
          left="250px"
          //TODO: move to styled component? there is probably a better way
          style={{ zIndex: '10' }}
          show={[showSlideout, setShowSlideout]}
          menu="PageShuffler"
          theme={editorData?.theme}
          themeLayout={editorData?.theme_layout?.[0]}
          basePages={editorData?.pages}
          baseLayout={editorData?.layout}
        />

        <Loading isLoading={isLoading}>
          {pages.length > 0 && (
            <Canvas
              id={itemIds[0]}
              theme={editorData?.theme}
              themeLayout={editorData?.theme_layout?.[0]}
              loadedPages={pages}
              setShowSlideout={setShowSlideout}
            />
          )}
        </Loading>
        <PropertiesMenu />
      </Flex>
    </Flex>
  )
}
