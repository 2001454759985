import React, { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  ThemeCard,
  NewThemeModal,
  FullscreenModal,
  Button,
  DigitalAgreement,
} from 'components'
import { customThemes as themesAPI } from 'api'
import { useSetToast, useNavigate } from 'hooks'

export default () => {
  const [customThemes, setCustomThemes] = useState([])
  const [showNewThemeModal, setShowNewThemeModal] = useState(false)
  const [showFullModal, setShowFullModal] = useState(false)
  const setToast = useSetToast()
  const navigate = useNavigate()

  const getThemes = async () => {
    const { data } = await themesAPI.getCustomThemes()
    setCustomThemes(data)
  }

  const editTheme = (theme) => {
    navigate(`/customThemes/${theme.id}`)
  }

  const deleteTheme = async (theme) => {
    try {
      const resp = await themesAPI.archiveCustomTheme(theme.id)
      if (resp.status === 200) {
        setToast({
          text: 'Theme Deleted',
          icon: 'check',
        })
      }
      getThemes()
    } catch (err) {
      setToast({
        text: err?.response?.data?.message || err.message,
        icon: 'deleteOutline',
      })
    }
  }

  const createTheme = async (payload) => {
    try {
      const resp = await themesAPI.createCustomTheme(payload)
      if (resp.status === 201) {
        setToast({
          text: 'Theme Created',
          icon: 'check',
        })
        navigate(`/customThemes/${resp.data.id}`)
      }
      getThemes()
    } catch (err) {
      setToast({
        text: err?.response?.data?.message || err.message,
        icon: 'deleteOutline',
      })
    }
  }

  const agreeToTerms = () => {
    setShowFullModal(false)
    setShowNewThemeModal(true)
  }

  useEffect(() => {
    getThemes()
  }, [])

  return (
    <Flex full column>
      <Flex
        padding="24px 40px"
        margin="0 0 48px 0"
        height="94px"
        background="paleSecondary"
        align="center"
        justify="space-between"
        full
      >
        <Text size="34px" weight="600">
          Custom Themes
        </Text>
        <Button primary icon="plus" onClick={() => setShowFullModal(true)}>
          New Custom Theme
        </Button>
        <FullscreenModal show={[showFullModal, setShowFullModal]}>
          <DigitalAgreement
            setShowFullModal={setShowFullModal}
            agreeToTerms={agreeToTerms}
          />
        </FullscreenModal>
        <FullscreenModal show={[showNewThemeModal, setShowNewThemeModal]}>
          <NewThemeModal
            createTheme={createTheme}
            setShowNewThemeModal={setShowNewThemeModal}
          />
        </FullscreenModal>
      </Flex>
      <Flex full wrap="wrap" justify="center">
        {customThemes.map((theme) => (
          <ThemeCard
            key={theme.id}
            theme={theme}
            editTheme={editTheme}
            deleteTheme={deleteTheme}
            noHover={showFullModal || showNewThemeModal}
          />
        ))}
      </Flex>
    </Flex>
  )
}
