import React, { useEffect, useState } from 'react'
import { Flex, Dropdown, Button, Text, Link } from 'components'
import { PrintGuideModal } from './'
import { orders, orderProducts, orderPageSets } from 'api'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { productsAtom, selectedProductsAtom } from 'atoms'
import { frontEndDomain, runPolling } from 'utils'

export default ({
  order,
  productInfo,
  productLineInfo,
  themeInfo,
  selectedLayout: [selectedLayout, setSelectedLayout] = ['', null],
  selectedProducts: [selectedProducts, setSelectedProducts] = ['', null],
  layout: [layout, setLayout] = ['', null],
  pageSet,
  allLayouts,
  oldProduct,
  isLoading,
}) => {
  const [showPrintGuide, setShowPrintGuide] = useState(false)
  const [polling, setPolling] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState(null)
  const products = useRecoilValue(productsAtom)
  const setOrderProducts = useSetRecoilState(selectedProductsAtom)

  const downloadPDF = (file_url) => {
    let openWindow = window.open(file_url)
    try {
      openWindow.focus()
    } catch (err) {
      console.log(err)
    }
  }

  const successfulGeneration = (data) => {
    setDownloadUrl(data.aws_download_url)
    downloadPDF(data.aws_download_url)
  }

  const generatePDF = async () => {
    let body = {
      url: frontEndDomain,
      html: 'pdfProduct',
      page_height: selectedLayout.paper_height,
      page_width: selectedLayout.paper_width,
      order_id: order.id,
      order_page_set_id: pageSet.id,
    }
    try {
      await orders.generatePDF(body)
      await runPolling(
        pageSet.id,
        orderPageSets.getPageSetPDF,
        setPolling,
        (data) => successfulGeneration(data),
      )
    } catch (err) {
      console.error(
        'ERROR: adding generate PDF job to heroku service worker',
        err,
      )
    }
  }

  const isPMRegisterBook = (name) => {
    return (
      productLineInfo?.id === '00000000-57c7-4c01-caa5-ce2103a01c53' &&
      name.includes('Register Book') &&
      !name.includes('Front Insert') &&
      !name.includes('Extra Pages')
    )
  }

  const needsFrontInsert = (name) => {
    return selectedProducts.some(
      (product) =>
        product.name === `${name}: Front Insert` && !product.selected,
    )
  }

  const getOrderProducts = async () => {
    const { data: orderProductsData } = await orderProducts.getOrderProducts(
      order.collection_id,
      order.id,
    )
    setOrderProducts(orderProductsData)
  }

  const addExtraPages = async () => {
    const extraPages = products.find(
      (product) =>
        product.name.includes(`${productInfo.name}: Extra Pages`) &&
        product.collection_id === productInfo.collection_id,
    )
    setSelectedProducts(extraPages)
    /*TODO: the poduct gets sent to the DB in the selector above, 
    but we don't get the data back because selectors can't be async - 
    so we need to refetch the products, if we do that _immediately_ 
    then the selector hasn't finished and we don't get the new addition, 
    so a short wait resolved that, this could be made more elegant, 
    but this timeout works for now :(*/
    setTimeout(() => getOrderProducts(), 500)
  }

  const addFrontInsert = () => {
    const frontInsert = products.find(
      (product) =>
        product.name.includes(`${productInfo.name}: Front Insert`) &&
        product.collection_id === productInfo.collection_id,
    )
    setSelectedProducts(frontInsert)
  }

  const goToV1 = () => {
    const V1 = `https://directorsprintsuite.com/order/${order.id}/${order.case_id}`
    window.open(V1, '_blank')
  }

  useEffect(() => {
    pageSet?.download_url && setDownloadUrl(pageSet.download_url)
  }, [pageSet])

  return (
    <Flex column>
      <Flex
        margin={isPMRegisterBook(productInfo.name) ? '0 0 0 0' : '0 0 32px'}
        padding="0 40px"
        justify="space-between"
        align="center"
      >
        <Flex column>
          <Text size="24px" weight="600">
            {productInfo?.name}
          </Text>
          <Text>
            {productLineInfo?.name} • {themeInfo?.name}
          </Text>
        </Flex>
        <Flex>
          <Dropdown
            selected={[layout, setLayout]}
            placeholder="Layout:"
            textKey="name"
            valueKey="id"
            label="Layout"
            items={allLayouts}
            margin="0 16px 0 0"
          />
          <Button
            margin="19px 16px 0 0"
            onClick={() => setShowPrintGuide(!showPrintGuide)}
          >
            Printing Guide
          </Button>
          {!oldProduct && (
            <>
              <Button
                margin="19px 16px 0 0"
                onClick={() =>
                  downloadUrl ? downloadPDF(downloadUrl) : generatePDF()
                }
                disabled={isLoading || polling || oldProduct}
              >
                {downloadUrl
                  ? 'Download PDF'
                  : polling
                  ? 'Processing PDF...'
                  : 'Generate PDF'}
              </Button>
              <Flex disabled={isLoading || oldProduct}>
                <Link href={`/editor/${pageSet?.id}`}>
                  <Button margin="19px 0 0 0" primary>
                    Customize
                  </Button>
                </Link>
              </Flex>
            </>
          )}
          {oldProduct && (
            <Button primary margin="19px 16px 0 0" onClick={() => goToV1()}>
              Open on V1
            </Button>
          )}
        </Flex>
      </Flex>

      {isPMRegisterBook(productInfo.name) && (
        <Flex justify="flex-end" margin="0 0 32px" padding="0 40px">
          <Button margin="19px 16px 0 0" onClick={addExtraPages}>
            Add Extra Pages
          </Button>
          {needsFrontInsert(productInfo.name) && (
            <Button margin="19px 0px 0 0" onClick={addFrontInsert}>
              Add Front Insert
            </Button>
          )}
        </Flex>
      )}
      <PrintGuideModal
        show={[showPrintGuide, setShowPrintGuide]}
        selectedLayout={selectedLayout}
        productInfo={productInfo}
        productLineInfo={productLineInfo}
      />
    </Flex>
  )
}
