import { TableCell, Tooltip, Icon } from 'components'
import * as S from './Table.styled'

export default ({ loaded, actions }) => (
  <TableCell loaded={loaded}>
    <S.Actions>
      {actions.map(({ text, icon, onClick }, i) => (
        <Tooltip text={text} key={i}>
          <Icon
            icon={icon}
            onClick={() => onClick?.()}
            fill="brand"
            margin="8px"
            clickable
          />
        </Tooltip>
      ))}
    </S.Actions>
    <S.Ellipses>
      <Icon icon="ellipses" fill="gray3" margin="8px" />
    </S.Ellipses>
  </TableCell>
)
