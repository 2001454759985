import React from 'react'
import { Flex, Icon, Text, Tooltip, Image } from 'components'

export default ({ image, index, makeDefault, deleteImage }) => (
  <Flex
    column
    border="1px solid"
    borderColor="gray4"
    margin="0 0 24px 0"
    radius="4px"
    align="center"
  >
    <Flex full justify="space-between">
      <Flex>
        <Image
          margin="20px 20px"
          src={`${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${image}`}
          height="100px"
          maxWidth="95%"
        />
        {index === 0 && (
          <Flex center>
            <Icon
              icon="check"
              fill="gray2"
              height="12px"
              width="12px"
              margin="0 8px 0 0"
            />
            <Text color="gray2" size="12px" weight="500">
              Default image
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex center>
        {index !== 0 && (
          <Tooltip text="Make default" margin="0 12px 0 0">
            <Icon
              height="16px"
              width="16px"
              icon="makeDefault"
              fill="brand"
              disabled={index === 0}
              onClick={() => {
                makeDefault(index)
              }}
            />
          </Tooltip>
        )}
        <Tooltip text="Delete" margin="0 12px 0 0">
          <Icon
            height="16px"
            width="16px"
            margin="0 24px"
            icon="archive"
            fill="brand"
            onClick={() => {
              deleteImage(index)
            }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  </Flex>
)
