import React from 'react'
import { Flex, Button } from 'components'
import { useRecoilValue } from 'recoil'
import { editorClipboardAtom } from 'atoms'
import { useCopyItemToClipboard, usePasteItemFromClipboard } from 'hooks'

export default () => {
  const copyItemToClipboard = useCopyItemToClipboard()
  const pasteItemFromClipBoard = usePasteItemFromClipboard()
  const clipboard = useRecoilValue(editorClipboardAtom)
  const isClipboardEmpty = Object.keys(clipboard).length === 0

  return (
    <Flex
      full
      margin="0 0 24px"
      justify={isClipboardEmpty ? 'center' : 'space-between'}
    >
      <Button center full={isClipboardEmpty} onClick={copyItemToClipboard}>
        Copy Item
      </Button>
      {!isClipboardEmpty && (
        <Button
          full
          center
          margin="0 0 0 12px"
          onClick={() => pasteItemFromClipBoard()}
        >
          Paste Item
        </Button>
      )}
    </Flex>
  )
}
