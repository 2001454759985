import { axios } from 'api'

export const getCaseMemorials = (caseId) =>
  axios.get(`/caseMemorials?case_id=${caseId}`)

export const getCaseMemorialsAsGuest = (token) =>
  axios.get(`/caseMemorialsAsGuest/${token}`)

// const examplePaylod = {
//     case_id: 'case id',
//     memorial: 'memorial string',
//     submitter_name: 'name of submitter'
// }

export const createCaseMemorial = (payload) =>
  axios.post('/createCaseMemorial', payload)

export const createCaseMemorialAsGuest = (token, payload) =>
  axios.post(`/createCaseMemorialAsGuest/${token}`, payload)

export const archiveMemorial = (id) => axios.patch(`/archiveCaseMemorial/${id}`)
