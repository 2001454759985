import { axios } from 'api'
import { sanitizeCaseDates } from 'utils'

export const getOrderPageDetails = async (order_pageset_id) =>
  axios.get(`orderPageDetails?order_page_set_id=${order_pageset_id}`)

export const getEditorOrderPageDetails = async (order_pageset_id) => {
  const response = await axios.get(
    `editorOrderPageDetails?order_page_set_id=${order_pageset_id}`,
  )
  response.data.case = sanitizeCaseDates(response.data.case)
  return response
}

export const createOrderPage = async (payload) =>
  axios.post(`createOrderPageDetails`, payload)

export const updateOrderPage = async (order_page_id, payload) =>
  axios.patch(`orderPageDetails/${order_page_id}`, payload)

export const archiveOrderPage = async (order_page_id) =>
  axios.patch(`archiveOrderPageDetails/${order_page_id}`)
