import { axios } from 'api'

export const getCustomVerses = async () => axios.get(`customVerses`)

export const createCustomVerse = async (payload) =>
  axios.post(`createCustomVerse`, payload)

// expected payload:
// {
//   html: <String> (REQUIRED)
//   html_for_render: <String> (REQUIRED),
//   reference: <String> (REQUIRED),
// }

export const updateCustomVerse = async (id, payload) =>
  axios.patch(`customVerses/${id}`, payload)

// expected payload:
// {
//   html: <String> (REQUIRED)
//   html_for_render: <String> (REQUIRED),
//   reference: <String> (REQUIRED),
// }

export const deleteCustomVerse = async (id) =>
  axios.delete(`customVerses/${id}`)
