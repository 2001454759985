import { loadLocalPlugins, insertDetailsMenu, insertTemplatesMenu } from './'
import {
  customButtons,
  createCustomButtons,
  applyStylingToNonEditable,
} from 'utils'

export default (editor, caseDetails, dateFormat) => [
  loadLocalPlugins(editor),
  insertDetailsMenu(editor, caseDetails, dateFormat),
  insertTemplatesMenu(editor, caseDetails, dateFormat),
  createCustomButtons(editor, customButtons),
  applyStylingToNonEditable(editor),
]
