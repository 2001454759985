import React, { useState, useEffect } from 'react'
import filter from 'lodash/filter'
import { useNavigate } from 'hooks'
import { cases as casesAPI } from 'api'
import { casesMockTable } from 'mocks'
import {
  Flex,
  Text,
  SearchBar,
  Button,
  TableLite,
  NewCaseButton,
  ImportFailureModal,
} from 'components'

export default ({
  rowClick,
  setShowModal,
  height,
  type,
  importCase,
  refresh: [refreshCases, setRefresh] = ['', null],
}) => {
  const navigate = useNavigate()
  const [cases, setCases] = useState(casesMockTable)
  const [itemsLoaded, setItemsLoaded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [csvFailed, setCsvFailed] = useState(false)

  const selectCase = (row) => {
    setShowModal(false)
    rowClick?.(row?.id) || navigate(`/package/${row?.id}`)
  }

  const getCases = async () => {
    const {
      data: { cases },
    } = await casesAPI.getFuneralHomeCases(false)
    setCases(cases)
    setItemsLoaded(true)
  }

  const filteredCases = () => {
    const filtered = filter(cases, (items) => {
      const include = (key) =>
        items?.[key]?.toLowerCase()?.includes(searchValue?.toLowerCase())
      return (
        include('id') ||
        include('editable_case_id') ||
        include('name_of_deceased')
      )
    })
    return searchValue === '' ? cases : filtered
  }

  useEffect(() => {
    if (refreshCases) {
      getCases()
      setRefresh((prev) => !prev)
    }
  }, [refreshCases, setRefresh])

  useEffect(() => {
    getCases()
    // eslint-disable-next-line
  }, [])

  return (
    <Flex column width="700px" height={height || undefined}>
      <ImportFailureModal
        show={[csvFailed, setCsvFailed]}
        openFile={importCase}
      />

      <Flex background="paleSecondary" padding="32px" height="88px">
        <Text size="24px" weight="600">
          Choose a case
        </Text>
      </Flex>
      <Flex column padding="32px 32px 12px 32px">
        <Flex margin="0 0 32px 0">
          <SearchBar
            placeholder="Search by name or case ID"
            value={[searchValue, setSearchValue]}
            margin="0 16px 0 0"
            flex="1"
          />
          <Button
            icon="importCase"
            margin="0 16px 0 0"
            onClick={() => importCase()}
          >
            Import case
          </Button>
          <NewCaseButton type={type} onSave={rowClick} />
        </Flex>
        <TableLite
          height="500px"
          data={filteredCases()}
          loaded={itemsLoaded}
          structure={[
            {
              title: 'Case',
              key: 'editable_case_id',
              secondKey: 'id',
              truncate: '13',
            },
            {
              title: 'Deceased name',
              key: 'name_of_deceased',
            },
          ]}
          rowClick={selectCase}
        />
      </Flex>
    </Flex>
  )
}
