import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Triggers } from 'providers'
import {
  Admin,
  Analytics,
  Assets,
  Cases,
  Dashboard,
  Editor,
  FamilyUpload,
  Landing,
  Login,
  Package,
  AdminProducts,
  Settings,
  Showcase,
  StatusMessage,
  Support,
  Themes,
  PdfRender,
  AlignmentSheet,
  UnderConstructionPage,
  CustomThemes,
  CustomThemeEditorWrapper,
  SignUp,
} from 'components'

export default () => (
  <Router>
    {/* landing */}
    <Route exact path="/" component={Landing} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/sign-up" component={SignUp} />

    {/* dashboard */}
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/cases" component={Cases} />
    <Route exact path="/settings" component={Settings} />
    <Route exact path="/support/:tab" component={Support} />

    {/* package */}
    <Route path="/package" component={Package} />

    {/* editor */}
    <Route exact path="/editor/:id" component={Editor} />

    {/* Custom Themes */}
    <Route exact path="/customThemes" component={CustomThemes} />
    <Route
      exact
      path="/customThemes/:id"
      component={CustomThemeEditorWrapper}
    />

    {/* dev */}
    <Route exact path="/showcase" component={Showcase} />

    {/* admin */}
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/admin/analytics" component={Analytics} />
    <Route exact path="/admin/assets" component={Assets} />
    <Route exact path="/admin/products" component={AdminProducts} />
    <Route exact path="/admin/status-message" component={StatusMessage} />
    <Route exact path="/admin/themes" component={Themes} />

    {/* pdf */}
    <Route exact path="/pdfProduct" component={PdfRender} />
    <Route exact path="/pdfAlign" component={AlignmentSheet} />

    {/* Under Construction */}
    <Route exact path="/under-construction" component={UnderConstructionPage} />

    {/* Family Upload */}
    <Route exact path="/familyUpload/:token/:caseId" component={FamilyUpload} />

    {/* always present */}
    <Triggers />
  </Router>
)
