import React, { useState } from 'react'
import { Flex, Text, Button, ToggleSwitch, Input, Tooltip } from 'components'
import { default as _omit } from 'lodash/omit'
import { editorPagesAtom, editorLayoutAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { useSetToast } from 'hooks'
import { pageSets, pages } from 'api'

export default ({ setShow, basePages }) => {
  const [layoutIsDefault, setLayoutIsDefault] = useState(false)
  const [layoutName, setLayoutName] = useState(null)
  const [savingLayout, setSavingLayout] = useState(false)
  const editorPages = useRecoilValue(editorPagesAtom)
  const layout = useRecoilValue(editorLayoutAtom)
  const setToast = useSetToast()

  const createPageSet = async () => {
    //TODO: for now all templates are home templates, add a toggle for super admins
    const pageSetPayload = {
      name: layoutName,
      layout_id: layout,
      home_template: true,
      default: layoutIsDefault,
    }
    const { data } = await pageSets.create(pageSetPayload)
    return data
  }

  const createPageSetPages = async (pageSet) => {
    const sortedBase = basePages.sort((a, b) =>
      a.page_number < b.page_number ? -1 : 0,
    )
    for (const page of sortedBase) {
      const json = editorPages.find(
        (jsonPage) => jsonPage.product.orderPageId === page.id,
      )
      if (!json) {
        continue
      }
      let items = [...json?.product?.items]
      items = items.map((item) => {
        const newItem = { ...item }
        if (
          newItem?.type === 'image' &&
          newItem?.properties?.subtype === 'caseImage'
        ) {
          newItem.image = {
            url: 'https://via.placeholder.com/163x163',
            edits: {},
          }
          delete newItem?.image?.editedUrl
        }
        return newItem
      })

      const newJson = _omit(json, [
        'pageProperties.pageSet',
        'pageProperties.pageSetId',
        'product.orderPageId',
        'product.pageId',
        'product.themeLayout',
        'product.watermarks',
        'product.items',
      ])

      newJson.product.items = items
      newJson.pageProperties.pageSet = pageSet.name
      newJson.pageProperties.pageSetId = pageSet.id
      const newPage = _omit(page, [
        'order_page_set_id',
        'page_set_id',
        'id',
        'json_template',
        'archived',
      ])
      newPage.page_set_id = pageSet.id
      newPage.json_template = JSON.stringify(newJson)
      const { data } = await pages.createPage(newPage)
      if (data.id) {
        data.json_template.product.pageId = data.id
        const updated = { json_template: JSON.stringify(data.json_template) }
        await pages.updatePage(data.id, updated)
      }
    }
    //do we need to do something with new pages?
    setToast({ text: 'Layout saved!' })
  }

  const createSavedLayout = async () => {
    setSavingLayout(true)
    const pageSet = await createPageSet()
    await createPageSetPages(pageSet)
    setSavingLayout(false)
  }

  return (
    <Flex margin="16px 24px" column>
      <Flex column margin="24px 0">
        <Text>
          Create a new reusable custom layout using your current progress on
          this product.
        </Text>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        padding="16px 0 8px"
        borderTop="1px solid #B0B6BC"
      >
        <Tooltip text="Change name of this saved layout" margin="0 12px 0 0">
          <Input
            label="Layout Name"
            placeholder="Layout name"
            value={[layoutName, setLayoutName]}
          />
        </Tooltip>
        <Tooltip text="Make this layout default" margin="0 12px 0 0">
          <ToggleSwitch
            label="Default"
            value={[layoutIsDefault, setLayoutIsDefault]}
            margin="0 16px"
          />
        </Tooltip>
      </Flex>
      <Flex justify="right" align="center" margin="24px 16px">
        <Button disabled={savingLayout} onClick={createSavedLayout}>
          {savingLayout ? 'Saving layout...' : 'Create Saved Layout'}
        </Button>
      </Flex>
    </Flex>
  )
}
