export default {
  'Clip Art': '00000000-57da-a14d-2252-aa0300c4218e',
  Flourishes: '00000000-57e5-829e-0a4c-6903002888c5',
  'Frame Clip Art': '00000000-57e5-8358-0a4c-6903002888d6',
  'Thank You Verses': '00000000-57ec-3722-0ca3-b10300eadaba',
  'Prayer Card Verses': '00000000-57ec-372f-0ca3-b10300eadabb',
  'Service Record Verses': '00000000-57ec-3752-0ca3-b10300eadabd',
  'All Stylized Verses': '00000000-57ec-376d-0ca3-b10300eadabe',
  'Military Emblem': '00000000-5893-48e4-6e48-31030021b1c8',
  'Bookmark Verses': '00000000-58ab-2e76-fa79-2003001561fd',
  'Jewish/Hebrew Interiors': '0709c5e4-9470-11e9-b9a8-0ae4b99c037e',
  Quotes: 'f02c896c-8ed1-11ea-8f2e-0eea4b986413',
  'Spanish Verses': '1b4353d6-af45-11ea-b82c-06269835bc67',
  Backgrounds: '69457a74-cd21-11ea-ae6d-06269835bc67',
}
