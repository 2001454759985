import { axios } from 'api'
import { sanitizeDashboardDates, sanitizeCaseDates } from 'utils'

// let current_case = {};
// const case_custom_fields = {}

export const getFuneralHomeCases = async (archived) => {
  const response = await axios.get(`/funeralHomeCases?archived=${archived}`)
  response.data.cases = sanitizeDashboardDates(response.data.cases)
  return response
}

//we used to convert dates on the fetch, but this messes with the dateInput, convert dates elsewhere?
export const getCase = async (case_id) => {
  const response = await axios.get(`cases/${case_id}`)
  response.data = sanitizeCaseDates(response.data)
  return response
}

export const getCaseAsGuest = async (token) => {
  const response = await axios.get(`caseAsGuest/${token}`)
  response.data = sanitizeCaseDates(response.data)
  return response
}

// see case payload details in readme file
export const createCase = async (payload) => {
  const response = await axios.post(`createCase`, payload)
  response.data = sanitizeCaseDates(response.data)
  return response
}

export const updateCase = async (case_id, payload) => {
  const response = await axios.patch(`cases/${case_id}`, payload)
  response.data = sanitizeCaseDates(response.data)
  return response
}

export const archiveCase = async (case_id) => {
  const response = await axios.patch(`archiveCase/${case_id}`)
  response.data = sanitizeCaseDates(response.data)
  return response
}

export const unarchiveCase = async (case_id) => {
  const response = await axios.patch(`unarchiveCase/${case_id}`)
  response.data = sanitizeCaseDates(response.data)
  return response
}

export const uploadCaseImage = async (case_id, payload) =>
  axios.post(`uploadCaseImage/${case_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadOptimizedCaseImage = async (case_id, payload) =>
  axios.post(`uploadOptimizedCaseImage/${case_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadCaseImageAsGuest = (token, payload) =>
  axios.post(`uploadCaseImageAsGuest/${token}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const deleteCaseImage = async (case_id, payload) =>
  axios.patch(`/deleteCaseImage/${case_id}`, payload)

export const uploadEditedCaseImage = (case_id, payload) =>
  axios.post(`uploadEditedCaseImage/${case_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const deleteEditedCaseImage = async (case_id, payload) =>
  axios.patch(`/deleteEditedCaseImage/${case_id}`, payload)
