import { useEffect } from 'react'

export default (ref, active, callback, resetCallBack, type) => {
  const handleClick = (e) => {
    //if the item is selected, exists, does not contain the target, AND the target is either bounding box or watermark
    //this will capture clicks in the canvas that are not other items (text, shape, line, image). So we know to reset the selected item (completely clear it out)
    if (
      active &&
      ref.current &&
      !ref.current.contains(e.target) &&
      (e.target.getAttribute('type') === 'bounding-box' ||
        e.target.getAttribute('type') === 'watermark')
    ) {
      resetCallBack()
      callback()
    } else if (
      //if the item is selected, exists and does not contain the target, but the new target is also (shape, line, text, image) then we don't want to reset since that will clear out the new item from selected
      active &&
      ref.current &&
      !ref.current.contains(e.target) &&
      e.target.getAttribute('type')
    ) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener(type || 'click', handleClick)
    return () => document.removeEventListener(type || 'click', handleClick)
  })
}
