import React from 'react'
import { Flex, Text, Button } from 'components'
import { useNavigate } from 'hooks'

export default ({ setShow, orderId }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    setShow()
    navigate(`/package/${orderId}/customize`)
  }
  return (
    <Flex column full padding="32px">
      <Text size="20px" weight="600" margin="10px 0 10px 0">
        Progress is unsaved and will be lost.
      </Text>
      <Flex justify="flex-end" margin="10px 0 0 0">
        <Button margin="0 16px 0 0" onClick={setShow}>
          Cancel
        </Button>
        <Button secondary margin="0 16px 0 0" onClick={() => handleBack()}>
          Exit to dashboard
        </Button>
      </Flex>
    </Flex>
  )
}
