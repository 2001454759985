import { itemIdAtom, itemAtomFamily } from 'atoms'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { itemType, getImageDimensions } from 'utils'

export default () => {
  const itemIds = useRecoilValue(itemIdAtom)

  const addToItems = useRecoilCallback(({ set }) => (item) => {
    set(itemAtomFamily(item.id), item)
    set(itemIdAtom, [...itemIds, item.id])
  })

  const insertItem = async (type, image) => {
    const uuid = uuidv4()
    const item = { ...itemType[type] }
    item.id = uuid

    switch (type) {
      case 'image':
        const d = await getImageDimensions(`${image.url}`)
        const height = 150
        const width = height * (d.width / d.height)
        item.image = {
          url: image.url,
        }
        item.properties = {
          subtype: 'caseImage',
          caseImage: image.caseImage,
        }
        item.width = width
        item.height = height
        break
      case 'text':
        break
      case 'shape':
        break
      case 'line':
        break
      default:
      //do nothing
    }

    addToItems(item)
  }

  return (type, image) => insertItem(type, image)
}
