export default {
  'Memorial Stationery': [
    {
      title: 'Register Book',
      category: 'Memorial Stationery',
    },
    {
      title: 'Deluxe Register Book',
      category: 'Memorial Stationery',
    },
    {
      title: 'Thank You Cards',
      category: 'Memorial Stationery',
    },
    {
      title: 'Service Records',
      category: 'Memorial Stationery',
    },
    {
      title: 'Trifolds',
      category: 'Memorial Stationery',
    },
    {
      title: 'Prayer Cards',
      category: 'Memorial Stationery',
    },
    {
      title: 'Bookmarks',
      category: 'Memorial Stationery',
    },
    {
      title: 'Grave Markers',
      category: 'Memorial Stationery',
    },
    {
      title: 'Church Bulletins',
      category: 'Memorial Stationery',
    },
    {
      title: 'Clergy Records',
      category: 'Memorial Stationery',
    },
  ],
  'Memorial Candles': [
    {
      title: 'Designer Square Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Designer Round Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Small Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Large Candle',
      category: 'Memorial Candles',
    },
  ],
  'Service Items': [
    {
      title: 'Picture Frame',
      category: 'Service Items',
    },
    {
      title: 'Directory Signs',
      category: 'Service Items',
    },
    {
      title: 'Memory Box',
      category: 'Service Items',
    },
    {
      title: 'Memorial Collage',
      category: 'Service Items',
    },
    {
      title: '16 x 20 Collage',
      category: 'Service Items',
    },
  ],
}
