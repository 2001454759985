import { axios } from 'api'

export const getCustomThemes = async () =>
  axios.get(`themes?custom_themes=true`)

export const getCustomThemesById = async (theme_id) =>
  axios.get(`themes/${theme_id}?custom=true`)

export const createCustomTheme = async (payload) =>
  axios.post('createCustomTheme', payload)

export const updateCustomTheme = async (theme_id, payload) =>
  axios.patch(`themes/${theme_id}`, payload)

export const archiveCustomTheme = async (theme_id) =>
  axios.patch(`archiveTheme/${theme_id}`)

export const uploadThemeImage = async (theme_id, payload) =>
  axios.post(`uploadThemeImage/${theme_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadOptimizedThemeImage = (theme_id, payload) =>
  axios.post(`uploadOptimizedThemeImage/${theme_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadReplacementImage = async (layout_id, payload) =>
  axios.post(`uploadReplacementImage/${layout_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadOptimizedReplacementImage = async (layout_id, payload) =>
  axios.post(`uploadOptimizedReplacementImage/${layout_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const uploadEditedBackgroundImage = async (layout_id, payload) =>
  axios.post(`uploadEditedBackgroundImage/${layout_id}`, payload, {
    headers: { 'content-type': 'form-data' },
  })

export const deleteEditedBackgroundImage = (layout_id, payload) =>
  axios.post(`deleteEditedBackgroundImage/${layout_id}`, payload)

export const getProductsForCustomThemes = async () =>
  axios.get('products?custom_themes=true')

export const layoutForCustomThemes = async (product_id, theme_id) =>
  axios.get(
    `layoutsForCustomTheme?product_id=${product_id}&theme_id=${theme_id}`,
  )

//TODO: Why is this here?
export const updateThemeLayout = async (theme_layout_id, payload) =>
  axios.patch(`themeLayouts/${theme_layout_id}`, payload)
