// const serviceDetails = [
//   service: {
//      type: "multifield",
//      template: "serviceInstance",
//   }
//   ...
// ]

// const serviceInstance = [
//   date: {
//     type: "date"
//   },
//   location: {
//     type: "input"
//   }
// ]

export default {
  personalInfo: [
    {
      key: 'name_of_deceased',
      type: 'input',
      label: 'Deceased full name *',
    },
    {
      key: 'editable_case_id',
      type: 'input',
      label: 'Custom case ID',
    },
    {
      key: 'date_of_birth',
      type: 'date',
      label: 'Date of birth *',
    },
    {
      key: 'date_of_death',
      type: 'date',
      label: 'Date of death',
    },
    {
      key: 'place_of_birth',
      type: 'input',
      label: 'Place of birth',
    },
    {
      key: 'place_of_death',
      type: 'input',
      label: 'Place of death',
    },
    {
      key: 'address_of_deceased',
      type: 'input',
      label: 'Residence address',
    },
    {
      key: 'religion',
      type: 'input',
      label: 'Religion',
    },
    {
      key: 'occupation',
      type: 'input',
      label: 'Occupation',
    },
    {
      key: 'marital_status',
      type: 'input',
      label: 'Marital status',
    },
  ],
  familyInfo: [
    {
      key: 'section_label',
      type: 'sectionLabel',
      label: 'Contact',
    },
    {
      key: 'family_contact_name',
      type: 'input',
      label: 'Family contact full name',
    },
    {
      key: 'family_contact_address',
      type: 'input',
      label: 'Contact address',
    },
    {
      key: 'section_label',
      type: 'sectionLabel',
      label: 'Parents',
    },
    {
      key: 'mother_of_deceased',
      type: 'input',
      label: 'Mother full name',
    },
    {
      key: 'father_of_deceased',
      type: 'input',
      label: 'Father full name',
    },
    {
      key: 'section_label',
      type: 'sectionLabel',
      label: 'Spouse',
    },
    {
      key: 'spouse',
      type: 'input',
      label: 'Spouse full name',
    },
    {
      key: 'siblings',
      type: 'array',
      label: 'Siblings',
      shortLabel: 'Sibling',
    },
    {
      key: 'children',
      type: 'array',
      label: 'Children',
      shortLabel: 'Child',
    },
  ],
  additionalFamily: [
    {
      key: 'additional_family',
      type: 'mappedArray',
      typeKeys: null,
      addCaseAndType: true,
      keyMappingRequired: true,
      addLabel: 'Add Another Group',
      addPlaceholder: 'Preceded By, Survived By, Grandchildren etc.',
      allowedInputs: ['name', 'relation'],
      newObject: {
        name: null,
        relation: null,
      },
    },
  ],
  serviceInfo: [
    {
      key: 'services',
      type: 'nestedArray',
      typeKeys: ['visitation', 'service'],
      addCaseAndType: true,
      allowedInputs: ['location', 'city', 'state', 'date', 'time', 'default'],
      newObject: {
        time: null,
        date: '',
        location: null,
        city: null,
        state: null,
        default: false,
      },
    },
    {
      key: 'clergy',
      type: 'array',
      label: 'Clergy',
      shortLabel: 'Clergy',
    },
  ],
  musicSelections: [
    {
      key: 'songs',
      type: 'array',
      label: 'Music Selections',
      shortLabel: 'Song',
    },
    {
      key: 'special_songs',
      type: 'array',
      label: 'Special Songs',
      shortLabel: 'Song',
    },
  ],
  pallBearersSocieties: [
    {
      key: 'pallbearers',
      type: 'array',
      label: 'Pallbearers',
      shortLabel: 'Pallbearer',
    },
    {
      key: 'honorary_pallbearers',
      type: 'array',
      label: 'Honorary Pallbearers',
      shortLabel: 'Pallbearer',
    },
    {
      key: 'societies',
      type: 'array',
      label: 'Societies',
      shortLabel: 'Society',
    },
    {
      key: 'organizations',
      type: 'array',
      label: 'Organizations',
      shortLabel: 'Organization',
    },
  ],
  intermentInfo: [
    {
      key: 'interment_time',
      type: 'input',
      label: 'Interment Time',
    },
    {
      key: 'interment_date',
      type: 'date',
      label: 'Interment Date',
    },
    {
      key: 'interment_place',
      type: 'input',
      label: 'Interment Place',
    },
    {
      key: 'interment_city',
      type: 'input',
      label: 'Interment City',
    },
    {
      key: 'interment_state',
      type: 'input',
      label: 'Interment State',
    },
    {
      key: 'interment_county',
      type: 'input',
      label: 'Interment County',
    },
    {
      key: 'interment_section',
      type: 'input',
      label: 'Interment Section',
    },
    {
      key: 'interment_block',
      type: 'input',
      label: 'Interment Block',
    },
    {
      key: 'interment_lot',
      type: 'input',
      label: 'Interment Lot',
    },
  ],
  branchOfService: [
    {
      key: 'branch_of_service',
      type: 'input',
      label: 'Branch of service',
    },
    {
      key: 'place_entered_service',
      type: 'input',
      label: 'Place entered service',
    },
    {
      key: 'rank_discharged_from_service',
      type: 'input',
      label: 'Rank discharged',
    },
    {
      key: 'service_number',
      type: 'input',
      label: 'Service number',
    },
    {
      key: 'date_entered_service',
      type: 'date',
      label: 'Date entered service',
    },
    {
      key: 'date_discharged_from_service',
      type: 'date',
      label: 'Date discharged',
    },
  ],
  firingParty: [
    {
      key: 'ncoic_name',
      type: 'input',
      label: 'NCOIC',
    },
    {
      key: 'chaplain',
      type: 'input',
      label: 'Chaplain',
    },
    {
      key: 'oic_name',
      type: 'input',
      label: 'OIC',
    },
    {
      key: 'buglar_and_or_color_guard',
      type: 'input',
      label: 'Bugler/color guard',
    },
    {
      key: 'firing_parties_for_funeral_service',
      type: 'array',
      label: 'Firing party',
      shortLabel: 'Party',
    },
  ],
  honors: [
    {
      label: 'Distinguished Service Medal',
      key: 'distinguished_service_medal',
      type: 'check',
    },
    {
      label: 'Purple Heart',
      key: 'purple_heart_medal',
      type: 'check',
    },
    {
      label: 'Silver Star',
      key: 'silver_star_medal',
      type: 'check',
    },
    {
      label: 'Medal of Honor',
      key: 'medal_of_honor',
      type: 'check',
    },
    {
      label: 'Airforce Cross',
      key: 'air_force_cross_medal',
      type: 'check',
    },
    {
      label: 'Navy Cross',
      key: 'navy_cross_medal',
      type: 'check',
    },
    {
      label: 'Bronze Star',
      key: 'bronze_star_medal',
      type: 'check',
    },
    {
      key: 'blank',
      type: 'blank',
    },
    {
      label: 'Person providing honors',
      key: 'person_providing_honors',
      type: 'input',
    },
    {
      label: 'Add other medal',
      shortLabel: 'Medal',
      key: 'other_medals',
      type: 'array',
    },
  ],
  station: [
    {
      key: 'us_stations_where_served',
      type: 'nestedArray',
      typeKeys: ['Stations'],
      addCaseAndType: false,
      allowedInputs: [
        'station_name',
        'departure_date',
        'departure_place',
        'return_date',
        'return_place',
      ],
      newObject: {
        station_name: null,
        departure_date: new Date(),
        departure_place: null,
        return_date: new Date(),
        return_place: null,
      },
    },
  ],
}
