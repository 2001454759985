import React, { useEffect } from 'react'
import { InsertTabs, SelectLayoutDrawer, CreateLayoutDrawer } from 'components'
import { updatePageItems } from 'hooks'

export default ({ setShow, basePages, baseLayout, setIsLoading }) => {
  const updateItems = updatePageItems()

  useEffect(() => {
    updateItems()
    //eslint-disable-next-line
  }, [])

  return (
    <InsertTabs
      tabs={[
        {
          name: 'Select Layout',
          content: () => (
            <SelectLayoutDrawer
              setShow={setShow}
              basePages={basePages}
              baseLayout={baseLayout}
              setIsLoading={setIsLoading}
            />
          ),
        },

        {
          name: 'Save Progress as a New Layout',
          content: () => (
            <CreateLayoutDrawer setShow={setShow} basePages={basePages} />
          ),
        },
      ]}
    />
  )
}
