import React, { useState, useRef } from 'react'
import { Flex, Button, Input, TinyMCE } from 'components'
import { customVerses } from 'api'

const CustomVerseEditor = ({
  value,
  reference: [reference, setReference],
  TinyMCERef,
  cancel,
  save,
}) => {
  return (
    <Flex column full>
      <Flex
        border="1px solid"
        borderColor="gray4"
        radius="2px"
        minHeight="200px"
        margin="0 0 12px 0"
        full
      >
        <TinyMCE
          placeholder="Type text here"
          value={value}
          setRef={(ref) => (TinyMCERef.current = ref)}
        />
      </Flex>
      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      <Flex>
        <Button onClick={() => cancel?.()} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={() => save?.()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

export default ({ setCreatingVerse }) => {
  const TinyMCERef = useRef(null)
  const [editReference, setEditReference] = useState('')

  const createVerse = async () => {
    const content = TinyMCERef?.current?.getContent?.()
    const newVerse = {
      html: content,
      html_for_render: content,
      reference: editReference,
    }
    await customVerses.createCustomVerse(newVerse)
    setCreatingVerse(false)
  }

  return (
    <Flex full padding="24px">
      <CustomVerseEditor
        TinyMCERef={TinyMCERef}
        value=""
        reference={[editReference, setEditReference]}
        cancel={() => setCreatingVerse(false)}
        save={() => createVerse()}
      />
    </Flex>
  )
}
