import { axios } from 'api'

export const getPages = async (pageset_id) =>
  axios.get(`pages?page_set_id=${pageset_id}`)

export const createPage = async (payload) => axios.post(`createPage`, payload)

export const updatePage = async (page_id, payload) =>
  axios.patch(`pages/${page_id}`, payload)

export const archivePage = async (page_id) =>
  axios.patch(`archivePage/${page_id}`)
