import { axios } from 'api'

export const create = async (payload) => axios.post(`createLayout`, payload)

export const update = async (layout_id, payload) =>
  axios.patch(`layouts/${layout_id}`, payload)

export const getLayoutsByProduct = async (product_id) =>
  axios.get(`layouts?product_id=${product_id}`)

export const getById = async (layout_id) => axios.get(`layouts/${layout_id}`)

export const getForCustomThemes = async (product_id) =>
  axios.get(`layoutsForCustomThemes/${product_id}`)

//TODO: figure out config and payload
//export const uploadRepalcementImage = async (layout_id, payload) => axios.post(`uploadRepalcementImage/${layout_id}`, payload, config)
