import styled from 'styled-components'
import { Flex } from 'components'
import { colors as c } from 'utils'
import { spacing } from 'styles'

export const ContentWrapper = styled(Flex)`
  max-width: 680px;
  padding: 32px;
  border: 1px solid ${c('gray4')};

  ${spacing}
`
