import React from 'react'
import { Check } from 'components'

export default ({ show, setShow }) => (
  <Check
    text="Do not show this again"
    checked={!show}
    onClick={() => setShow(!show)}
  />
)
