import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { PrintPackageHeader } from './'
import { CardSelector, Text } from 'components'
import { collections as collectionsAPI } from 'api'
import { collectionsAtom, selectedProductLineAtom } from 'atoms'

export default ({ caseInfo }) => {
  const [collections, setCollections] = useRecoilState(collectionsAtom)
  const getCollections = async () => {
    const { data } = await collectionsAPI.getAllCollections()
    const parsedData = data.map((collection) => ({
      id: collection.id,
      image: collection.image,
    }))
    setCollections(parsedData)
  }

  useEffect(() => {
    getCollections()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PrintPackageHeader title="Choose a product line" caseInfo={caseInfo} />
      <CardSelector
        cardAlign="center"
        cardJustify="center"
        padding="32px"
        cards={collections}
        cardWidth="375px"
        cardHeight="378px"
        imageWidth="369px"
        imageHeight="378px"
        productIcon
        isProductLine={true}
        selectedState={useRecoilState(selectedProductLineAtom)}
      />
      <Text size="14px" color="gray2" align="center" justify="center">
        Note: collections may contain unique products. If you can’t find your
        product, return to this step and change collections.
      </Text>
    </>
  )
}
