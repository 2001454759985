import React from 'react'
import * as S from './ResizeControls.styled'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { selectedItemAtom, itemAtomFamily } from 'atoms'

export default ({
  x,
  y,
  z,
  width,
  height,
  rotate,
  internalWidth: [internalWidth, setInternalWidth] = [0, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  type,
  selected,
  setWidth,
  setHeight,
  disabled,
  isEditing,
  children,
}) => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))

  const handlePlacements = ['se']

  const minConstraints = type === 'line' ? [2, 2] : [20, 20]
  const axis = type === 'line' ? 'x' : 'both'

  const onResize = (event, { size: { width, height } }) => {
    setInternalWidth(width)
    setInternalHeight(height)
  }

  const onResizeStop = (event, { size: { width, height } }) => {
    setWidth(width)
    setHeight(height)
  }

  return (
    <S.ResizeControls
      height={internalHeight || 0}
      width={internalWidth || 0}
      rotate={rotate}
      onResize={onResize}
      onResizeStop={onResizeStop}
      minConstraints={minConstraints}
      selected={selected}
      resizeHandles={handlePlacements}
      lockAspectRatio={type === 'image' || type === 'watermark'}
      axis={axis}
      disabled={disabled}
      handle={(position) =>
        isEditing ? (
          <></>
        ) : (
          <S.ResizeHandle
            className="no-drag"
            x={x}
            y={y}
            z={z}
            height={height || 0}
            width={width || 0}
            position={position}
            selected={selected}
          />
        )
      }
    >
      <>
        {!isEditing && (
          <S.DeleteItem
            z={z}
            selected={selected}
            onClick={() => deleteItem()}
            fill="brand"
            icon="exitModal"
          />
        )}
        {children}
      </>
    </S.ResizeControls>
  )
}
