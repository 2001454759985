import React, { useState } from 'react'
import { useSetToast } from 'hooks'
import {
  Button,
  Check,
  ColorPicker,
  DateInput,
  Dropdown,
  Flex,
  FullscreenModal,
  Input,
  MenuButton,
  ModalButton,
  ModalShowcase,
  Radio,
  SearchBar,
  Slider,
  Text,
  ToggleSwitch,
  Tooltip,
} from 'components'

export default () => {
  const setToast = useSetToast()

  const [checked, setChecked] = useState(false)
  const [input, setInput] = useState('')
  const [dropdown, setDropdown] = useState('')
  const [date, setDate] = useState(null)
  const [search, setSearch] = useState('')
  const [showFullModal, setShowFullModal] = useState(false)
  const [showButtonModal, setShowButtonModal] = useState(false)
  const [radioValue, setRadioValue] = useState('')
  const [toggleSwitch1, setToggleSwitch1] = useState(false)
  const [toggleSwitch2, setToggleSwitch2] = useState(false)
  const [color, setColor] = useState()
  const [sliderValue, setSliderValue] = useState(50)

  return (
    <Flex column align="center" margin="0 42px 42px 0">
      <Flex column center full padding="48px" background="paleSecondary">
        <Text as="h2" margin="0 0 32px 0">
          Components
        </Text>

        {/* checkboxes */}
        <Flex column margin="0 0 32px 0">
          <Check
            text="Checkbox"
            checked={checked}
            onClick={() => setChecked(!checked)}
          />
        </Flex>

        {/* radio buttons */}
        <Flex column margin="0 0 32px 0">
          <Radio
            label="Radio Menu"
            value={[radioValue, setRadioValue]}
            options={['Option 1', 'Option 2', 'Option 3']}
            column
          />
        </Flex>

        {/* toggle switch */}
        <Flex column margin="0 0 32px 0">
          <Text align="center" weight="bold">
            Toggle Switch
          </Text>
          <ToggleSwitch
            label="Label Top"
            value={[toggleSwitch1, setToggleSwitch1]}
            margin="24px 0"
          />
          <ToggleSwitch
            label="Label Right"
            value={[toggleSwitch2, setToggleSwitch2]}
            labelRight
            margin="0 0 24px"
          />
        </Flex>

        {/* color picker */}
        <Flex column center margin="0 0 32px 0">
          <Text align="center" weight="bold">
            Color Picker
          </Text>
          <ColorPicker
            left
            color={[color, setColor]}
            margin="20px 0"
            resetPicker
          />
        </Flex>

        {/* slider */}
        <Flex column margin="0 0 32px 0">
          <Text align="center" weight="bold">
            Slider
          </Text>
          <Slider
            sliderState={[sliderValue, setSliderValue]}
            min="0"
            max="100"
            initialValue={0}
          />
        </Flex>

        {/* buttons */}
        <Flex margin="0 0 32px 0">
          <Button margin="0 12px 0 0">Button</Button>
          <Button icon="email" margin="0 12px 0 0">
            Button with icon
          </Button>
          <Button disabled margin="0 12px 0 0">
            Disabled button
          </Button>
          <MenuButton
            left
            text="Menu button"
            items={[
              {
                text: 'New memorial label',
                icon: 'plus',
                onClick: () => alert('Yahaha! You found me!'),
              },
              { text: 'Import case', icon: 'importCase' },
              { text: 'Archive multiple', icon: 'archive' },
            ]}
          />
        </Flex>

        {/* special buttons */}
        <Flex
          margin="0 0 32px 0"
          background="lockedGray1"
          center
          padding="32px"
          radius="2px"
        >
          <Button primary margin="0 12px 0 0">
            Primary button
          </Button>
          <Button secondary margin="0 12px 0 0">
            Secondary button
          </Button>
          <Button outline>Outline button</Button>
        </Flex>

        {/* inputs */}
        <Flex column margin="0 0 32px 0">
          <Flex margin="0 0 12px 0">
            <Input
              label="Label"
              placeholder="Placeholder"
              value={[input, setInput]}
              margin="0 12px 0 0"
            />
            <Dropdown
              label="Default font"
              selected={[dropdown, setDropdown]}
              placeholder="Select a font"
              textKey="fontName"
              valueKey="font"
              items={[
                { fontName: 'Noto Serif TC', font: 'notoSerifTC' },
                { fontName: 'Century Gothic', font: 'centuryGothic' },
                { fontName: 'Times New Roman', font: 'timesNewRoman' },
              ]}
              margin="0 12px 0 0"
            />
          </Flex>
          <Flex>
            <SearchBar
              placeholder="Search"
              value={[search, setSearch]}
              margin="0 12px 0 0"
            />
            <DateInput date={[date, setDate]} left />
          </Flex>
        </Flex>

        {/* tooltips */}
        <Flex center margin="0 0 32px 0">
          <Tooltip text="Tooltip" margin="0 12px 0 0">
            <Text>Tooltip example</Text>
          </Tooltip>
          <Button
            margin="0 12px 0 0"
            onClick={() =>
              setToast({
                text: 'Case deleted',
                actions: [
                  {
                    text: 'undo',
                    onClick: () =>
                      setToast({ text: 'Case restored', icon: 'check' }),
                  },
                ],
              })
            }
          >
            Trigger toast
          </Button>
          <Button margin="0 12px 0 0" onClick={() => setShowFullModal(true)}>
            Fullscreen Modal
          </Button>
          <FullscreenModal show={[showFullModal, setShowFullModal]}>
            <ModalShowcase setShowExample={() => setShowFullModal(false)} />
          </FullscreenModal>
          <ModalButton
            left
            text="Modal button"
            show={[showButtonModal, setShowButtonModal]}
          >
            <ModalShowcase setShowExample={() => setShowButtonModal(false)} />
          </ModalButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
