import React from 'react'
import { Tabs } from 'components'
import { Contact, Instructions, Webinars, Videos } from './'
import {
  GettingStartedContent,
  WelcomeContent,
} from 'components/dashboard/WelcomeModal'

export default ({
  activeTab: [activeTab, setActiveTab] = [null, () => {}],
  ...props
}) => (
  <Tabs
    tabs={[
      {
        title: 'Contact',
        content: <Contact />,
        url: '/support/contact',
      },
      {
        title: 'Videos',
        content: <Videos />,
        url: '/support/videos',
      },
      {
        title: 'Instructions',
        content: <Instructions />,
        url: '/support/instructions',
      },
      {
        title: 'Webinars',
        content: <Webinars />,
        url: '/support/webinars',
      },
      {
        title: 'Welcome',
        content: (
          <>
            <WelcomeContent margin="0 0 24px 0" />
            <GettingStartedContent />
          </>
        ),
        url: '/support/welcome',
      },
    ]}
    selected={[activeTab, setActiveTab]}
    {...props}
  />
)
