import { caseDetailSpan } from '.'
import startCase from 'lodash/startCase'
import { replaceInitialValues } from 'utils'
import { format } from 'date-fns'

export default (editor, caseDetails, key, templates, dateFormat) => {
  caseDetails[key].forEach((service, i) => {
    let content = `
        <p>${caseDetailSpan(`${key}[${i}].location`)}</p>
        <p>
          ${caseDetailSpan(`${key}[${i}].city`)}, 
          ${caseDetailSpan(`${key}[${i}].state`)}
        </p>
        <p>${caseDetailSpan(`${key}[${i}].time`)}</p>
      `

    templates.push({
      type: 'menuitem',
      text: `${startCase(service.type)} ${format(service.date, dateFormat)}`,
      onAction: () =>
        editor.execCommand(
          'mceInsertTemplate',
          false,
          replaceInitialValues(content, caseDetails),
        ),
    })
  })
}
